import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'src/components/mui-components';
import {
  RPEmployeeViewDisableExpandAllCount,
  RPProjectViewDisableExpandAllCount,
} from '../../constants';
import { RPRow, RTRow } from '../../types/resourcePlanner';

interface IToggleRowsButton {
  disableExpandAll: boolean;
  handleOnToggleRow: (row: RTRow) => void;
  isGroupedByProject: boolean;
  rows: Row<RPRow>[];
}

export const ToggleRowsButton = ({
  disableExpandAll = false,
  handleOnToggleRow,
  isGroupedByProject,
  rows,
}: IToggleRowsButton) => {
  const { t } = useTranslation('resourcePlanner');
  const maxTableExpandedDepth =
    rows.reduce((maxDepth, row) => {
      if (row.getCanExpand() && row.getIsExpanded()) {
        return Math.max(maxDepth, row.depth);
      }
      return maxDepth;
    }, 0) + 1;

  const onExpandCallback = () => {
    const minimumDepthToExpand = rows.reduce((a, { depth, getCanExpand, getIsExpanded }) => {
      if (depth < maxTableExpandedDepth && getCanExpand() && !getIsExpanded()) {
        return Math.min(a, depth);
      }
      return a;
    }, maxTableExpandedDepth);

    const isAllSameDepthRowCollapsed = rows.reduce((a, { depth, getCanExpand, getIsExpanded }) => {
      if (depth === minimumDepthToExpand && getCanExpand() && getIsExpanded()) {
        return false;
      }
      return a;
    }, true);

    if (isAllSameDepthRowCollapsed) {
      rows.forEach((row) => {
        if (row.depth > minimumDepthToExpand && row.getCanExpand() && row.getIsExpanded()) {
          handleOnToggleRow(row);
        }
      });
    }

    rows.forEach((row) => {
      if (row.depth === minimumDepthToExpand && row.getCanExpand() && !row.getIsExpanded()) {
        handleOnToggleRow(row);
      }
    });
  };

  const onCollapseCallback = () => {
    rows.forEach((row) => {
      if (row.depth === maxTableExpandedDepth - 1 && row.getCanExpand() && row.getIsExpanded()) {
        handleOnToggleRow(row);
      }
    });
  };

  const disabledTooltipText = isGroupedByProject
    ? t('ExpandAllDisabledTooltipProject', { maxCount: RPProjectViewDisableExpandAllCount })
    : t('ExpandAllDisabledTooltipEmployee', { maxCount: RPEmployeeViewDisableExpandAllCount });

  return (
    <Stack alignItems="center" direction="row" gap={0} sx={{ height: 32 }}>
      <IconButton
        data-automation-id="ExpandAllRowsButton"
        disabled={disableExpandAll}
        onClick={onExpandCallback}
        size="small"
        title={disableExpandAll ? disabledTooltipText : t('UnfoldMore')}
      >
        <UnfoldMore fontSize="small" />
      </IconButton>
      <IconButton
        data-automation-id="CollapseAllRowsButton"
        onClick={onCollapseCallback}
        size="small"
        title={t('UnfoldLess')}
      >
        <UnfoldLess fontSize="small" />
      </IconButton>
    </Stack>
  );
};
