import { Box } from '@mui/material';
import {
  GridColDef,
  GridCsvExportMenuItem,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import {
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from 'src/components/mui-components';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { Circle, MonetizationOnOutlined } from '@mui/icons-material';
import { useGetTimeRegistrations } from 'src/apis/projectsAPI';
import { formatTime } from 'src/utils/time';
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { translationAnyText } from 'src/utils/translation';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ITimeRegistrations } from 'src/apis/projectsAPI/types';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';
import { useExcelConfig } from './config';

interface ITimeRegistrationProps {
  showTimeRegistration: boolean;
  setShowTimeRegistration: (value: boolean) => void;
  projectId: string;
}

const billableStatusMap: { [key: string]: string } = {
  NonBillableRegistrationOnNonBillableTask: 'disabled',
  BillableRegistrationOnBillableTask: 'success',
  NonBillableRegistrationOnBillableTask: 'error',
  BillableRegistrationOnNonBillableTask: 'success',
  NonBillableRegistrationOnBillableTaskWithZeroHourlyRate: 'disabled',
};

const bookingStatusMap: { [key: string]: string } = {
  Booked: 'success',
  Drafted: 'warning',
  NotBooked: 'disable',
};

export const Toolbar = (columns: GridColDef[]) => (
  <GridToolbarContainer>
    <Box sx={{ flexGrow: 1 }} />
    <GridToolbarExportContainer>
      <GridCsvExportMenuItem />
      <GridExcelExportMenuItem columns={columns} config={useExcelConfig()} />
    </GridToolbarExportContainer>
  </GridToolbarContainer>
);

export const TimeRegistrationsDialog = ({
  showTimeRegistration,
  setShowTimeRegistration,
  projectId,
}: ITimeRegistrationProps) => {
  const { t } = useTranslation('searchProjects');
  const navigate = useNavigate();

  const siteLocale = useGetLocale();

  const handleBookingStatus = ({
    bookingStatus,
    bookedInvoiceId,
    bookedVoucherId,
    draftInvoiceId,
    draftVoucherId,
  }: Pick<
    ITimeRegistrations,
    'bookingStatus' | 'bookedInvoiceId' | 'bookedVoucherId' | 'draftInvoiceId' | 'draftVoucherId'
  >) => {
    if (bookingStatus === 'Booked') {
      if (bookedInvoiceId !== 0) {
        navigate(`/Financial/Invoicing/EditInvoice/${bookedInvoiceId}`);
      } else if (bookedVoucherId !== 0) {
        navigate(`/Financial/Invoicing/EditVoucher/${bookedVoucherId}`);
      }
    } else if (bookingStatus === 'Drafted') {
      if (draftInvoiceId !== 0) {
        navigate(`/Financial/Invoicing/EditInvoice/${draftInvoiceId}`);
      } else if (draftVoucherId !== 0) {
        navigate(`/Financial/Invoicing/EditVoucher/${draftVoucherId}`);
      }
    }
  };

  const {
    timeRegistrations: rows,
    additionalCommentLabel,
    summations,
    isLoading,
  } = useGetTimeRegistrations(projectId || '');

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: t('TableHeaderDate'),
      minWidth: 100,
      renderCell: (params) => getDateStringFromSiteLocale(new Date(params.value), siteLocale),
    },
    { field: 'projectName', headerName: t('TableHeaderProject'), minWidth: 200, flex: 1 },
    {
      field: 'taskName',
      headerName: t('TableHeaderTask'),
      minWidth: 150,
    },
    {
      field: 'employeeInitials',
      headerName: t('TableHeaderEmployee'),
      minWidth: 100,
      renderCell: (params) => <span title={params.row.employeeName}>{params.value}</span>,
    },
    {
      field: 'comment',
      headerName: t('TableHeaderComment'),
      minWidth: 100,
    },
    ...(additionalCommentLabel !== ''
      ? [
          {
            field: 'additionalText',
            minWidth: 100,
            headerName: additionalCommentLabel,
          },
        ]
      : []),
    {
      field: 'currency',
      minWidth: 100,
      headerName: t('TableHeaderCurrency'),
    },
    {
      field: 'actualHours',
      minWidth: 100,
      headerName: t('TableHeaderActualHours'),
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (value) => formatTime(value ?? 0, siteLocale),
    },
    {
      field: 'hourlyRate',
      minWidth: 100,
      headerName: t('TableHeaderHourlyRate'),
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (value) => formatTime(value ?? 0, siteLocale),
      renderCell: (params) => (params.id === 'TOTAL' ? '' : formatTime(params.value, siteLocale)),
    },
    {
      field: 'actualAmount',
      minWidth: 100,
      headerName: t('TableHeaderActual'),
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (value) => formatTime(value ?? 0, siteLocale),
    },
    {
      field: 'billableStatus',
      width: 58,
      headerName: '',
      renderCell: (params: GridRenderCellParams) => {
        if (params.id === 'TOTAL') return null;
        const color = billableStatusMap[params.value];
        return (
          <IconButton
            title={translationAnyText(t, `BillableStatus${params.value}`)}
            data-automation-id="BillableRegistrationIcon"
          >
            <MonetizationOnOutlined
              fontSize="small"
              color={color as 'success' | 'error' | 'disabled'}
            />
          </IconButton>
        );
      },
    },
    {
      field: 'bookingStatus',
      headerName: '',
      width: 58,
      renderCell: (params: GridRenderCellParams) => {
        if (params.id === 'TOTAL') return null;
        const color = bookingStatusMap[params.value];
        return (
          <IconButton
            title={translationAnyText(t, `BookingStatus${params.value}`)}
            onClick={() => handleBookingStatus(params.row)}
            data-automation-id="bookedIcon"
          >
            <Circle fontSize="small" color={color as 'success' | 'warning' | 'disabled'} />
          </IconButton>
        );
      },
    },
  ];

  const pinnedRows: GridPinnedRowsProp = {
    bottom: [...[summations]],
  };

  return (
    <Dialog
      open={!!showTimeRegistration}
      onClose={() => setShowTimeRegistration(false)}
      aria-labelledby="dialog-time-registration"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="dialog-time-registration">{t('TimeRegistrationsTitle')}</DialogTitle>
      <DialogContent>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            loading={isLoading}
            rows={rows}
            columns={columns}
            slots={{
              toolbar: () => Toolbar(columns),
            }}
            disableColumnTopBorder
            disableColumnMenu
            hideFooter
            getRowId={(row) => row.timeRegistrationId}
            pinnedRows={pinnedRows}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setShowTimeRegistration(false)}>
          {t('CancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
