import { HTMLAttributes, MouseEventHandler, ReactNode, forwardRef } from 'react';
import { Box, Chip, Paper, Typography } from 'src/components/mui-components';
import { useAutoId } from 'src/hooks/useAutoId';
import { useTranslation } from 'react-i18next';
import styles from './FilterTag.module.scss';

const ChipTextComponent = ({
  label,
  list,
  isInclude,
}: {
  label: string;
  list: FilterTagItemProp[];
  isInclude?: string;
}) => (
  <Typography component="span" sx={{ fontSize: 13 }}>
    {label}
    {isInclude ? ` ${isInclude.toLowerCase()} ` : ': '}
    <span data-automation-id="FilterTagOpenPanelButtonValue">{list[0]?.label} </span>
    {list.length > 1 && ` +${list.length - 1}`}
  </Typography>
);

export interface FilterTagProps extends Omit<HTMLAttributes<HTMLDivElement>, 'style'> {
  title: string;
  items: FilterTagItemProp[];
  children: ReactNode;
  removeFilterTag?: () => void;
  panelIsOpen?: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  isInclude?: boolean;
}

export interface FilterTagItemProp {
  label: string;
}

export const FilterTag = forwardRef<HTMLDivElement, FilterTagProps>(
  (
    {
      title,
      items,
      removeFilterTag,
      children,
      panelIsOpen = false,
      onClick,
      isInclude = true,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation('filter');
    const autoId = `filter-${useAutoId()}`;
    return (
      <Box className={styles.tagContainer} ref={ref} {...props}>
        <Chip
          color="primary"
          label={
            <ChipTextComponent
              label={title}
              list={items}
              isInclude={isInclude ? undefined : t('DropDownRadioIsNotLabel')}
            />
          }
          onClick={onClick}
          onDelete={removeFilterTag || undefined}
          data-automation-id="FilterTagOpenPanelButton"
          aria-controls={autoId}
          aria-expanded={panelIsOpen}
        />
        <Paper
          id={autoId}
          className={styles.panelContent}
          data-automation-id="FilterTagPanelContent"
          hidden={!panelIsOpen}
        >
          {children}
        </Paper>
      </Box>
    );
  },
);
