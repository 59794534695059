import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postFetch } from 'src/utils/fetcher';
import { MOVE_HOURS_KEY } from '../get/getMoveHoursAPI';

const POST_REGISTRATIONS_SPLIT_URL_PATH = '/api/v2/adjustments/move-hours';
export const POST_REGISTRATIONS_SPLIT_MSW_STATUS_KEY = 'postRegistrationsSplitAPI';

interface IPostModel {
  targetTaskId?: number;
  hours: number;
  comment: string;
  additionalText?: string;
}

const postRegistrationSplit = ({
  timeRegistrationId,
  postModel,
}: {
  timeRegistrationId: string;
  postModel: IPostModel[];
}) =>
  postFetch({
    path: `${POST_REGISTRATIONS_SPLIT_URL_PATH}/${timeRegistrationId}/split`,
    key: POST_REGISTRATIONS_SPLIT_MSW_STATUS_KEY,
    body: JSON.stringify(postModel),
  });

export const usePostRegistrationSplit = () => {
  const queryClient = useQueryClient();

  return useMutation(postRegistrationSplit, {
    onSuccess: () => {
      queryClient.invalidateQueries([MOVE_HOURS_KEY]);
    },
  });
};
