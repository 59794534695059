import { useGetLocale } from 'src/components/global/LocaleProvider';
import { formatTime } from 'src/utils/time';

export const useExcelConfig = () => {
  const siteLocale = useGetLocale();

  return {
    ignoreColumns: ['billableStatus', 'bookingStatus'],
    fileName: 'Time_registrations.xlsx',
    sheetName: 'Time_registrations',
    titleName: 'TimeLog - Time_registrations',
    valueParser: {
      actualHours: (value: number | string) => formatTime(value, siteLocale),
      hourlyRate: (value: number | string) => formatTime(value, siteLocale),
      actualAmount: (value: number | string) => formatTime(value, siteLocale),
    },
  };
};
