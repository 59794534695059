import { QueryClient } from '@tanstack/react-query';
import {
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
  GROUP_BY_RESOURCE_TOTAL_KEY,
  PARTIAL_GROUP_BY_PROJECT_KEY,
  PARTIAL_GROUP_BY_RESOURCE_KEY,
} from 'src/apis/resourcePlannerAPI';

export const reloadRpTables = (qc: QueryClient) => {
  qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
  qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
  qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
  qc.invalidateQueries([PARTIAL_GROUP_BY_PROJECT_KEY]);
  qc.invalidateQueries([PARTIAL_GROUP_BY_RESOURCE_KEY]);
};
