import { InfiniteData } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { concatQuerys } from 'src/utils/url';
import {
  IResourcePlannerItem,
  IResourcePlannerPeriodColumn,
  IResourcePlannerResult,
} from '../types/resourcePlannerAPI';

export const flattenInfiniteQueryData = (data?: InfiniteData<IResourcePlannerResult> | null) => {
  const latestPage = data?.pages[(data?.pages?.length ?? 1) - 1];
  // flatten the array of arrays from the useInfiniteQuery hook
  const properties = data?.pages?.flatMap((page) => page.model.properties) ?? [];
  const children = properties.flatMap((property) => property.children ?? []);

  return {
    children,
    isEmpty: !children.length,
    latestPageChildrenLength: latestPage?.model.properties.children?.length ?? 0,
    periods: properties.reduce((_, b) => b.periods, [] as IResourcePlannerPeriodColumn[]),
    responseType: properties.reduce((_, b) => b.responseType, 'GroupByWorkItem'),
    viewOptions: properties.reduce((a, b) => ({ ...a, ...b.viewOptions }), {
      'grouped-by': '',
      'included-work-items': '',
      'reporting-types': '',
      'period-types': '',
      'unit-types': '',
    }),
  };
};

export const getAllResourceIds = (result: IResourcePlannerItem[]): string[] =>
  result.map(({ resourceId }) => resourceId);

export const getFiltersParam = (selectedFilterList: IFilterStoreProps['selectedFilterList']) => {
  const items = Object.keys(selectedFilterList);
  return `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: selectedFilterList[item].isInclude ? 'true' : 'false',
        isAppended: true,
      })),
  )}`;
};

export const getQueryParams = (params: Record<string, string>) =>
  concatQuerys(
    Object.keys(params).map((key) => ({
      param: key.replaceAll('-', ''),
      value:
        // we need the hyphens in dates
        key === 'period-starts-at' || key === 'period-ends-at'
          ? params[key]
          : params[key].replaceAll('-', ''),
    })),
  );
