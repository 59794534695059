import { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useGetSavedView } from 'src/apis/savedViewAPI';
import { FilterSection } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/FilterSection';
import { IOptionsChangeParameters } from 'src/components/layout/FilterPanelLayout/types';
import { TViewOptions } from 'src/apis/savedViewAPI/types';
import { Deck } from 'src/components/ui-components';
import { SearchProjectsViewOptionsStateKey } from './localStorageKeys';

import { Table } from './components/Table';
import { IProjectError } from './types';

export const SearchProjects = () => {
  const pageIdentifier = 'DesignSevenSearchProject';
  const activateSidePanelButtonRef = useRef<HTMLButtonElement>(null);

  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const { data } = useGetSavedView(pageIdentifier);
  const savedView = useMemo(() => data || [], [data]);

  const [activeView, setActiveView] = useState('');
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions>();

  const [rowHaveError, setRowHaveError] = useState<IProjectError[]>([]);

  const setViewOptions = useCallback(() => {
    const viewOptions = savedView.find((v) => v.filterViewId === activeView)?.viewOptions;
    setChangedViewOptions(viewOptions);
    if (rowHaveError.length > 0) {
      setRowHaveError([]);
    }
  }, [activeView, rowHaveError.length, savedView]);

  useEffect(() => {
    setViewOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  const clearRowError = () => {
    if (rowHaveError.length > 0) {
      setRowHaveError([]);
    }
  };

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(SearchProjectsViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterPanelLayout
        filterList={filterList}
        savedViewsList={savedView}
        activeView={activeView}
        setActiveView={setActiveView}
        changedViewOptions={changedViewOptions}
        setViewOptions={setViewOptions}
        key="SearchProjectsFilterPanelLayout"
        activateSidePanelButtonRef={activateSidePanelButtonRef}
      >
        <Deck.Item hasOverflow>
          <Table
            selectedViewOptions={changedViewOptions}
            onViewOptionsChange={onViewOptionsChange}
            rowHaveError={rowHaveError}
            setRowHaveError={setRowHaveError}
            filterSection={
              <FilterSection
                key="SearchProjectsFilterSection"
                changedViewOptions={changedViewOptions}
                onViewOptionsChange={onViewOptionsChange}
                activateSidePanelButtonRef={activateSidePanelButtonRef}
                clearRowError={clearRowError}
              />
            }
          />
        </Deck.Item>
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
