import { FlagOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMilestones } from 'src/apis/milestonesAPI';
import { Button, Select, Stack } from 'src/components/mui-components';
import {
  getDateOneWeekFromStartOfWeek,
  getDateThirtyDaysFromNow,
  getDateTwoWeeksFromStartOfWeek,
} from 'src/utils/date';
import { useGetFrontPageFeature } from 'src/apis/featureAPI';
import { milestoneDropdownOptions } from 'src/constants/dropdown';
import { WidgetHeader } from '../WidgetHeader';
import { Error, FeedItems, Loading, NoResults } from './components';

interface IMilestoneFeedStatus {
  date: string;
}

const getDateValue = (date: string) => {
  switch (date) {
    case '0':
      return getDateOneWeekFromStartOfWeek();
    case '1':
      return getDateTwoWeeksFromStartOfWeek();
    case '2':
      return getDateThirtyDaysFromNow();
    default:
      return '';
  }
};

const MilestoneFeedStatus = ({ date }: IMilestoneFeedStatus) => {
  const { t } = useTranslation('frontpage');
  const { milestones, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetMilestones(date);

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error />;
  }
  if (milestones && milestones[0] !== undefined) {
    return (
      <Stack gap={4}>
        <FeedItems items={milestones} />

        {isFetchingNextPage && <Loading />}

        {hasNextPage && (
          <Stack direction="row" justifyContent="right">
            <Button
              size="small"
              data-automation-id="MilestonesLoadMoreButton"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {t('MilestonesLoadMoreButton')}
            </Button>
          </Stack>
        )}
      </Stack>
    );
  }
  return <NoResults />;
};

const PeriodSelect = ({ date, setDate }: { date: string; setDate: (event: string) => void }) => {
  const { t } = useTranslation('frontpage');

  return (
    <Select
      size="small"
      name="milestones-period-change"
      value={date}
      onChange={(event) => setDate(event.target.value)}
      data-automation-id="MilestonesHeaderSelect"
      inputProps={{ 'aria-label': t('MilestonesSelectLabel') }}
    >
      {Object.entries(milestoneDropdownOptions).map(([key, value]) => (
        <MenuItem
          key={key}
          value={key}
          data-automation-id={`MilestonesHeaderSelectOption${parseInt(key, 10) + 1}`}
        >
          {t(value)}
        </MenuItem>
      ))}
    </Select>
  );
};
export const MilestoneFeed = () => {
  const { data } = useGetFrontPageFeature();
  const [date, setDate] = useState('0');
  const { t } = useTranslation('frontpage');

  const showMilestones = data?.find((feat) => feat.name === 'ShowMileStones')?.value;

  useEffect(() => {
    if (showMilestones) setDate(showMilestones.toString());
  }, [showMilestones]);

  return (
    <Stack gap={3}>
      <WidgetHeader
        widgetKey="Milestone"
        heading={t('MilestonesHeading')}
        headingAs="h2"
        icon={<FlagOutlined data-automation-id="MilestonesHeaderIcon" />}
        action={<PeriodSelect date={date} setDate={(value: string) => setDate(value)} />}
        data-automation-id="MilestonesHeader"
      />
      <MilestoneFeedStatus date={getDateValue(date)} />
    </Stack>
  );
};
