import { RPRow } from '../types/resourcePlanner';

export const nestItems = (items: RPRow[]) => {
  if (!items.length) {
    return [];
  }
  const itemMap: Record<string, RPRow> = items
    .filter((item) => item.id)
    .reduce(
      (a, b) => ({ ...a, [b.id]: { ...b, children: b.children ?? [] } }),
      {} as Record<string, RPRow>,
    );
  return items.filter(Boolean).reduce<RPRow[]>((a, b) => {
    if (!b?.id || !itemMap[b.id]) {
      return a;
    }
    if (!b.parentId) {
      return [...a, itemMap[b.id]];
    }
    const parent = itemMap[b.parentId];
    if (parent) {
      parent.children = [
        ...((parent.children ?? []) as RPRow[]).filter((i) => i.id !== b.id),
        itemMap[b.id],
      ];
    }
    return a;
  }, []);
};
