import { toCamel } from 'src/utils/object';

const designSevenResult = {
  Entities: [
    {
      Properties: {
        PageId: '5f11ec8e-27f0-47b2-9bb2-667ed9f4800c',
        Name: 'pageName_NewFrontPage',
        PageIdentifier: 'DesignSevenFrontPage',
        PageUrl: 'http://localhost:3000/home',
      },
    },
    {
      Properties: {
        PageId: '667ed9f4800c-5f11ec8e-27f0-47b2-9bb2',
        Name: 'pageName_Reports',
        PageIdentifier: 'DesignSevenExampleReport',
        PageUrl: 'http://localhost:3000/reports/example-report',
      },
    },
    {
      Properties: {
        PageId: '29a0ea59-edfb-4539-8a6e-eea04e0dfe07',
        Name: 'pageName_RevenueForecast',
        PageIdentifier: 'RevenueForecast',
        PageUrl: 'http://localhost:3000/reports/revenue-forecast',
      },
    },
    {
      Properties: {
        PageId: '95a70234-0f6d-4165-935d-2447536209fb',
        Name: 'pageName_EmployeesOverview',
        PageIdentifier: 'EmployeesOverview',
        PageUrl: 'http://localhost:3000/employees',
      },
    },
    {
      Properties: {
        PageId: 'dc23305c-5d92-49ab-b67e-6e356218ff0b',
        Name: 'pageName_Employee',
        PageIdentifier: 'Employee',
        PageUrl: 'http://localhost:3000/employees/:id',
      },
    },
    {
      Properties: {
        PageId: 'A8841CCE-71B4-41B9-8F0C-278485685F1A',
        Name: 'pageName_DesignSevenEditEmployeeCompetence',
        PageIdentifier: 'DesignSevenEditEmployeeCompetence',
        PageUrl: 'http://localhost:3000/employees/:id/competences',
      },
    },
    {
      Properties: {
        PageId: 'c210b1c5-f3df-44d8-b17c-43c10a504c0d',
        Name: 'pageName_NewResourcePlanner',
        PageIdentifier: 'NewResourcePlanner',
        PageUrl: 'http://localhost:3000/employees/resource-planner',
      },
    },
    {
      Properties: {
        PageId: '1cb7f563-3574-e311-a677-8f28e14d6311',
        Name: 'Competences',
        PageIdentifier: 'SystemAdministrationEmployeeCompetencies',
        PageUrl: 'http://localhost:3000/system-administration/employees/competences',
      },
    },
    {
      Properties: {
        PageId: 'c3e04a88-0a00-487f-9e59-9d101a96462f',
        Name: 'pageName_SearchProjects',
        PageIdentifier: 'DesignSevenSearchProject',
        PageUrl: 'http://localhost:3000/projects/search-projects',
      },
    },
    {
      Properties: {
        PageId: '7af9b8f1-dc98-4bc7-a1a4-9081451b0d1b',
        Name: 'pageName_MoveHours',
        PageIdentifier: 'DesignSevenMoveHours',
        PageUrl: 'http://localhost:3000/projects/move-hours',
      },
    },
    {
      Properties: {
        PageId: '7d4180fc-3b8e-4953-9060-595fdeafc409',
        Name: 'Register',
        PageIdentifier: 'TimeRegistration',
        PageUrl: 'http://localhost/tlp/Registration/TimeTracking',
        PageActions: [
          {
            PageActionId: 30,
            Name: 'MileageRegistration_EditMileageRegistration',
            Url: 'http://localhost/tlp/Registration/Mileage/EditMileageRegistrationConfirmation/{MileageRegistrationID}',
            Type: 'Link',
          },
          {
            PageActionId: 32,
            Name: 'MileageRegistration_MileageRegistration',
            Type: 'Modal',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '7d80fc-3b8e-6049-9053-595f941deafc40',
        Name: 'Employee',
        PageIdentifier: 'Employee',
        PageUrl: 'http://localhost/tlp/Employee/List',
        PageActions: [
          {
            PageActionId: 41,
            Name: 'EmployeeDelete',
            Url: 'http://localhost/tlp/Registration/Mileage/EditMileageRegistrationConfirmation/{MileageRegistrationID}',
            Type: 'Link',
          },
          {
            PageActionId: 42,
            Name: 'Employee',
            Type: 'Modal',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: '595fdeafc409-7d4180fc-3b8e-4953-9060',
        Name: 'Example',
        PageIdentifier: 'example',
        PageUrl: 'http://localhost/tlp/sample-path/example',
        PageActions: [
          {
            PageActionId: 40,
            Name: 'Example_Link',
            Url: 'http://localhost/tlp/Registration/TimeTracking',
            Type: 'Link',
          },
          {
            PageActionId: 43,
            Name: 'Example_Modal',
            Type: 'Modal',
          },
        ],
      },
    },
    {
      Properties: {
        PageId: 'deafc40595f9-7cd4180f-3b8e-5349-0690',
        Name: 'Employees',
        PageIdentifier: '456',
        PageUrl: 'http://localhost/tlp/sample-path/employees/:id',
        PageActions: [
          {
            PageActionId: 50,
            Name: 'Employees_Link',
            Url: 'http://localhost/tlp/Registration/TimeTracking',
            Type: 'Link',
          },
          {
            PageActionId: 52,
            Name: 'Employees_Modal',
            Type: 'Modal',
          },
        ],
      },
    },
  ],

  Links: [
    {
      Href: 'https://app3.timelog.com/localarena2/api/v2/pages/design-seven',
      Rel: 'self',
    },
  ],
};

export const designSevenCamelCase = toCamel(designSevenResult);

export default designSevenResult;
