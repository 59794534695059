export default {
  Properties: {
    FeatureList: [
      {
        name: 'FrontPageInternalMessage',
        identifier: 466,
        enabled: true,
      },
      {
        name: 'DesignSevenNotificationCenter',
        identifier: 445,
        enabled: true,
      },
      {
        name: 'ShowMileStones',
        identifier: 133,
        enabled: true,
        value: 0,
      },
      {
        name: 'WidgetInvoicedPerMonth',
        identifier: 450,
        enabled: true,
      },
      {
        name: 'WidgetPlannedAbsence',
        identifier: 449,
        enabled: true,
      },
      {
        name: 'WidgetProjectEconomyHealth',
        identifier: 452,
        enabled: true,
      },
      {
        name: 'WidgetAverageHourlyRate',
        identifier: 453,
        enabled: false,
      },
      {
        name: 'WidgetTimeTrackingDelay',
        identifier: 461,
        enabled: false,
      },
      {
        name: 'WidgetVacationCalculation',
        identifier: 462,
        enabled: false,
      },
    ],
  },
  Links: [
    {
      Href: 'http://mockapi/api/v2/general/features',
      Rel: 'self',
    },
  ],
};
