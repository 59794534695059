import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { ICurrencyMatrixExchangeRatesResponse, ICurrencyMatrixExchangeRate } from '../types';

const EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_URL_PATH =
  '/api/v1/exchange-rate/matrix-exchange-rates-by-period-id';
export const EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_MSW_STATUS_KEY = 'currencyMatrixExchangeRateAPI';
export const EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY = 'EXCHANGE_RATE_MATRIX_BY_PERIOD_ID';

const getExchangeRateMatrixByPeriodId = (
  periodId: number,
): Promise<ICurrencyMatrixExchangeRatesResponse> =>
  getFetch({
    path: `${EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_URL_PATH}${concatQuerys([
      { param: 'periodId', value: periodId.toString() },
    ])}`,
    key: EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_MSW_STATUS_KEY,
  });

export const useGetMatrixExchangeRatesByPeriodId = (
  periodId: number,
  isReadyToRun: boolean = true /** If there is a dependent query, pass the status of that query here */,
) => {
  const { data, ...rest } = useQuery(
    [EXCHANGE_RATE_MATRIX_BY_PERIOD_ID_KEY, periodId],
    () => getExchangeRateMatrixByPeriodId(periodId),
    { enabled: isReadyToRun },
  );

  const matrixExchangeRates: ICurrencyMatrixExchangeRate[] = data?.entities
    ? data.entities.map(({ properties }) =>
        properties
          ? {
              ...properties,
            }
          : {
              intercompanyExchangeRateId: 0,
              amount: 0,
              lookupKey: '',
              inverseLookupKey: '',
              isImmutable: true,
            },
      )
    : [];

  return {
    matrixExchangeRates,
    ...rest,
  };
};
