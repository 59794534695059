import {
  flattenInfiniteQueryData,
  useGetGroupByResource,
  useGetPartialGroupByResource,
} from 'src/apis/resourcePlannerAPI';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { TCollapseExpandState } from 'src/screens/ResourcePlanner/helper/expandedRow';
import { nestItems } from '../helper/nestItems';

interface IUSeGetRefetchGroupByResource {
  expandedRows: TCollapseExpandState[];
  params: Record<string, string>;
  selectedFilterList: IFilterStoreProps['selectedFilterList'];
  take: number;
}

export const useGetResourceView = ({
  expandedRows,
  params,
  selectedFilterList,
  take,
}: IUSeGetRefetchGroupByResource) => {
  // 1. Get resource list
  const {
    data: infiniteData,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useGetGroupByResource({
    params,
    selectedFilterList,
    take,
  });

  const { children, isEmpty, latestPageChildrenLength, periods, responseType } =
    flattenInfiniteQueryData(infiniteData);

  // 2. Get children of expanded resources
  const partial = useGetPartialGroupByResource({
    expandedRows,
    params,
    selectedFilterList,
  });

  const partialChildren = partial.flatMap((p) => p.data?.model.properties.children ?? []);
  const partialWithExpandedIds = partial.map((p, i) => ({
    ...p,
    ...expandedRows[i],
  }));

  // 3. Combine data
  const nestedItems = nestItems([...children, ...partialChildren]);

  return {
    children: nestedItems,
    fetchNextPage,
    isEmpty,
    isFetching,
    isFetchingNextPage,
    isNextPageAvailable: latestPageChildrenLength === take,
    isRefetching,
    partial: partialWithExpandedIds,
    periods,
    responseType,
  };
};
