import { useQuery } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { ISearchProjectsWrapper } from '../types';

const SEARCH_PROJECTS_URL_PATH = '/api/v2/projects';
export const SEARCH_PROJECTS_MSW_STATUS_KEY = 'GetSearchProjectsAPI';
export const SEARCH_PROJECTS_KEY = 'SEARCH_PROJECTS';

interface IParams {
  [key: string]: string;
}

const projectsFetch = (
  filters: string,
  params: { [key: string]: string },
): Promise<ISearchProjectsWrapper> =>
  getFetch({
    path: `${SEARCH_PROJECTS_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value: params[key],
      })),
    )}`.concat(filters),
    key: SEARCH_PROJECTS_MSW_STATUS_KEY,
  });

export const useGetProjects = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: IParams,
) => {
  // Return {} if params is empty
  let filteredParams = {};
  if (params) {
    filteredParams = Object.entries(params).reduce<IParams>((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  const items = Object.keys(selectedFilterList);
  let filteredList = concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .flatMap((item) => [
        {
          param: `${item}Ids`,
          value: selectedFilterList[item].values.map((value) => value.value).toString(),
          isAppended: true,
        },
        {
          param: `Is${item}Inclusive`,
          value: String(selectedFilterList[item].isInclude),
          isAppended: true,
        },
      ]),
  );

  // Check if params is an empty object and replace the first '&' with '?'
  if (Object.keys(filteredParams).length === 0) {
    filteredList = filteredList.replace(/^&/, '?');
  }

  const { data, isError, isSuccess, ...restProps } = useQuery(
    [SEARCH_PROJECTS_KEY, params || {}, selectedFilterList || {}],
    async () => {
      const response = await projectsFetch(filteredList, filteredParams || {});

      return response;
    },
    {
      // any previous data will be kept when fetching new data because the query key changed.
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );

  const summations = {
    id: 'TOTAL',
    projectsCount: data?.properties?.projectsCount,
    allocationsBudgetHours: parseFloat(data?.properties?.totalAllocationsBudgetHours ?? '0'),
    budgetWorkHours: parseFloat(data?.properties?.totalBudgetWorkHours ?? '0'),
    projectActualHours: parseFloat(data?.properties?.totalProjectActualHours ?? '0'),
    tasksBudgetHours: parseFloat(data?.properties?.totalTasksBudgetHours ?? '0'),
  };

  return {
    data: data?.entities?.map((item) => ({
      ...item.properties,
    })),
    summations,
    isEmpty: (data?.entities?.length ?? 0) <= 0,
    isError,
    isSuccess,
    ...restProps,
  };
};
