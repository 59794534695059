import { rest } from 'msw';
import { eventHandler } from 'src/mocks/handlers';
import getCapacity from './getCapacity';
import getCompetenceContents from './getCompetenceContents';
import getEmployeeAllocation from './getEmployeeAllocation';
import getHourlyRates from './getHourlyRates';
import getRecentProjects from './getRecentProjects';
import getRecentResources from './getRecentResources';
import getRecentTasks from './getRecentTasks';
import getUnallocatedEmployees from './getUnallocatedEmployees';
import getUnallocatedTasks from './getUnallocatedTasks';
import postEmployeeSearch from './postEmployeeSearch';
import postProjectSearch from './postProjectSearch';

export const assignFlowHandlers = [
  rest.get('http://mockapi/api/v2/competence/content', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getCompetenceContents,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/contract-hourly-rates/dropdown-content', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getHourlyRates,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/:projectId/employee-allocation', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getEmployeeAllocation,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/:projectId/employee-unallocated', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getUnallocatedEmployees,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/projects/:projectId/tasks-unallocated', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getUnallocatedTasks,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/projects/recently-registered', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getRecentProjects,
      },
    }),
  ),
  rest.get(
    'http://mockapi/api/v2/resource-planner/resources/recently-registered',
    (req, res, ctx) =>
      eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getRecentResources,
        },
      }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/tasks/recently-registered', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getRecentTasks,
      },
    }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/user/:userId/capacity', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': getCapacity,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/resource-planner/employee-search', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': postEmployeeSearch,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/resource-planner/project-search', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': postProjectSearch,
      },
    }),
  ),
  rest.post('http://mockapi/api/v2/resource-planner/work-item-relationship/bulk', (req, res, ctx) =>
    eventHandler({
      req,
      res,
      ctx,
      responseData: {
        '200': ['OK'],
      },
    }),
  ),
];
