import { RefObject, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from 'src/components/mui-components/Stack';
import {
  useFilterDispatch,
  useFullFilterList,
  useSelectedFilterListInArray,
} from 'src/stores/FilterStore';
import { translationAnyText } from 'src/utils/translation';
import { Box, Button } from 'src/components/mui-components';
import { Header } from './components/Header';
import { InputGroup } from './components/InputGroup';
import { useFocusTrapping } from './helpers/focusTrapping';
import styles from './SideBar.module.scss';

import { FilterItem } from '../MainContainer/components/FilterItem';

interface IFilterList {
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

export const FilterList = ({ activateSidePanelButtonRef }: IFilterList) => {
  const dispatch = useFilterDispatch();
  const filterList = useFullFilterList();
  const selectedFilterList = useSelectedFilterListInArray();

  const projectFilterIsSelected = useMemo(
    () => selectedFilterList.some((filter) => filter.id === 'Project'),
    [selectedFilterList],
  );

  const dynamicFilterList = useMemo(() => {
    if (projectFilterIsSelected) {
      return filterList;
    }
    const filteredProjectItems = filterList
      .find((g) => g.filterCategory === 'Project')
      ?.filterItems?.filter((i) => i.name !== 'Task');
    return filterList.map((filter) => {
      if (filter.filterCategory === 'Project') {
        return { ...filter, filterItems: filteredProjectItems };
      }
      return filter;
    });
  }, [filterList, projectFilterIsSelected]);

  const { t } = useTranslation('filter');
  const sideBarRef = useRef<HTMLDivElement>(null);

  const showResetButton = selectedFilterList.length;

  useFocusTrapping(sideBarRef);

  return (
    <Box ref={sideBarRef}>
      <Stack gap={3} data-automation-id="SidePanel">
        <Header activateSidePanelButtonRef={activateSidePanelButtonRef} />
        {selectedFilterList.length || showResetButton ? (
          <Stack gap={1} className={styles.filterList}>
            {showResetButton ? (
              <Box ml="auto">
                <Button
                  variant="text"
                  size="small"
                  onClick={() => dispatch?.({ type: 'RESET_FILTER' })}
                  data-automation-id="FilterContainerRemoveFilters"
                >
                  {t('ClearAllFiltersButton')}
                </Button>
              </Box>
            ) : null}
            {selectedFilterList.map(({ id, ...restProps }) => (
              <FilterItem {...restProps} id={id} key={id} />
            ))}
          </Stack>
        ) : null}
        {dynamicFilterList.map(({ filterCategory = '', filterItems }) => (
          <InputGroup
            key={filterCategory}
            filterCategory={translationAnyText(t, `FilterCategory${filterCategory}`)}
            filterItems={filterItems || []}
          />
        ))}
      </Stack>
    </Box>
  );
};
