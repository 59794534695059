import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useFilterDispatch } from 'src/stores/FilterStore';
import { IChildInputComponent, IOnChangeInput } from '../types';

interface IOnChangeHandler extends Omit<IChildInputComponent, 'filterId'> {
  setListValue: Dispatch<SetStateAction<IOnChangeInput[] | null>>;
}
export const useOnChangeSingleHandler = ({
  contentUrl,
  fieldLabel,
  childFilters = [],
  parentFilters = [],
  isInclude,
  setListValue,
  type,
}: IOnChangeHandler) => {
  const dispatch = useFilterDispatch();

  return (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (dispatch) {
      if (checked) {
        dispatch({
          type: 'ADD_OR_UPDATE_FILTER',
          payload: {
            [fieldLabel]: {
              label: fieldLabel,
              values: [{ value: checked.toString() }],
              contentUrl,
              childFilters,
              parentFilters,
              isInclude,
              type,
            },
          },
        });
      } else {
        dispatch({ type: 'REMOVE_FILTER', payload: { filterID: fieldLabel } });
      }

      if (dispatch) {
        dispatch({ type: 'QUERY_FILTER' });
      }
    }
    setListValue([
      {
        value: checked.toString(),
        label: '',
      },
    ]);
  };
};
