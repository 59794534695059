import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useGetSavedView } from 'src/apis/savedViewAPI';
import { FilterSection } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/FilterSection';
import { format, subDays } from 'date-fns';
import { IOptionsChangeParameters } from 'src/components/layout/FilterPanelLayout/types';
import { useTranslation } from 'react-i18next';
import { useGetDateLocale } from 'src/utils/locale/dateLocale';
import {
  formatDateRange,
  handleMatchingOption,
  isValidDateRange,
} from 'src/components/layout/FilterPanelLayout/components/SaveViewContainer/utils';
import { TViewOptions } from 'src/apis/savedViewAPI/types';
import { Deck } from 'src/components/ui-components';
import { MoveHoursViewOptionsStateKey } from './localStorageKeys';

import { Table } from './components/Table';
import { PeriodPicker } from '../ResourcePlanner/components/PeriodPicker';
import { IMoveHoursError } from './types';

const defaultStartDate = subDays(new Date(), 7);
const defaultEndDate = subDays(new Date(), 0);

export const MoveHours = () => {
  const pageIdentifier = 'DesignSevenMoveHours';
  const { t: tDayPicker } = useTranslation('dayPicker');
  const userDateLocale = useGetDateLocale();
  const activateSidePanelButtonRef = useRef<HTMLButtonElement>(null);

  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const { data } = useGetSavedView(pageIdentifier);
  const savedView = useMemo(() => data || [], [data]);
  const [activeView, setActiveView] = useState<string>('');
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(undefined);
  const [rowHaveError, setRowHaveError] = useState<IMoveHoursError[]>([]);
  // is pre-defined period - ThisWeek, PreviousWeek, ThisMonth, PreviousMonth
  const [preDefinedPeriod, setPreDefinedPeriod] = useState<string>('');
  const [initialDateStart, setInitialDateStart] = useState(defaultStartDate);
  const [initialDateEnd, setInitialDateEnd] = useState(defaultEndDate);

  const setViewOptions = useCallback(() => {
    const viewOptions = savedView.find((v) => v.filterViewId === activeView)?.viewOptions;
    // Format date-range to period-starts-at and period-ends-at based on CurrentWeek, PastOneWeek, PastOneMonth or fix period
    if (viewOptions?.['date-range']) {
      const dateRange = viewOptions['date-range'];
      delete viewOptions['date-range'];

      if (isValidDateRange(dateRange)) {
        setPreDefinedPeriod('');
        formatDateRange(dateRange, viewOptions, setChangedViewOptions);
      } else {
        setPreDefinedPeriod(dateRange);
        handleMatchingOption(
          dateRange,
          viewOptions,
          setChangedViewOptions,
          initialDateStart,
          userDateLocale,
          tDayPicker,
        );
      }
    } else {
      setChangedViewOptions({
        ...viewOptions,
        'period-starts-at': format(defaultStartDate, 'yyyy-MM-dd'),
        'period-ends-at': format(defaultEndDate, 'yyyy-MM-dd'),
      });
    }
    if (rowHaveError.length > 0) {
      setRowHaveError([]);
    }
  }, [activeView, initialDateStart, rowHaveError.length, savedView, tDayPicker, userDateLocale]);

  useEffect(() => {
    setViewOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  const clearRowError = () => {
    if (rowHaveError.length > 0) {
      setRowHaveError([]);
    }
  };

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    if (changedViewOptions?.['period-starts-at']) {
      setInitialDateStart(new Date(changedViewOptions['period-starts-at']));
    } else {
      setInitialDateStart(defaultStartDate);
    }
    if (changedViewOptions?.['period-ends-at']) {
      setInitialDateEnd(new Date(changedViewOptions['period-ends-at']));
    } else {
      setInitialDateEnd(defaultEndDate);
    }
    localStorage.setItem(MoveHoursViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);
    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const handleDateViewOptionsChange = (item: IOptionsChangeParameters[]) => {
    onViewOptionsChange(item);
    setRowHaveError([]);
  };

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterPanelLayout
        filterList={filterList}
        savedViewsList={savedView}
        activeView={activeView}
        setActiveView={setActiveView}
        changedViewOptions={changedViewOptions}
        preDefinedPeriod={preDefinedPeriod}
        setViewOptions={setViewOptions}
        key="MoveHoursFilterPanelLayout"
        activateSidePanelButtonRef={activateSidePanelButtonRef}
      >
        <Deck.Item hasOverflow>
          <Table
            selectedViewOptions={changedViewOptions}
            onViewOptionsChange={onViewOptionsChange}
            rowHaveError={rowHaveError}
            setRowHaveError={setRowHaveError}
            filterSection={
              <FilterSection
                key="MoveHoursFilterSection"
                changedViewOptions={changedViewOptions}
                onViewOptionsChange={onViewOptionsChange}
                activateSidePanelButtonRef={activateSidePanelButtonRef}
                clearRowError={clearRowError}
                viewOptionsLeftFilter={
                  <PeriodPicker
                    initialDateStart={initialDateStart}
                    initialDateEnd={initialDateEnd}
                    onChange={handleDateViewOptionsChange}
                    preSelectOptions="pastPreSelectOptions"
                    setPreDefinedPeriod={setPreDefinedPeriod}
                  />
                }
              />
            }
          />
        </Deck.Item>
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
