import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { GET_SAVED_VIEW_KEY, PUT_SAVED_VIEW_MSW_STATUS_KEY } from '../constants';
import { ISavedViewBodyModel } from '../types';

const PUT_SAVED_VIEW_URL_PATH = '/api/v2/filter-view';

const updateSavedView = ({
  pageIdentifier,
  updateModel,
}: {
  pageIdentifier: string;
  updateModel: ISavedViewBodyModel;
}) =>
  putFetch({
    path: `${PUT_SAVED_VIEW_URL_PATH}/${pageIdentifier}`,
    key: PUT_SAVED_VIEW_MSW_STATUS_KEY,
    body: JSON.stringify(updateModel),
  });

export const useUpdateSavedView = () => {
  const queryClient = useQueryClient();

  return useMutation(updateSavedView, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_SAVED_VIEW_KEY]);
    },
  });
};
