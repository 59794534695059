import { useGetLocale } from 'src/components/global/LocaleProvider';
import { formatTime } from 'src/utils/time';
import { statusValueOptions } from 'src/screens/SearchProjects/constants';
import { useTranslation } from 'react-i18next';

export const useExcelConfig = () => {
  const siteLocale = useGetLocale();
  const { t } = useTranslation('searchProjects');

  return {
    ignoreColumns: ['__check__', 'hasAccountingIssues', 'action'],
    fileName: 'Search_projects.xlsx',
    sheetName: 'Search_projects',
    titleName: 'TimeLog - Search_projects',
    valueParser: {
      completion: (value: number | string) => `${value}%`,
      budgetWorkHours: (value: number | string) => formatTime(value, siteLocale),
      tasksBudgetHours: (value: number | string) => formatTime(value, siteLocale),
      allocationsBudgetHours: (value: number | string) => formatTime(value, siteLocale),
      projectActualHours: (value: number | string) => formatTime(value, siteLocale),
      statusId: (value: number | string) => {
        const statusOption = statusValueOptions.find((option) => option.value === value);
        return statusOption ? t(statusOption.label) : 'Unknown Status';
      },
    },
  };
};
