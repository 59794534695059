import { ReactNode, createContext, useContext } from 'react';
import { INITIAL_RP_STORE } from './ResourcePlannerStore.constants';
import { IResourcePlannerStoreValue } from './ResourcePlannerStore.types';

interface IResourcePlannerStore {
  children: ReactNode;
  value?: IResourcePlannerStoreValue;
}

const Context = createContext<IResourcePlannerStoreValue>(INITIAL_RP_STORE);

export const useResourcePlannerStore = () => useContext(Context);

export const ResourcePlannerStore = ({
  children,
  value = INITIAL_RP_STORE,
}: IResourcePlannerStore) => <Context.Provider value={value}>{children}</Context.Provider>;
