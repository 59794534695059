import { flattenInfiniteQueryData } from 'src/apis/resourcePlannerAPI';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { TCollapseExpandState } from 'src/screens/ResourcePlanner/helper/expandedRow';
import {
  useGetGroupByProject,
  useGetPartialGroupByProject,
} from '../../../apis/resourcePlannerAPI/get/getGroupByProjectAPI';
import { nestItems } from '../helper/nestItems';

interface IUSeGetRefetchGroupByProject {
  expandedRows: TCollapseExpandState[];
  params: Record<string, string>;
  selectedFilterList: IFilterStoreProps['selectedFilterList'];
  take: number;
}

export const useGetProjectView = ({
  expandedRows,
  params,
  selectedFilterList,
  take,
}: IUSeGetRefetchGroupByProject) => {
  // 1. Get project list
  const {
    data: infiniteData,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useGetGroupByProject({
    params,
    selectedFilterList,
    take,
  });

  const { children, isEmpty, latestPageChildrenLength, periods, responseType } =
    flattenInfiniteQueryData(infiniteData);

  // 2. Get children of expanded projects
  const partial = useGetPartialGroupByProject({
    expandedRows,
    params,
    selectedFilterList,
  });

  const partialChildren = partial.flatMap((p) => p.data?.model.properties.children ?? []);
  const partialWithExpandedIds = partial.map((p, i) => ({
    ...p,
    ...expandedRows[i],
  }));

  // 3. Combine data
  const nestedItems = nestItems([...children, ...partialChildren]);

  return {
    children: nestedItems,
    fetchNextPage,
    isEmpty,
    isFetching,
    isFetchingNextPage,
    isNextPageAvailable: latestPageChildrenLength === take,
    isRefetching,
    partial: partialWithExpandedIds,
    periods,
    responseType,
  };
};
