import { parse, format, isValid } from 'date-fns';
import { dateRangeLogic } from 'src/components/ui-components/DatePicker/DayRangePicker/helpers/preselectOptionsLogic';
import { Dispatch, SetStateAction } from 'react';
import { TViewOptionKey, TViewOptions } from 'src/apis/savedViewAPI/types';
import { TFunction } from 'i18next';
import { getPreSelectOptionsList } from '../../../../../screens/ResourcePlanner/components/PeriodPicker/PeriodPicker';

export const isValidDateRange = (dateRange: string) => {
  const [start, end] = dateRange.split(',');
  const startDate = parse(start, 'yyyy-MM-dd', new Date());
  const endDate = parse(end, 'yyyy-MM-dd', new Date());
  return isValid(startDate) && isValid(endDate);
};

export const formatDateRange = (
  dateRange: string,
  viewOptions: TViewOptions,
  setChangedViewOptions: Dispatch<
    SetStateAction<Partial<Record<TViewOptionKey, string>> | undefined>
  >,
) => {
  const [start, end] = dateRange.split(',');
  setChangedViewOptions({
    ...viewOptions,
    'period-starts-at': format(new Date(start), 'yyyy-MM-dd'),
    'period-ends-at': format(new Date(end), 'yyyy-MM-dd'),
  });
};

export const handleMatchingOption = (
  dateRange: string,
  viewOptions: Partial<Record<TViewOptionKey, string>>,
  setChangedViewOptions: Dispatch<
    SetStateAction<Partial<Record<TViewOptionKey, string>> | undefined>
  >,
  initialDateStart: Date,
  userDateLocale: Locale,
  tDayPicker: TFunction<'dayPicker', undefined>,
) => {
  const preSelectOptionsList = getPreSelectOptionsList(tDayPicker);
  const matchingOption = preSelectOptionsList.pastPreSelectOptions.find(
    (option) => option.name === dateRange,
  );

  if (matchingOption) {
    const { periodType, periodAmount, from, weekStartsOn } = matchingOption || {};

    const { dateFrom, period } = dateRangeLogic(
      periodType,
      initialDateStart,
      from,
      periodAmount.toString(),
      userDateLocale,
      weekStartsOn,
    );
    setChangedViewOptions({
      ...viewOptions,
      'period-starts-at': format(dateFrom, 'yyyy-MM-dd'),
      'period-ends-at': format(period, 'yyyy-MM-dd'),
    });
  }
};
