import { FilterList } from '@mui/icons-material';
import { Badge, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSidePanelDispatch } from 'src/components/layout/SidePanel';
import { Button, FormControl, InputLabel, Stack } from 'src/components/mui-components';
import { Deck } from 'src/components/ui-components';
import { useSelectedFilterListInArray } from 'src/stores/FilterStore';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { IViewOptionsField, ViewOptionsChangeParameters } from '../../../../types';

interface ResourceViewOptionsProps {
  viewOptionsLeftFilter?: ReactNode;
  viewOptionsFields?: Array<IViewOptionsField>;
  viewOptionsChange?: (item: ViewOptionsChangeParameters[]) => void;
}

export const ViewOptions = ({
  viewOptionsLeftFilter,
  viewOptionsFields,
  viewOptionsChange,
}: ResourceViewOptionsProps) => {
  const { t } = useTranslation('viewOptions');

  const sidePanelDispatch = useSidePanelDispatch();
  const selectedFilterList = useSelectedFilterListInArray();

  const toggleFilterPanel = () => {
    sidePanelDispatch?.({ type: 'TOGGLE_PANEL' });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    viewOptionsChange?.([{ name, value }]);
  };

  return (
    <Deck.Item>
      <Stack
        direction="row"
        justifyContent={viewOptionsLeftFilter ? 'space-between' : 'flex-end'}
        alignItems="bottom"
      >
        {viewOptionsLeftFilter && viewOptionsLeftFilter}
        {viewOptionsFields && viewOptionsFields.length > 0 && (
          <Stack direction="row">
            {viewOptionsFields?.map(({ name: fieldName, value: fieldValue, options }) => (
              <FormControl key={fieldName} size="small">
                <InputLabel id={`${fieldName}-label`}>
                  {translationAnyText(t, `FieldOption${stringToPascal(fieldName)}Label`)}
                </InputLabel>
                <Select
                  labelId={`${fieldName}-label`}
                  name={fieldName}
                  label={translationAnyText(t, `FieldOption${stringToPascal(fieldName)}Label`)}
                  value={fieldValue}
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                  data-automation-id={`ViewOptions${stringToPascal(fieldName)}`}
                >
                  {options?.map(({ value }) => {
                    const label = translationAnyText(t, `FieldOption${stringToPascal(value)}`);
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ))}
            <Button
              onClick={toggleFilterPanel}
              startIcon={
                <Badge
                  badgeContent={selectedFilterList.length}
                  color="primary"
                  data-automation-id="FilterContainerAddFilterCount"
                >
                  <FilterList />
                </Badge>
              }
              variant="text"
            >
              {t('filter')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Deck.Item>
  );
};
