import {
  AccountCircle,
  AssignmentOutlined,
  ContentPasteOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { Avatar, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton } from 'src/components/mui-components';
import { Text, TextLink } from 'src/components/ui-components';
import { getUserAvatar } from 'src/utils/getUserAvatar';
import { RTRow } from '../../types/resourcePlanner';
import { AssignFlow } from '../AssignFlow';
import styles from './NameColumnRenderer.module.scss';

interface INameColumnRenderer {
  handleOnToggleRow: (row: RTRow) => void;
  row: RTRow;
  rowsThatAreLoading: string[];
  value: string;
}

export const NameColumnRenderer = ({
  handleOnToggleRow,
  row,
  rowsThatAreLoading,
  value,
}: INameColumnRenderer) => {
  const { t } = useTranslation('resourcePlanner');

  const {
    original: {
      canAssign,
      canDelete,
      endsAt,
      hierarchyType,
      name,
      parentProject,
      parentTask,
      resourceId,
      resourceSourceReferenceId,
      resourceType,
      sourceReferenceId,
      startsAt,
      type,
      workItemId,
      workItemSourceReferenceId,
      workItemType,
      profileImage: profileImageLink = resourceSourceReferenceId
        ? getUserAvatar(resourceSourceReferenceId)
        : undefined,
    },
  } = row;

  const thisRowIsLoading = rowsThatAreLoading.includes(
    `${sourceReferenceId}_${resourceSourceReferenceId}`,
  );
  const isWorkItem = hierarchyType === 'workitem';
  const isProject = type === 'Project';
  const isTask = type === 'Task';
  const isEmployee = type === 'Employee';

  const onExpandClick = useCallback(async () => {
    handleOnToggleRow(row);
  }, [handleOnToggleRow, row]);

  const expandCollapseButton = useMemo(() => {
    if (thisRowIsLoading) {
      return <CircularProgress size={20} />;
    }
    return row.getIsExpanded() ? (
      <KeyboardArrowDown fontSize="small" />
    ) : (
      <KeyboardArrowRight fontSize="small" />
    );
  }, [row, thisRowIsLoading]);

  return (
    <Stack alignItems="center" direction="row" gap={1 / 2} height={32}>
      {row.getCanExpand() ? (
        <IconButton
          data-automation-id={`${row.getIsExpanded() ? 'Collapse' : 'Expand'}IconButton-${
            isWorkItem ? workItemSourceReferenceId : resourceSourceReferenceId
          }`}
          onClick={onExpandClick}
          size="small"
          title={row.getIsExpanded() ? t('RowButtonLabelCollapse') : t('RowButtonLabelExpand')}
        >
          {expandCollapseButton}
        </IconButton>
      ) : (
        <span className={styles.spacer} />
      )}
      {isProject && <AssignmentOutlined fontSize="small" />}
      {isTask && <ContentPasteOutlined fontSize="small" />}
      {isEmployee && (
        <div className={styles.avatarContainer}>
          {profileImageLink ? (
            <Avatar alt={value} className={styles.avatar} src={profileImageLink} />
          ) : (
            <AccountCircle fontSize="small" />
          )}
        </div>
      )}

      <div className={styles.name}>
        {isProject && (
          <TextLink
            href={`/ProjectManagement/Plan/Task/${row.original.workItemSourceReferenceId}`}
            title={t('ProjectLinkTooltip')}
            useTextColor
          >
            {value}
          </TextLink>
        )}
        {isTask && <span>{value}</span>}
        {isEmployee && (
          <>
            <Text inheritSize>{value}</Text>
            {row.original.role ? <Text size="subtips">{row.original.role}</Text> : null}
          </>
        )}
      </div>

      <AssignFlow
        canAssign={canAssign}
        canDelete={canDelete}
        isWorkItem={isWorkItem}
        isTask={isTask}
        value={{
          endsAt: new Date(endsAt),
          isWorkItem,
          name,
          parentProject,
          parentTask,
          resourceId,
          resourceSourceReferenceId,
          resourceType,
          startsAt: new Date(startsAt),
          workItemId,
          workItemSourceReferenceId,
          workItemType,
        }}
      />
    </Stack>
  );
};
