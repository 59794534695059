import { Stack } from '@mui/material';
import cx from 'classnames';
import styles from './ResourceBar.module.scss';

interface ResourceBarProps {
  depth: number;
}

export const ResourceBar = ({ depth }: ResourceBarProps) => (
  <Stack className={cx([styles.resourceBar, depth === 0 ? styles.depth0 : styles.depth1])} />
);
