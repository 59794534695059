import { addDays } from 'date-fns';
import { TDateRange } from '../types/resourcePlanner';

export const isPredefinedDateRange = (value: string): value is TDateRange =>
  ['OneWeek', 'OneMonth', 'ThreeMonths', 'OneYear'].includes(value);

export const getEndDateFromDateRange = (dateRange: TDateRange) => {
  switch (dateRange) {
    case 'OneWeek':
      return addDays(new Date(), 7);

    case 'ThreeMonths':
      return addDays(new Date(), 90);

    case 'OneYear':
      return addDays(new Date(), 365);

    case 'OneMonth':
    default:
      return addDays(new Date(), 30);
  }
};

export const getDateRange = (dateRange?: TDateRange | string) => {
  if (!dateRange) {
    return {
      startDate: new Date(),
      endDate: new Date(),
      predefinedPeriod: '',
    };
  }

  if (isPredefinedDateRange(dateRange)) {
    return {
      startDate: new Date(),
      endDate: getEndDateFromDateRange(dateRange),
      predefinedPeriod: dateRange,
    };
  }

  const [startDate, endDate] = dateRange.split(',');

  return { startDate, endDate, predefinedPeriod: '' };
};
