export const filterSearchProjectsFullResult = {
  Entities: [
    {
      Properties: {
        FilterCategory: 'Project',
        FilterItems: [
          {
            Id: 46,
            Name: 'LegalEntityProject',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/legal-entity-project/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 2,
            Name: 'Customer',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/customer/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 53,
            Name: 'ProjectDepartment',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-department/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 6,
            Name: 'AccountManager',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/account-manager/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 45,
            Name: 'ContractModel',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/contract-model/content',
          },
          {
            Id: 59,
            Name: 'ContractStatus',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/contract-status/content',
          },
          {
            Id: 51,
            Name: 'ProjectStatus',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-status/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 5,
            Name: 'ProjectManager',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-manager/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 8,
            Name: 'ProjectType',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-type/content',
            ChildFilters: ['Project'],
          },
          {
            Id: 9,
            Name: 'ProjectCategory',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-category/content',
          },
          {
            Id: 60,
            Name: 'ProjectStage',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-stage/content',
          },
          {
            Id: 61,
            Name: 'ProjectInactivityPeriod',
            Type: 'SingleSelectTypeSearch',
            ContentUrl: 'api/v2/filters/project-inactivity-period/content',
          },
        ],
      },
    },
    {
      Properties: {
        FilterCategory: 'General',
        FilterItems: [
          {
            Id: 7,
            Name: 'CustomerOwner',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/customer-owner/content',
            ChildFilters: ['Customer'],
          },
          {
            Id: 21,
            Name: 'Contact',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/contact/content',
            ChildFilters: ['Customer'],
          },
          {
            Id: 58,
            Name: 'Partner',
            Type: 'MultiSelectTypeSearch',
            ContentUrl: 'api/v2/filters/partner/content',
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/list/DesignSevenSearchProjects',
      Rel: 'self',
    },
  ],
};
