export const SNACKBAR_CONFIG = [
  {
    type: 'save',
    title: 'SaveViewSuccessToastTitle',
    description: 'SaveViewSuccessToastBody',
  },
  {
    type: 'edit',
    title: 'UpdateViewSuccessToastTitle',
    description: 'UpdateViewSuccessToastBody',
  },
  {
    type: 'delete',
    title: 'DeleteViewSuccessToastTitle',
    description: 'DeleteViewSuccessToastBody',
  },
  {
    type: 'changes',
    title: 'ChangesViewSuccessToastTitle',
    description: 'ChangesViewSuccessToastBody',
  },
  {
    type: 'duplicate',
    title: 'DuplicateViewSuccessToastTitle',
    description: 'DuplicateViewSuccessToastBody',
  },
];

export const SEARCH_TEXT_FILTER = {
  name: 'SearchText',
  value: '',
  isInclusive: true,
  filterType: 'Textbox',
};

export const DATE_RANGE_FILTER = {
  name: 'DateRange',
  value: {
    PeriodStartsAt: '',
    PeriodEndsAt: '',
  },
  isInclusive: true,
  filterType: 'DateRange',
};

export const SINGLE_SELECT_FILTER = {
  filterType: 'SingleSelect',
  isInclusive: true,
  name: '',
  value: '',
};

export const preDefinedFilters = ['MyActiveProjects', 'ActiveProjects', 'AllProjects', 'MyView'];
