import { useInfiniteQuery, useQueries } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { IResourcePlannerItem, IResourcePlannerResult } from 'src/apis/types/resourcePlannerAPI';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { TCollapseExpandState } from 'src/screens/ResourcePlanner/helper/expandedRow';
import { RPRow } from 'src/screens/ResourcePlanner/types/resourcePlanner';
import { postFetch } from 'src/utils/fetcher';
import { GROUP_BY_PROJECT_KEY, PARTIAL_GROUP_BY_PROJECT_KEY } from '../constants';
import { getFiltersParam, getQueryParams } from '../utils';

interface IGroupByProjectBase {
  params: Record<string, string>;
  selectedFilterList: IFilterStoreProps['selectedFilterList'];
}

const getTranslatedStatus = (
  result: IResourcePlannerItem[],
  t: TFunction<'resourcePlanner', undefined>,
) =>
  result.map(({ children, ...child }): RPRow => {
    const parentList = child.pathToParent?.split('/')?.filter(Boolean);
    return {
      ...child,
      children: children ? getTranslatedStatus(children, t) : undefined,
      id: `${child.resourceId}_${child.workItemId}`,
      parentId: (() => {
        if (parentList?.length) {
          return `${child.resourceId}_${parentList[parentList.length - 1]}`;
        }
        return child.hierarchyType === 'resource' ? `0_${child.workItemId}` : child.parentId;
      })(),
      translatedStatus: t(child.status),
    };
  });

// Fetch project list

const GROUP_BY_PROJECT_URL_PATH = '/api/v2/resource-planner/partial-group-by-work-item-first-load';

const resourcePlannerFetch = (
  filters: string,
  params: Record<string, string>,
): Promise<IResourcePlannerResult> =>
  postFetch({
    path: `${GROUP_BY_PROJECT_URL_PATH}${getQueryParams(params)}`.concat(filters),
    key: GROUP_BY_PROJECT_KEY,
  });

interface IUseGetGroupByProject extends IGroupByProjectBase {
  take: number;
}

export const useGetGroupByProject = ({
  params,
  selectedFilterList,
  take,
}: IUseGetGroupByProject) => {
  const { t } = useTranslation('resourcePlanner');
  const filtersParam = getFiltersParam(selectedFilterList);

  return useInfiniteQuery<IResourcePlannerResult>({
    getNextPageParam: (_lastGroup, groups) => groups.length,
    // keepPreviousData: true,
    queryFn: async ({ pageParam = 0 }) => {
      const reqParams: Record<string, string> = {
        ...params,
        skip: String(pageParam * take),
        take: String(take),
      };
      const response = await resourcePlannerFetch(filtersParam, reqParams);
      if (response?.model?.properties?.children) {
        response.model.properties.children = getTranslatedStatus(
          response.model.properties.children,
          t,
        );
      }
      return response;
    },
    queryKey: [GROUP_BY_PROJECT_KEY, params, selectedFilterList],
    // refetchOnWindowFocus: false,
    // staleTime: Infinity,
  });
};

// Fetch project children

const PARTIAL_GROUP_BY_PROJECT_URL_PATH = '/api/v2/resource-planner/partial-group-by-work-item';

const partialResourcePlannerFetch = (
  filters: string,
  params: Record<string, string>,
  expandId: string,
  expandType: IResourcePlannerItem['type'],
): Promise<IResourcePlannerResult> =>
  postFetch({
    path: `${PARTIAL_GROUP_BY_PROJECT_URL_PATH}${getQueryParams(params)}`.concat(filters),
    key: PARTIAL_GROUP_BY_PROJECT_KEY,
    body: JSON.stringify({ expandId, expandType }),
  });

interface IUseGetPartialGroupByProject extends IGroupByProjectBase {
  expandedRows: TCollapseExpandState[];
}

export const useGetPartialGroupByProject = ({
  expandedRows,
  params,
  selectedFilterList,
}: IUseGetPartialGroupByProject) => {
  const { t } = useTranslation('resourcePlanner');
  const filtersParam = getFiltersParam(selectedFilterList);

  return useQueries({
    queries: expandedRows.map(({ originalId: rowId, tableRowId, type: rowType }) => ({
      // keepPreviousData: true,
      queryFn: async () => {
        const response = await partialResourcePlannerFetch(filtersParam, params, rowId, rowType);
        if (response?.model?.properties?.children) {
          response.model.properties.children = getTranslatedStatus(
            response.model.properties.children,
            t,
          );
        }
        return response;
      },
      queryKey: [PARTIAL_GROUP_BY_PROJECT_KEY, tableRowId, params, selectedFilterList],
      // staleTime: Infinity,
    })),
  });
};
