import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useGetInternalMessage, useUpdateInternalMessage } from 'src/apis/internalMessageAPI';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { RichTextEditor } from 'src/components/ui-components/RichText';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IconButton, Stack, ToastifyAlert, Typography } from 'src/components/mui-components';
import { Close, EditOutlined } from '@mui/icons-material';
import { StaticText } from './components';
import styles from './InternalMessageWidget.module.scss';

export const InternalMessageWidget = () => {
  const { isError, isLoading, internalMessage, isEditable } = useGetInternalMessage();

  const { t } = useTranslation('frontpage');
  const queryClient = useQueryClient();

  const {
    mutate: internalMessageAPIUpdate,
    isLoading: mutationIsLoading,
    isError: mutationIsError,
  } = useUpdateInternalMessage();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleUpdate = (html: string) => {
    internalMessageAPIUpdate(html, {
      onSuccess: () => {
        queryClient.invalidateQueries(['INTERNAL_MESSAGE']);
        toast.success(<ToastifyAlert description={t('InternalMessageIsSavedFeedback')} />, {
          autoClose: 2000,
          closeButton: false,
        });
      },
    });
  };

  const exitFunction = () => {
    setIsEditing(false);
  };

  return (
    <Stack gap={1} data-automation-id="InternalMessageWidget" className={styles.wrapper}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h3"
          component="h2"
          fontWeight={700}
          data-automation-id="InternalMessageWidgetHeading"
        >
          {t('InternalMessageWidgetHeading')}
        </Typography>
        {isEditable && (
          <IconButton
            data-automation-id="InternalMessageButtonEdit"
            title={
              isEditing
                ? t('InternalMessageWidgetCancelEdit')
                : t('InternalMessageWidgetEditInternalMessage')
            }
            onClick={() => {
              setIsEditing((prev) => !prev);
            }}
          >
            {isEditing ? <Close /> : <EditOutlined />}
          </IconButton>
        )}
      </Stack>
      <ResponseHandler
        isError={isError || mutationIsError}
        isLoading={isLoading || mutationIsLoading}
      >
        {isEditable && isEditing ? (
          <RichTextEditor
            data-automation-id="InternalMessageTextEditable"
            value={internalMessage}
            saveFunction={handleUpdate}
            exitFunction={exitFunction}
          />
        ) : (
          <StaticText
            isEmpty={internalMessage === '<p style=""></p>' || internalMessage === ''}
            internalMessage={internalMessage}
            standardText={t('InternalMessageWidgetNoMessage')}
          />
        )}
      </ResponseHandler>
    </Stack>
  );
};
