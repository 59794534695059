import { memo } from 'react';
import { THorizontalAlignment } from 'src/assets/variables/alignment';
import { TableCellColors } from 'src/assets/variables/table';
import { Table, TCellText } from 'src/components/ui-components/Table';
import { RTColumn, RTRow } from '../../types/resourcePlanner';
import EditableCell from '../EditableCell';

interface IMemoizedRenderBodyCellPeriodColumn {
  column: RTColumn;
  handleOnCellValueChanged: ((row: RTRow, column: RTColumn, value: string) => void) | undefined;
  heatmapCode: TableCellColors | string;
  isCellEditable: boolean;
  reportingType: string;
  row: RTRow;
  unitType: string;
  alignment?: THorizontalAlignment;
  dividerBorderLeft?: boolean;
  editable?: boolean;
  style?: React.CSSProperties;
  type?: TCellText;
}

export const MemoizedRenderBodyCellPeriodColumn = memo(
  ({
    alignment,
    column,
    dividerBorderLeft,
    editable,
    handleOnCellValueChanged,
    heatmapCode,
    isCellEditable,
    reportingType,
    row,
    style,
    type,
    unitType,
  }: IMemoizedRenderBodyCellPeriodColumn) => (
    <Table.Cell
      alignment={alignment}
      bgColor={heatmapCode}
      dividerBorderLeft={dividerBorderLeft}
      hasOverflow={editable}
      style={style}
      type={type}
    >
      <EditableCell
        column={column}
        editable={isCellEditable}
        reportingType={reportingType}
        row={row}
        unitType={unitType}
        updateMyData={handleOnCellValueChanged}
      />
    </Table.Cell>
  ),
  (prevProp, nextProp) => {
    const isSameCellEditable = prevProp.isCellEditable === nextProp.isCellEditable;

    const isSameUnitType = prevProp.unitType === nextProp.unitType;

    const isSameReportingType = prevProp.reportingType === nextProp.reportingType;

    const periodColumnIdSubstring = prevProp.column.id.substring(
      prevProp.column.id.indexOf('_') + 1,
      prevProp.column.id.length,
    );

    const isSameHeatmapColor = prevProp.heatmapCode === nextProp.heatmapCode;

    const prevPropActualValue =
      prevProp.row?.original?.values?.[periodColumnIdSubstring]?.displayValue || '0';

    const nextPropActualValue =
      nextProp.row?.original?.values?.[periodColumnIdSubstring]?.displayValue || '0';

    const isSameActualValue = prevPropActualValue === nextPropActualValue;

    return (
      isSameCellEditable &&
      isSameUnitType &&
      isSameReportingType &&
      isSameHeatmapColor &&
      isSameActualValue
    );
  },
);
