import { useEffect, useRef } from 'react';
import { TFilterType } from 'src/apis/types/filterListAPI';
import { Autocomplete } from 'src/components/mui-components';
import { DataAutomationId } from 'src/utils/ts-utils';

export interface IFilterSelectOnChangeEvent {
  label?: string;
  value: string;
}

export interface IFilterItemProperties<U>
  extends Partial<Omit<HTMLFormElement, 'contentEditable'>>,
    DataAutomationId {
  fieldLabel: string;
  filterId: string;
  options?: Array<IFilterSelectOnChangeEvent>;
  defaultValue?: U;
  hiddenLabel?: boolean;
  type?: TFilterType;
}

export type TFilterItemProperties = IFilterItemProperties<Array<IFilterSelectOnChangeEvent>>;

const FilterInput = ({
  fieldLabel,
  filterId,
  addFilterItem,
  options = [],
  defaultValue,
  type,
  ...restProps
}: TFilterItemProperties) => {
  const inputAutoCompleteRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputAutoCompleteRef.current) {
      inputAutoCompleteRef.current.focus();
    }
  }, [options]);

  return (
    <Autocomplete
      inputRef={inputAutoCompleteRef}
      fullWidth
      multiple={type === 'MultiSelectTypeSearch'}
      options={options}
      defaultValue={defaultValue}
      disableCloseOnSelect
      disablePortal
      disableClearable
      componentsProps={{
        popper: {
          modifiers: [
            {
              // We add this to prevent the popper from hiding the "is" and "is not" radio buttons
              name: 'flip',
              enabled: false,
            },
          ],
        },
      }}
      {...restProps}
      data-automation-id={restProps['data-automation-id']}
    />
  );
};

export default FilterInput;
