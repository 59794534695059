export default {
  Model: {
    class: 'countresponse',
    properties: {
      tenantId: '10007',
      count: 14,
    },
    links: [
      {
        href: 'http://test1.resourceplanner2.aks.timelog.com:80/workitem/getallprojectcount',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 1435365136,
  StatusCode: 200,
};
