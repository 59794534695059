import SaveIcon from '@mui/icons-material/Save';
import SyncIcon from '@mui/icons-material/Sync';
import { MenuItem } from '@mui/material';
import { ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISavedView, TSavedViewAction } from 'src/apis/savedViewAPI';
import { useSidePanelDispatch } from 'src/components/layout/SidePanel';
import { Button, Stack, Tab, TabMenu, Tabs, Typography } from 'src/components/mui-components';
import { Deck } from 'src/components/ui-components';
import { useMenu } from 'src/hooks/useMenu';
import { IMenuItem } from 'src/reducers/FilterReducer/FilterReducer';
import { TViewOptions } from 'src/screens/ResourcePlanner/types/resourcePlanner';
import {
  useFilterDispatch,
  useFilterStore,
  useSelectedFilterListInArray,
} from 'src/stores/FilterStore';
import { stringToPascal } from 'src/utils/string';
import { IViewOptionsField, ViewOptionsChangeParameters } from '../../types';
import { FilterContainer } from './components/FilterContainer';
import { FilterItem } from './components/FilterItem';
import { ViewOptions } from './components/ViewOptions';

interface IMainContainer {
  children: ReactNode;
  viewOptionsLeftFilter?: ReactNode;
  viewOptionsFields?: Array<IViewOptionsField>;
  viewOptionsChange?: (item: ViewOptionsChangeParameters[]) => void;
  localStorageNamePrefix: string;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
  allowSavedViews?: boolean;
  savedViewsList?: ISavedView[];
  activeView?: string;
  menuItems?: IMenuItem[];
  handleViewAction?: (action: TSavedViewAction) => void;
  setActiveView?: (view?: string) => void;
  changedViewOptions?: TViewOptions;
  allowSearch?: boolean;
  searchText?: string;
  initialSearchValue?: string;
}

export const MainContainer = ({
  children,
  activateSidePanelButtonRef,
  viewOptionsLeftFilter,
  viewOptionsFields,
  viewOptionsChange,
  localStorageNamePrefix,
  allowSavedViews = false,
  savedViewsList = [],
  activeView = '',
  menuItems = [],
  handleViewAction = () => {},
  setActiveView = () => {},
  changedViewOptions,
  allowSearch,
  searchText,
  initialSearchValue,
}: IMainContainer) => {
  const selectedFilterList = useSelectedFilterListInArray();
  const sidePanelDispatch = useSidePanelDispatch();
  const dispatch = useFilterDispatch();
  const { t } = useTranslation('filter');

  const ICON_BUTTON_ID = 'view-button';
  const TAB_MENU_ID = 'view-menu';

  const [haveFilterChanges, setHaveFilterChanges] = useState(false);
  const [haveOptionChanges, setHaveOptionChanges] = useState(false);
  const { anchorEl, menuIsOpen, setAnchorEl } = useMenu();

  const { filterQueryObj } = useFilterStore();

  const handleFilterChanges = useCallback(
    (id: string) => {
      const payload = savedViewsList?.find((v) => v.id === id)?.filters;
      if (dispatch) {
        dispatch({ type: 'RESET_FILTER' });
        if (payload && Object.keys(payload).length) {
          dispatch({ type: 'ADD_OR_UPDATE_FILTER', payload });
        }
        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    },
    [dispatch, savedViewsList],
  );

  const hasChanges = useMemo(
    () => haveFilterChanges || haveOptionChanges,
    [haveFilterChanges, haveOptionChanges],
  );

  const tabOnChange = (id: string) => {
    setActiveView(id);
    handleFilterChanges(id);
  };

  const toggleFilterPanel = () => {
    sidePanelDispatch?.({ type: 'TOGGLE_PANEL' });
  };

  const handleMenuItemClick = (item: any) => {
    item.action();
    setAnchorEl(null);
  };

  useEffect(() => {
    setAnchorEl(null);
    setHaveFilterChanges(false);
    setHaveOptionChanges(false);
    if (allowSavedViews) {
      handleFilterChanges(activeView);
    }
  }, [activeView, allowSavedViews, handleFilterChanges, setAnchorEl]);

  // To set filter on first load of saved view when there is filter
  useEffect(() => {
    const payload = savedViewsList?.find((v) => v.id === activeView)?.filters;
    if (payload && Object.keys(payload).length) {
      dispatch?.({ type: 'ADD_OR_UPDATE_FILTER', payload });
    }
  }, [activeView, dispatch, savedViewsList]);

  // Compare changes for filters and view
  useEffect(() => {
    const selectedView = savedViewsList?.find((view) => view.id === activeView);
    if (selectedView) {
      // Compare changes for filters
      if (filterQueryObj !== undefined) {
        setHaveFilterChanges(
          JSON.stringify(filterQueryObj) !== JSON.stringify(selectedView?.filters ?? {}),
        );
      }

      // Compare changes for view options
      if (changedViewOptions) {
        setHaveOptionChanges(
          JSON.stringify(changedViewOptions) !== JSON.stringify(selectedView?.viewOptions),
        );
      }
    }
  }, [activeView, changedViewOptions, filterQueryObj, savedViewsList]);

  return (
    <Deck data-automation-id="MainContainer">
      <Deck.Item>
        <FilterContainer
          activeFilters={selectedFilterList.length}
          openFilterPanel={toggleFilterPanel}
          activateSidePanelButtonRef={activateSidePanelButtonRef}
          clearAllFilters={() => dispatch?.({ type: 'RESET_FILTER' })}
          savedViewsList={savedViewsList}
          filterButtonText={t('ShowActiveFiltersButton')}
          noActiveFiltersText={t('NoActiveFiltersText')}
          addFilterButtonText={t('AddFilterButtonText')}
          clearAllFiltersButtonText={t('ResetFilterButtonText')}
          localStorageNamePrefix={localStorageNamePrefix}
          allowSearch={allowSearch}
          searchText={searchText}
          initialSearchValue={initialSearchValue}
          viewOptionsChange={viewOptionsChange}
          primaryActions={
            allowSavedViews ? (
              <>
                <Button
                  startIcon={<SyncIcon />}
                  onClick={() => handleViewAction('save')}
                  disabled={!hasChanges}
                  data-automation-id="FilterContainerSaveChanges"
                >
                  {t('SaveChangesButtonText')}
                </Button>
                <Button
                  startIcon={<SaveIcon />}
                  onClick={() => handleViewAction('saveAs')}
                  data-automation-id="FilterContainerSavedView"
                >
                  {t('SaveViewButtonText')}
                </Button>
              </>
            ) : null
          }
          tabRowTemplate={
            allowSavedViews ? (
              <>
                <Tabs value={activeView} onChange={(_, v) => tabOnChange(v)}>
                  {savedViewsList.map((v) => (
                    <Tab
                      component="a"
                      key={v.id}
                      value={v.id}
                      isDefault={v.isDefault}
                      iconPosition="start"
                      label={v.title}
                      aria-current={activeView === v.id}
                      data-automation-id={`FilterContainer${stringToPascal(v.title).replace(
                        / /g,
                        '',
                      )}Tab`}
                      threeDots={{
                        iconButtonAriaControls: TAB_MENU_ID,
                        iconButtonId: ICON_BUTTON_ID,
                        isActive: activeView === v.id,
                        isOpen: menuIsOpen,
                        handleClick: (e) => {
                          setAnchorEl(e.currentTarget);
                        },
                      }}
                    />
                  ))}
                </Tabs>
                <TabMenu
                  id={TAB_MENU_ID}
                  anchorEl={anchorEl}
                  open={menuIsOpen}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{ 'aria-labelledby': ICON_BUTTON_ID }}
                >
                  {menuItems.map((item) => (
                    <MenuItem
                      disabled={item.id === 'delete' && savedViewsList.length === 1}
                      key={item.id}
                      onClick={() => handleMenuItemClick(item)}
                    >
                      <Stack direction="row" alignItems="center">
                        <Stack color="#444">{item.icon}</Stack>
                        <Typography fontSize={14}>{item.label}</Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </TabMenu>
              </>
            ) : null
          }
        >
          {selectedFilterList.map(({ id, ...restProps }) => (
            <FilterItem {...restProps} id={id} key={id} />
          ))}
        </FilterContainer>

        <ViewOptions
          viewOptionsLeftFilter={viewOptionsLeftFilter}
          viewOptionsFields={viewOptionsFields}
          viewOptionsChange={viewOptionsChange}
        />
      </Deck.Item>

      <Deck.Item hasOverflow>{children}</Deck.Item>
    </Deck>
  );
};
