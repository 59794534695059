import { useQuery } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { IMoveHoursWrapper } from '../types';

const MOVE_HOURS_URL_PATH = '/api/v2/adjustments/move-hours';
export const MOVE_HOURS_MSW_STATUS_KEY = 'GetMoveHoursAPI';
export const MOVE_HOURS_KEY = 'MOVE_HOURS';

interface IParams {
  [key: string]: string;
}

const moveHoursFetch = (
  filters: string,
  params: { [key: string]: string },
): Promise<IMoveHoursWrapper> =>
  getFetch({
    path: `${MOVE_HOURS_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value: params[key],
      })),
    )}`.concat(filters),
    key: MOVE_HOURS_MSW_STATUS_KEY,
  });

export const useGetMoveHours = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: IParams,
) => {
  // Return {} if params is empty
  let filteredParams = {};
  if (params) {
    filteredParams = Object.entries(params).reduce<IParams>((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  const items = Object.keys(selectedFilterList);
  let filteredList = concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .flatMap((item) => [
        {
          param: `${item}Ids`,
          value: selectedFilterList[item].values.map((value) => value.value).toString(),
          isAppended: true,
        },
        {
          param: `Is${item}Inclusive`,
          value: String(selectedFilterList[item].isInclude),
          isAppended: true,
        },
      ]),
  );

  // Check if params is an empty object and replace the first '&' with '?'
  if (Object.keys(filteredParams).length === 0) {
    filteredList = filteredList.replace(/^&/, '?');
  }

  const { data, ...restProps } = useQuery(
    [MOVE_HOURS_KEY, params || {}, selectedFilterList || {}],
    async () => {
      const response = await moveHoursFetch(filteredList, filteredParams || {});

      return response;
    },
    {
      keepPreviousData: true,
      staleTime: 2000,
    },
  );

  const {
    totalRegisteredHours,
    isCommentMandatory,
    isShowingProjectNo,
    isShowingTaskNo,
    additionalCommentFieldName,
  } = data?.properties || {};

  const summations = {
    id: 'TOTAL',
    registeredHours: totalRegisteredHours ?? '0',
  };

  return {
    data: data?.entities?.map((item) => ({
      ...item.properties,
    })),
    summations,
    additionalCommentLabel: additionalCommentFieldName ?? '',
    isCommentMandatory: isCommentMandatory ?? false,
    isShowingProjectNo: isShowingProjectNo ?? false,
    isShowingTaskNo: isShowingTaskNo ?? false,
    isEmpty: (data?.entities?.length ?? 0) <= 0,
    ...restProps,
  };
};
