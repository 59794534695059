import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { IAddedValueBarPerMonthWidgetDataItem } from 'src/apis/reportingWidgetsAPI/types/addedValuePerMonth';

interface CustomTooltipWithBarProps {
  axisValue: string | number | Date | null;
  data: IAddedValueBarPerMonthWidgetDataItem[];
  alignment: string;
  currencyAbb: string;
  t: (key: string) => string;
  valueFormatter: (value: number) => string;
}

export const CustomTooltipWithBar = ({
  axisValue,
  data,
  alignment,
  currencyAbb,
  t,
  valueFormatter,
}: CustomTooltipWithBarProps) => {
  const dataPoint = data.find((item) => item.month === axisValue) ?? {
    amountRegistered: 0,
    amountEstimated: 0,
    bar: 0,
    hours: 0,
    hoursEstimated: 0,
    registeredAmountYTD: 0,
    estimatedAmountYTD: 0,
    bARAmountYTD: 0,
    hoursYTD: 0,
    hoursEstimatedYTD: 0,
  };

  const isRegistered = alignment === 'reg';

  const calculateHourlyRate = (amount: number, hours: number) => {
    if (!hours) return amount;
    return amount / hours;
  };

  const formatAmount = (amount: number) => `${valueFormatter(amount)} ${currencyAbb}`;
  const formatHours = (hours: number) => valueFormatter(hours);

  const renderHourlyRate = (amount: number, hours: number) => {
    const rate = calculateHourlyRate(amount, hours);
    return (
      <>
        {formatAmount(amount)} / {valueFormatter(hours)} {t('AddedValuePerMonthHours')} ={' '}
        {formatAmount(rate)}
      </>
    );
  };

  const currentPeriod = {
    amount: isRegistered ? dataPoint.amountRegistered ?? 0 : dataPoint.amountEstimated ?? 0,
    hours: isRegistered ? dataPoint.hours ?? 0 : dataPoint.hoursEstimated ?? 0,
    bar: dataPoint.bar ?? 0,
  };

  const ytdPeriod = {
    amount: isRegistered ? dataPoint.registeredAmountYTD ?? 0 : dataPoint.estimatedAmountYTD ?? 0,
    hours: isRegistered ? dataPoint.hoursYTD ?? 0 : dataPoint.hoursEstimatedYTD ?? 0,
    bar: dataPoint.bARAmountYTD ?? 0,
  };

  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent>
        <Stack gap={1}>
          <Typography component="h3">
            <strong>{String(axisValue)}</strong>
          </Typography>

          <Typography>
            {t(isRegistered ? 'AddedValuePerMonthRegAmount' : 'AddedValuePerMonthEstAmount')}:{' '}
            {formatAmount(currentPeriod.amount)}
          </Typography>

          <Typography>
            {t('AddedValuePerMonthBarAmount')}: {formatAmount(currentPeriod.bar)}
          </Typography>

          <Typography>
            {t('AddedValuePerMonthHours')}: {formatHours(currentPeriod.hours)}
          </Typography>

          <Box>
            <Typography>
              {t('AverageHourlyRate')} ({t('BarText')}):
            </Typography>
            <Typography>{renderHourlyRate(currentPeriod.bar, currentPeriod.hours)}</Typography>
          </Box>
        </Stack>

        <Stack sx={{ mt: 2 }} gap={1}>
          <Typography component="h3">
            <strong>{t('YearToDateText')}</strong>
          </Typography>

          <Typography>
            {t(isRegistered ? 'AddedValuePerMonthRegAmount' : 'AddedValuePerMonthEstAmount')}:{' '}
            {formatAmount(ytdPeriod.amount)}
          </Typography>

          <Typography>
            {t('AddedValuePerMonthBarAmount')}: {formatAmount(ytdPeriod.bar)}
          </Typography>

          <Typography>
            {t('AddedValuePerMonthHours')}: {formatHours(ytdPeriod.hours)}
          </Typography>

          <Box>
            <Typography>
              {t('AverageHourlyRate')} ({t('BarText')}):
            </Typography>
            <Typography>{renderHourlyRate(ytdPeriod.bar, ytdPeriod.hours)}</Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
