import {
  Description,
  AssignmentTurnedIn,
  PlayArrow,
  Pause,
  Done,
  Archive,
  Close,
} from '@mui/icons-material';

export const statusValueOptions = [
  { label: 'StatusQuoteText', value: 0, icon: Description },
  { label: 'StatusApprovedText', value: 1, icon: AssignmentTurnedIn },
  { label: 'StatusInProgressText', value: 2, icon: PlayArrow },
  { label: 'StatusOnHoldText', value: 3, icon: Pause },
  { label: 'StatusCompletedText', value: 4, icon: Done },
  { label: 'StatusArchivedText', value: 5, icon: Archive },
  { label: 'StatusCancelledText', value: 6, icon: Close },
];
