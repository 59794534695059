export default {
  Properties: {
    Count: 42,
  },
  Links: [
    {
      Href: 'https://uat.timelog.com/rp-test1/api/v2/resource-planner/tlp-all-project-count',
      Rel: 'self',
    },
  ],
};
