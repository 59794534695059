import { Dispatch, ReactNode, RefObject, SetStateAction } from 'react';
import { INewSavedView } from 'src/apis/savedViewAPI';
import { IFilterCategoryProperties } from 'src/apis/types/filterListAPI';
import { SidePanel } from 'src/components/layout/SidePanel';
import { initFilterStore } from 'src/reducers/FilterReducer';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { FilterStoreProvider } from 'src/stores/FilterStore';
import { TViewOptions } from 'src/apis/savedViewAPI/types';
import { FilterList } from './components/SideBar';
import { MainContainer } from './components/MainContainer';

interface IFilterPanelLayout {
  filterList?: Array<Partial<IFilterCategoryProperties>>;
  selectedFilterList?: Record<TFilterID, IFilterProps>;
  children: ReactNode;
  allowSavedViews?: boolean;
  savedViewsList?: INewSavedView[];
  changedViewOptions?: TViewOptions;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  preDefinedPeriod?: string;
  setViewOptions?: () => void;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

export const FilterPanelLayout = ({
  filterList = [],
  selectedFilterList = {},
  children,
  allowSavedViews = true,
  savedViewsList = [],
  changedViewOptions,
  activeView,
  setActiveView,
  preDefinedPeriod = undefined,
  setViewOptions,
  activateSidePanelButtonRef,
}: IFilterPanelLayout) => (
  <FilterStoreProvider
    value={{
      ...initFilterStore,
      filterList,
      selectedFilterList,
      filterQueryObj: selectedFilterList,
    }}
  >
    <SidePanel
      sideBar={<FilterList activateSidePanelButtonRef={activateSidePanelButtonRef} />}
      activateSidePanelButtonRef={activateSidePanelButtonRef}
    >
      <MainContainer
        allowSavedViews={allowSavedViews}
        savedViewsList={savedViewsList}
        changedViewOptions={changedViewOptions}
        activeView={activeView}
        setActiveView={setActiveView}
        preDefinedPeriod={preDefinedPeriod}
        setViewOptions={setViewOptions}
      >
        {children}
      </MainContainer>
    </SidePanel>
  </FilterStoreProvider>
);
