import { useResourcePlannerStore } from 'src/stores/ResourcePlannerStore';
import { TFilterNames } from '../components/FilterItems/FilterItem';
import { ISearchFilter } from '../types';

export const useGetFlattenedFilterItems = (filterEnum: Partial<Record<TFilterNames, string>>) => {
  const { filterList } = useResourcePlannerStore();
  const flattenedFilterItems = filterList.reduce(
    (a, { filterItems }) => ({
      ...a,
      ...(filterItems ?? []).reduce(
        (b, c) => ({
          ...b,
          [c.name]: {
            contentUrl: c.contentUrl,
            filterName: c.name,
            id: c.id,
          },
        }),
        {},
      ),
    }),
    {} as Record<TFilterNames, ISearchFilter>,
  );

  return Object.keys(filterEnum)
    .reduce((a, b): ISearchFilter[] => {
      const filterName = b as keyof typeof filterEnum;
      return [...a, flattenedFilterItems[filterName]];
    }, [] as ISearchFilter[])
    .filter((i) => i);
};
