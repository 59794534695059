import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';
import { LicenseInfo } from '@mui/x-license';
import reportWebVitals from 'src/reportWebVitals';
import { isDev } from 'src/utils/env';
import { APP_URL, API_URL } from 'src/constants/cookies';
import { parseBooleanFromString } from 'src/utils/boolean';
import App from 'src/App';
import worker from 'src/mocks/browser';
import 'src/services/datadogLogs';
import 'src/services/realUserMonitoring';
import 'src/services/i18n';
import 'src/assets/styles/globals.scss';

const BYPASS_TLP = parseBooleanFromString(import.meta.env.VITE_BYPASS_TLP);
const ENABLE_MOCK = parseBooleanFromString(import.meta.env.VITE_ENABLE_MOCK);

if (isDev && BYPASS_TLP) {
  Cookies.set(APP_URL, 'http://localhost/tlp');
  Cookies.set(API_URL, 'http://mockapi');

  worker.start({ onUnhandledRequest: 'bypass' });
}

if (isDev && ENABLE_MOCK) {
  worker.start({
    onUnhandledRequest: 'bypass',
    // quiet: true,
    // Return the first registered service worker found with the name
    // of `mockServiceWorker`, disregarding all other parts of the URL
    findWorker: (scriptURL) => scriptURL.includes('mockServiceWorker'),
  });
}

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_PRO_LICENSE_KEY ?? '');

const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
