const getChildren = (expandId: string | null) => {
  switch (String(expandId)) {
    case '34395': // Accountor + Intern tid
      return [
        {
          StartsAt: '2024-02-13T00:00:00',
          EndsAt: '2030-01-01T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 26560.81,
          TotalEAC: 26560.81,
          NotPlanned: -26560.81,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -26560.81,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597646109507585',
          workItemSourceReferenceId: '2918121',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597646109507585',
          name: 'Grupptid',
          sourceReferenceId: '2918121',
          tenantId: '10003',
          parentId: '362597646021427201',
          type: 'Task',
          pathToParent: '/362597646021427201/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/Accountor + Intern tid /001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2024-01-01T00:00:00',
          EndsAt: '2030-01-01T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 4021.25,
          TotalEAC: 4021.25,
          NotPlanned: -4021.25,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -4021.25,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597646109507598',
          workItemSourceReferenceId: '2918130',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597646109507598',
          name: 'Byråtid',
          sourceReferenceId: '2918130',
          tenantId: '10003',
          parentId: '362597646021427201',
          type: 'Task',
          pathToParent: '/362597646021427201/',
          sortOrder: 14,
          level: 2,
          sortablePath: '/Accountor + Intern tid /014',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '2918121': // Grupptid
      return [
        {
          StartsAt: '2024-02-13T00:00:00',
          EndsAt: '2027-02-11T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 3360.86,
          TotalEAC: 3360.86,
          NotPlanned: -3360.86,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -3360.86,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597646109507587',
          workItemSourceReferenceId: '2918122',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597646109507587',
          name: 'Interna möten',
          sourceReferenceId: '2918122',
          tenantId: '10003',
          parentId: '362597646109507585',
          type: 'Task',
          pathToParent: '/362597646021427201/362597646109507585/',
          sortOrder: 3,
          level: 3,
          sortablePath: '/Accountor + Intern tid /001/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2024-02-13T00:00:00',
          EndsAt: '2027-02-11T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 1785.06,
          TotalEAC: 1785.06,
          NotPlanned: -1785.06,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -1785.06,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597646109507589',
          workItemSourceReferenceId: '2918124',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597646109507589',
          name: 'Utbildning',
          sourceReferenceId: '2918124',
          tenantId: '10003',
          parentId: '362597646109507585',
          type: 'Task',
          pathToParent: '/362597646021427201/362597646109507585/',
          sortOrder: 5,
          level: 3,
          sortablePath: '/Accountor + Intern tid /001/005',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2024-02-13T00:00:00',
          EndsAt: '2027-02-11T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 600.84,
          TotalEAC: 600.84,
          NotPlanned: -600.84,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -600.84,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597646109507590',
          workItemSourceReferenceId: '2918125',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597646109507590',
          name: '1:1 Samtal',
          sourceReferenceId: '2918125',
          tenantId: '10003',
          parentId: '362597646109507585',
          type: 'Task',
          pathToParent: '/362597646021427201/362597646109507585/',
          sortOrder: 6,
          level: 3,
          sortablePath: '/Accountor + Intern tid /001/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '2918122': // Interna möten
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024W44': {
              displayValue: 8,
              value: 8,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W45': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W46': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W47': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W48': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W49': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W50': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W51': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W52': {
              displayValue: 16,
              value: 16,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2025W1': {
              displayValue: 32,
              value: 32,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 16,
          TotalEAC: 16,
          NotPlanned: -16,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -16,
          total: {
            displayValue: 336,
            value: 336,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '362593722271006720',
          resourceSourceReferenceId: 907,
          resourceType: 'Employee',
          workItemId: '362597646109507587',
          workItemSourceReferenceId: '2918122',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '362593722271006720',
          name: 'FirstName_Admin907 LastName (Initials_Admin907)',
          role: '',
          isActive: false,
          hiredAt: '2021-11-29',
          tenantId: '10003',
          sourceReferenceId: 907,
          type: 'Employee',
        },
      ];

    case '2918124': // Utbildning
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024W44': {
              displayValue: 8,
              value: 8,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W45': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W46': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W47': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W48': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W49': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W50': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W51': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W52': {
              displayValue: 16,
              value: 16,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2025W1': {
              displayValue: 32,
              value: 32,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 8,
          TotalEAC: 8,
          NotPlanned: -8,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -8,
          total: {
            displayValue: 336,
            value: 336,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '362593722271006720',
          resourceSourceReferenceId: 907,
          resourceType: 'Employee',
          workItemId: '362597646109507589',
          workItemSourceReferenceId: '2918124',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '362593722271006720',
          name: 'FirstName_Admin907 LastName (Initials_Admin907)',
          role: '',
          isActive: false,
          hiredAt: '2021-11-29',
          tenantId: '10003',
          sourceReferenceId: 907,
          type: 'Employee',
        },
      ];

    case '2918125': // 1:1 Samtal
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024W44': {
              displayValue: 8,
              value: 8,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W45': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W46': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W47': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W48': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W49': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W50': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W51': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W52': {
              displayValue: 16,
              value: 16,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2025W1': {
              displayValue: 32,
              value: 32,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 336,
            value: 336,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '362593722271006720',
          resourceSourceReferenceId: 907,
          resourceType: 'Employee',
          workItemId: '362597646109507590',
          workItemSourceReferenceId: '2918125',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '362593722271006720',
          name: 'FirstName_Admin907 LastName (Initials_Admin907)',
          role: '',
          isActive: false,
          hiredAt: '2021-11-29',
          tenantId: '10003',
          sourceReferenceId: 907,
          type: 'Employee',
        },
      ];

    case '2918130': // Byråtid
      return [
        {
          StartsAt: '2027-02-12T00:00:00',
          EndsAt: '2028-02-12T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597646109507618',
          workItemSourceReferenceId: '2918151',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597646109507618',
          name: 'Fackligt arbete',
          sourceReferenceId: '2918151',
          tenantId: '10003',
          parentId: '362597646109507598',
          type: 'Task',
          pathToParent: '/362597646021427201/362597646109507598/',
          sortOrder: 34,
          level: 3,
          sortablePath: '/Accountor + Intern tid /014/034',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '2918151': // Fackligt arbete
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024W44': {
              displayValue: 8,
              value: 8,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W45': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W46': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W47': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W48': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W49': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W50': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W51': {
              displayValue: 40,
              value: 40,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024W52': {
              displayValue: 16,
              value: 16,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2025W1': {
              displayValue: 32,
              value: 32,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 336,
            value: 336,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '362593722271006720',
          resourceSourceReferenceId: 907,
          resourceType: 'Employee',
          workItemId: '362597646109507618',
          workItemSourceReferenceId: '2918151',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '362593722271006720',
          name: 'FirstName_Admin907 LastName (Initials_Admin907)',
          role: '',
          isActive: false,
          hiredAt: '2021-11-29',
          tenantId: '10003',
          sourceReferenceId: 907,
          type: 'Employee',
        },
      ];

    default:
      return [];
  }
};

export default (expandId: string | null) => ({
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10003',
      responseType: 'GroupByWorkItem',
      viewOptions: {
        'grouped-by': 'group-by-work-item',
        'reporting-types': 'availability',
        'period-types': 'week',
        'unit-types': 'hours',
        'period-starts-at': '2024-11-01',
        'period-ends-at': '2024-12-31',
      },
      periods: [
        {
          startsAt: '2024-10-28T00:00:00',
          endsAt: '2024-11-03T00:00:00',
          type: 'Week',
          identifier: '2024W44',
        },
        {
          startsAt: '2024-11-04T00:00:00',
          endsAt: '2024-11-10T00:00:00',
          type: 'Week',
          identifier: '2024W45',
        },
        {
          startsAt: '2024-11-11T00:00:00',
          endsAt: '2024-11-17T00:00:00',
          type: 'Week',
          identifier: '2024W46',
        },
        {
          startsAt: '2024-11-18T00:00:00',
          endsAt: '2024-11-24T00:00:00',
          type: 'Week',
          identifier: '2024W47',
        },
        {
          startsAt: '2024-11-25T00:00:00',
          endsAt: '2024-12-01T00:00:00',
          type: 'Week',
          identifier: '2024W48',
        },
        {
          startsAt: '2024-12-02T00:00:00',
          endsAt: '2024-12-08T00:00:00',
          type: 'Week',
          identifier: '2024W49',
        },
        {
          startsAt: '2024-12-09T00:00:00',
          endsAt: '2024-12-15T00:00:00',
          type: 'Week',
          identifier: '2024W50',
        },
        {
          startsAt: '2024-12-16T00:00:00',
          endsAt: '2024-12-22T00:00:00',
          type: 'Week',
          identifier: '2024W51',
        },
        {
          startsAt: '2024-12-23T00:00:00',
          endsAt: '2024-12-29T00:00:00',
          type: 'Week',
          identifier: '2024W52',
        },
        {
          startsAt: '2024-12-30T00:00:00',
          endsAt: '2025-01-05T00:00:00',
          type: 'Week',
          identifier: '2025W1',
        },
      ],
      children: getChildren(expandId),
    },
    links: [
      {
        href: 'http://uat3.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyworkitem?periodstartsat=2024-11-01&periodendsat=2024-12-31&periodtypes=week&unittypes=hours&reportingtypes=availability&requesthavefilters=true&requesthaveemployeefilters=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 79394058,
  StatusCode: 200,
});
