import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

type TTooltipStatus = 'info' | 'error' | 'success' | 'warning';

interface IStyledTooltip extends TooltipProps {
  status: TTooltipStatus;
}

export const StyledTooltip = styled(({ className, ...props }: IStyledTooltip) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ...props }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette[props.status].main}`,
    boxShadow: theme.shadows[1],
    color: theme.palette[props.status].main,
    fontSize: 11,
    maxWidth: 150,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette[props.status].main}`,
  },
}));
