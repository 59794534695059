import { createContext, useContext, useEffect, useReducer } from 'react';
import FilterReducer, {
  IFilterStoreProps,
  TFilterActionProps,
  initFilterStore,
} from 'src/reducers/FilterReducer';

interface IFilterPanelStoreProps {
  children: JSX.Element | JSX.Element[];
  value?: IFilterStoreProps;
}
const FilterStoreContext = createContext<IFilterStoreProps>(initFilterStore);
const FilterDispatchContext = createContext<React.Dispatch<TFilterActionProps> | undefined>(
  undefined,
);
export const useFilterDispatch = () => useContext(FilterDispatchContext);
export const useFilterStore = () => useContext(FilterStoreContext);
export const useGetFilterValuesById = (fieldLabel: string) => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  return selectedFilterList?.[fieldLabel]?.values;
};
export const useFullFilterList = () => {
  const context = useContext(FilterStoreContext);
  if (!context) {
    throw new Error('useFullFilterList must be used within the FilterStoreContext');
  }
  return context.filterList;
};
export const useMatchSelectedFilterKey = (childFilterList: Array<string>) => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  return Object.keys(selectedFilterList).filter((key) => childFilterList.includes(key));
};
export const useGetParentQueryString = (parentFilterList: Array<string>) => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  const queryValue = parentFilterList
    .map((key) => {
      if (selectedFilterList[key]?.values?.length > 0) {
        return `${key}Ids=${selectedFilterList[key]?.values
          ?.map(({ value }) => value)
          .join(',')}&is${key}Inclusive=${selectedFilterList[key]?.isInclude ? 'true' : 'false'}`;
      }
      return undefined;
    })
    .filter((value) => !!value)
    .join('&');
  return queryValue ? `&${queryValue}` : '';
};
export const useFakeSelectedFilterResult = (noRequired = 3) => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  const selectedItemCount = Object.keys(selectedFilterList);

  return {
    isLoading: selectedItemCount.length <= noRequired && selectedItemCount.length > 0,
    isError: false,
    isEmpty: selectedItemCount.length <= 0,
  };
};
export const useSelectedFilterListInArray = () => {
  const { selectedFilterList } = useContext(FilterStoreContext);
  return Object.keys(selectedFilterList).map((key) => ({
    id: key,
    items: selectedFilterList[key].values.map(({ label }) => ({
      label: label ?? '',
    })),
    ...selectedFilterList[key],
  }));
};
export const useActivePanelID = () => {
  const { activePanelID } = useContext(FilterStoreContext);
  return activePanelID;
};
export const useActiveContainerID = () => {
  const { activeContainerID } = useContext(FilterStoreContext);
  return activeContainerID;
};

export default ({ children, value = initFilterStore }: IFilterPanelStoreProps) => {
  const [filterPanelState, filterPanelDispatch] = useReducer(FilterReducer, value);

  useEffect(() => {
    if (JSON.stringify(filterPanelState?.filterList) !== JSON.stringify(value?.filterList)) {
      filterPanelDispatch({ type: 'UPDATE_FILTER_LIST', payload: value });
    }
  }, [value, filterPanelState]);

  return (
    <FilterStoreContext.Provider value={filterPanelState}>
      <FilterDispatchContext.Provider value={filterPanelDispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterStoreContext.Provider>
  );
};
