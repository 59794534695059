import { useMutation, useQueryClient } from '@tanstack/react-query';
import { putFetch } from 'src/utils/fetcher';
import { SEARCH_PROJECTS_KEY } from '../get/getProjectsAPI';

const PROJECTS_URL_PATH = '/api/v2/projects';
export const PUT_PROJECT_MSW_STATUS_KEY = 'PutProjectAPI';

interface Base {
  [key: string]: number | Date | boolean | string | undefined;
}

// Extend the base type with specific properties
interface UpdateModel extends Base {
  validDate?: Date;
}

interface PutProjectVariables {
  updateModel: UpdateModel;
  fieldName: string;
  projectsId: string[];
}

// Able to pass in /status, /stage, /resource-planner in body
const putProjects = ({ updateModel, fieldName, projectsId }: PutProjectVariables) =>
  putFetch({
    path: `${PROJECTS_URL_PATH}/${fieldName}?projectsIds=${projectsId
      .map((project) => project)
      .join(',')}`,
    key: PUT_PROJECT_MSW_STATUS_KEY,
    body: JSON.stringify(updateModel),
  });

export const usePutProjects = () => {
  const queryClient = useQueryClient();

  return useMutation(putProjects, {
    onSuccess: () => queryClient.invalidateQueries([SEARCH_PROJECTS_KEY]),
  });
};
