import {
  AutocompleteCloseReason,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Rating,
} from '@mui/material';
import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import elements from 'src/assets/styles/variables/colors/global/elements.module.scss';
import pinks from 'src/assets/styles/variables/colors/themes/pink.module.scss';
import { Autocomplete, CircularProgress, Typography } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useKeyboardControl } from 'src/screens/ResourcePlanner/components/AssignFlow/hooks';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { useDebounce } from 'use-debounce';
import { IFilterCompetence } from './CompetenceDialog.types';

interface ICompetenceDialog {
  competence: IFilterCompetence[];
  setCompetence: Dispatch<SetStateAction<IFilterCompetence[]>>;
  onClose: () => void;
}

export const CompetenceDialog = ({ competence, setCompetence, onClose }: ICompetenceDialog) => {
  const { t } = useTranslation('assignFlow');
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);

  const filterId = 'competence';
  const contentUrl = `api/v2/filters/${filterId}/content`;
  const pageIdentifier = useGetCurrentPageIdentifier();
  const {
    dropdownList: d,
    isError,
    isFetching,
  } = useGetDropdownWithSearchAPI(
    {
      key: `filterInput${filterId}`,
      MSWKey: `FILTER_INPUT_${filterId}`,
      path: `/${contentUrl}?pageIdentifier=${pageIdentifier}`,
      searchText: debouncedQuery,
    },
    true,
  );

  const dropdownList = d.map((item) => ({
    ...item,
    levels: [],
  }));

  const competenceOnClick = (item: IFilterCompetence) => {
    setCompetence((prev) => {
      if (prev.find((c) => c.value === item.value)) {
        return prev.filter((c) => c.value !== item.value);
      }
      return [...prev, { ...item, levels: [1, 2, 3, 4, 5] }];
    });
  };

  const competenceLevelOnClick = (item: IFilterCompetence, level: number | null) => {
    setCompetence((prev) => {
      const competenceList = [...prev];
      const targetIndex = prev.findIndex((c) => c.value === item.value);
      const levels = level
        ? Array.from({ length: 5 - level + 1 }, (_, i) => i + level)
        : [1, 2, 3, 4, 5];
      competenceList[targetIndex] = { ...competenceList[targetIndex], levels };
      return [...competenceList];
    });
  };

  const getCompetenceIsChecked = (item: IFilterCompetence) =>
    competence.find((c) => c.value === item.value) !== undefined;

  const getCompetenceLevel = (item: IFilterCompetence) => {
    const selected = competence.find((c) => c.value === item.value);
    return selected?.levels[0] ?? 0;
  };

  const {
    searchContainerResults,
    selectItemIndex: selectedItemIndex,
    textFieldOnKeyDown,
  } = useKeyboardControl({});

  const autocompleteOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    textFieldOnKeyDown(e);
    if (query && (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Enter')) {
      setQuery('');
    }
    if (e.ctrlKey && (e.key === 'ArrowLeft' || e.key === 'ArrowRight')) {
      const highlightedItem = dropdownList[selectedItemIndex];
      const highlightedItemIsSelected = competence.find((c) => c.value === highlightedItem?.value);
      const competenceLevel = getCompetenceLevel(highlightedItem);
      if (e.key === 'ArrowLeft') {
        // Deselect competence if item is selected without competence level
        if (highlightedItemIsSelected && competenceLevel === 1) {
          competenceOnClick(highlightedItem);
          return;
        }
        // Reduce competence level if competence is selected with a level
        competenceLevelOnClick(highlightedItem, competenceLevel - 1);
        return;
      }
      // For ArrowRight
      // Select competence if it is not selected
      if (!highlightedItemIsSelected) {
        competenceOnClick({ ...highlightedItem, levels: [] });
        return;
      }
      // Stop increasing competence level at level 5
      if (competenceLevel === 5) {
        return;
      }
      competenceLevelOnClick(highlightedItem, competenceLevel + 1);
    }
  };

  useEffect(() => {
    setCompetence(competence);
  }, [competence, setCompetence]);

  return (
    <>
      <Box p={2} pb={0}>
        <Autocomplete
          componentsProps={{
            popper: { style: { display: 'none' } },
          }}
          data-automation-id="SearchCompetence"
          disablePortal
          getOptionLabel={(o) => {
            const { label, levels } = o as IFilterCompetence;
            if (!levels.length) {
              return label;
            }
            if (levels.length === 5) {
              return `${label} (${t('FilterItems.Any')})`;
            }
            return `${label} (${levels.toString()})`;
          }}
          inputProps={{ autoFocus: true }}
          inputOnChange={setQuery}
          inputValue={query}
          label={t('FilterItems.SearchInAllCompetences')}
          multiple
          onChange={(_, v) => setCompetence(v as IFilterCompetence[])}
          onClose={(_, reason: AutocompleteCloseReason) => {
            if (reason === 'escape') {
              onClose();
            }
          }}
          onKeyDown={autocompleteOnKeyDown}
          open
          options={[]}
          size="small"
          value={competence}
        />
      </Box>
      <ResponseHandler
        EmptyComponent={<Typography textAlign="center">{t('NoResults')}</Typography>}
        isLoading={isFetching}
        isError={isError}
        isEmpty={!dropdownList.length}
        LoadingComponent={<CircularProgress sx={{ my: 10 }} />}
      >
        <List dense ref={searchContainerResults} sx={{ maxHeight: '40vh', overflow: 'auto', p: 2 }}>
          {dropdownList.map((c) => (
            <ListItem
              data-automation-id={`CompetenceItem-${c.value}`}
              disablePadding
              key={c.value}
              sx={{
                backgroundColor: getCompetenceIsChecked(c) ? pinks.themeColorPinkLighter : '',
                position: 'relative',
              }}
            >
              <ListItemButton
                onClick={() => competenceOnClick(c)}
                sx={{
                  '&[aria-selected="true"]': { backgroundColor: elements.colorSelected },
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={getCompetenceIsChecked(c)}
                    disableRipple
                    edge="start"
                    inputProps={{ 'aria-labelledby': c.value }}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText
                  id={c.value}
                  primary={<HighlightMatchingText name={c.label} matchName={debouncedQuery} />}
                  sx={{ mr: 17 }}
                />
              </ListItemButton>
              {getCompetenceIsChecked(c) ? (
                <Rating
                  data-automation-id={`CompetenceItemRating-${c.value}`}
                  onChange={(_, v) => competenceLevelOnClick(c, v)}
                  sx={{
                    insetBlockStart: '50%',
                    insetInlineEnd: 16,
                    position: 'absolute',
                    transform: 'translateY(-50%)',
                  }}
                  value={getCompetenceLevel(c)}
                />
              ) : null}
            </ListItem>
          ))}
        </List>
      </ResponseHandler>
    </>
  );
};
