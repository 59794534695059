import { Stack } from '@mui/material';
import { Typography } from 'src/components/mui-components';
import { Trans, useTranslation } from 'react-i18next';
import { useIsValidIndexControlName } from 'src/apis/indexAPI';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { TextLink } from 'src/components/ui-components';
import { useGetCompetences } from 'src/apis/competencesAPI';

export const NoCompetencesAdded = ({ availableCompetences }: { availableCompetences: number }) => {
  const { t } = useTranslation('employeeCompetences');
  const { rows } = useGetCompetences();

  const { isError, isValidIndexControlName: hasAccessToAddCompetences } =
    useIsValidIndexControlName('Competences', 'CreateCompetencesV2');

  const noCompetencesAddedInSystem = rows.length <= 1; // this is because the first row is the category
  const doesNotHaveAccessToAddCompetencesAndNoCompetences =
    !hasAccessToAddCompetences && noCompetencesAddedInSystem;
  const hasAccessToAddCompetencesAndNoCompetences =
    hasAccessToAddCompetences && noCompetencesAddedInSystem;
  const employeeHasAllCompetences = !noCompetencesAddedInSystem && availableCompetences === 0;

  return (
    <Stack gap={2}>
      <ResponseHandler isError={isError}>
        <>
          {hasAccessToAddCompetencesAndNoCompetences ||
          doesNotHaveAccessToAddCompetencesAndNoCompetences ? (
            <Typography>{t('modals.AddCompetenceNoDataText')}</Typography>
          ) : null}
          {hasAccessToAddCompetencesAndNoCompetences ? (
            <Trans
              i18nKey="modals.AddCompetenceNoDataCanCreateText"
              ns="employeeCompetences"
              components={[
                <TextLink
                  href="/system-administration/employees/competences"
                  target="_blank"
                  iconName="newWindow"
                />,
              ]}
            />
          ) : null}
          {doesNotHaveAccessToAddCompetencesAndNoCompetences ? (
            <Typography>{t('modals.AddCompetenceNoDataCanNotCreateText')}</Typography>
          ) : null}
          {employeeHasAllCompetences ? (
            <Typography>{t('modals.AddCompetenceAllCompetencesAddedText')}</Typography>
          ) : null}
        </>
      </ResponseHandler>
    </Stack>
  );
};
