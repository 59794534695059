import { TFunction } from 'i18next';
import { translationAnyText } from 'src/utils/translation';
import camelToPascal from './camelToPascal';

export const getTranslatedText = (
  t: TFunction<'resourcePlanner', undefined>,
  unitType: string,
  identifier: string,
  view: 'ProjectView' | 'EmployeeView',
) => {
  if (identifier === 'budget') {
    return translationAnyText(
      t,
      `ColumnLabel${camelToPascal(identifier)}${unitType === 'days' ? 'Days' : 'Hours'}${view}`,
    );
  }
  if (
    identifier === 'totalBooked' ||
    identifier === 'totalActualWorkload' ||
    identifier === 'notPlanned'
  ) {
    return translationAnyText(
      t,
      `ColumnLabel${camelToPascal(identifier)}${unitType === 'days' ? 'Days' : 'Hours'}`,
    );
  }
  return translationAnyText(t, `ColumnLabel${camelToPascal(identifier)}`);
};
