export default {
  Model: {
    class: 'countresponse',
    properties: {
      tenantId: '10007',
      count: 6,
    },
    links: [
      {
        href: 'http://test1.resourceplanner2.aks.timelog.com:80/resource/getactiveemployeecount',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: -2098291289,
  StatusCode: 200,
};
