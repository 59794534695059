import { memo, ReactNode } from 'react';
import { THorizontalAlignment } from 'src/assets/variables/alignment';
import { Table, TCellText } from 'src/components/ui-components/Table';

/**
 * These props are used in `memo()`'s `arePropsEqual` comparison
 */
interface IMemoArePropsEqual {
  // eslint-disable-next-line react/no-unused-prop-types
  cellValue?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  endsAt?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  startsAt?: string;
}

interface IMemoizedRenderBodyCellColumn extends IMemoArePropsEqual {
  children: ReactNode;
  alignment?: THorizontalAlignment;
  dividerBorderLeft?: boolean;
  editable?: boolean;
  fontColor?: string;
  type?: TCellText;
}

export const MemoizedRenderBodyCellColumn = memo(
  ({
    alignment,
    children,
    dividerBorderLeft,
    editable,
    fontColor,
    type,
  }: IMemoizedRenderBodyCellColumn) => (
    <Table.Cell
      alignment={alignment}
      cellFontColor={fontColor}
      dividerBorderLeft={dividerBorderLeft}
      hasOverflow={editable}
      type={type}
    >
      {children}
    </Table.Cell>
  ),
  (prevProp, nextProp) => {
    const isSameCellValue = prevProp.cellValue === nextProp.cellValue;
    const isSameStartsAt = prevProp.startsAt === nextProp.startsAt;
    const isSameEndsAt = prevProp.endsAt === nextProp.endsAt;

    return isSameCellValue && isSameStartsAt && isSameEndsAt;
  },
);
