import { useInfiniteQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { IMilestones, IMilestonesEntities } from 'src/apis/types/milestonesAPI';

const MILESTONES_URL_PATH = '/api/v2/milestones/incomplete';
export const MILESTONES_MSW_STATUS_KEY = 'milestonesAPI';

export const MILESTONES_KEY = 'MILESTONES_KEY';

const getMilestones = (
  dueDate?: string,
  pageSize: string = '3',
  page: string = '1',
): Promise<IMilestones> =>
  getFetch({
    path: `${MILESTONES_URL_PATH}${concatQuerys([
      { param: '$pagesize', value: pageSize },
      { param: '$page', value: page },
      { param: 'dueDate', value: dueDate },
    ])}`,
    key: MILESTONES_MSW_STATUS_KEY,
  });

export const useGetMilestones = (dueDate: string, pageSize?: string) => {
  const { data, ...restProps } = useInfiniteQuery(
    [MILESTONES_KEY, { dueDate, pageSize }],
    ({ pageParam }) => getMilestones(dueDate, pageSize, pageParam),
    {
      getNextPageParam: (lastPage) => {
        if (
          lastPage.properties &&
          parseInt(lastPage.properties.pageNumber.toString(), 10) <
            parseInt(lastPage.properties?.totalPage.toString(), 10)
        ) {
          return (
            lastPage.properties &&
            parseInt(lastPage.properties.pageNumber as unknown as string, 10) + 1
          );
        }
        return null;
      },
    },
  );

  const milestones =
    data?.pages
      .map((pageItem) =>
        pageItem.entities?.map(
          ({ properties: { dueDate: mileStoneDueDate, ...restProperties } = {} }) => ({
            isOverdue:
              mileStoneDueDate &&
              DateTime.fromISO(mileStoneDueDate).startOf('day') <= DateTime.now().startOf('day'),
            dueDate: mileStoneDueDate,
            ...restProperties,
          }),
        ),
      )
      .flat(2)
      .filter((milestone): milestone is IMilestonesEntities => typeof milestone !== undefined) ||
    [];

  return {
    milestones,
    ...restProps,
  };
};
