export default {
  Properties: {
    CurrentUserId: 1,
    IsApprovalEnabled: true,
    IsApprovalOfTimeSheetEnabled: true,
    IsApprovalOfExpensesEnabled: true,
    IsManagerForActiveEmployees: true,
    IsApprovalOfTimesheetByProjectManagerOnlyEnabled: false,
    NotificationCenterApprovalItems: [
      {
        ApprovalStatus: 0,
        EmployeeUserId: 5,
        EmployeeInfo: 'Anders Pedersen',
        ProjectName: null,
        CustomerDescription: null,
        Hours: 0.0,
        NormalWorkingTimeHoursInPeriod: 37.0,
        TimeTrackingFormat: 0,
        StartDate: '2023-03-13T00:00:00+01:00',
        EndDate: '2023-03-19T00:00:00+01:00',
        NavigationItemType: 0,
        ProjectId: 0,
        ApprovalManagerUserID: 1,
        ApprovalForUserUrl:
          'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=5&startDate=2023-03-13&endDate=2023-03-19&approvalNavigationItemType=0',
      },
      {
        ApprovalStatus: 0,
        EmployeeUserId: 6,
        EmployeeInfo: 'Poul Scholes',
        ProjectName: null,
        CustomerDescription: null,
        Hours: 1.33,
        NormalWorkingTimeHoursInPeriod: 37.0,
        TimeTrackingFormat: 0,
        StartDate: '2023-03-13T00:00:00+01:00',
        EndDate: '2023-03-19T00:00:00+01:00',
        NavigationItemType: 0,
        ProjectId: 0,
        ApprovalManagerUserID: 1,
        ApprovalForUserUrl:
          'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=6&startDate=2023-03-13&endDate=2023-03-19&approvalNavigationItemType=0',
      },
      {
        ApprovalStatus: 0,
        EmployeeUserId: 1,
        EmployeeInfo: 'Peter Summersens',
        ProjectName: null,
        CustomerDescription: null,
        Hours: 0.0,
        NormalWorkingTimeHoursInPeriod: 37.0,
        TimeTrackingFormat: 0,
        StartDate: '2023-03-13T00:00:00+01:00',
        EndDate: '2023-03-19T00:00:00+01:00',
        NavigationItemType: 0,
        ProjectId: 0,
        ApprovalManagerUserID: 1,
        ApprovalForUserUrl:
          'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=1&startDate=2023-03-13&endDate=2023-03-19&approvalNavigationItemType=0',
      },
      {
        ApprovalStatus: 0,
        EmployeeUserId: 1,
        EmployeeInfo: 'Peter Summersens',
        ProjectName: null,
        CustomerDescription: null,
        Hours: 1.0,
        NormalWorkingTimeHoursInPeriod: 0.0,
        TimeTrackingFormat: 1,
        StartDate: '2022-10-25T00:00:00',
        EndDate: '2022-10-25T00:00:00',
        NavigationItemType: 1,
        ProjectId: 0,
        ApprovalManagerUserID: 1,
        ApprovalForUserUrl:
          'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=1&startDate=2023-03-13&endDate=2023-03-19&approvalNavigationItemType=1',
      },
      {
        ApprovalStatus: 0,
        EmployeeUserId: 1,
        EmployeeInfo: 'Peter Summersens',
        ProjectName: null,
        CustomerDescription: null,
        Hours: 7.0,
        NormalWorkingTimeHoursInPeriod: 0.0,
        TimeTrackingFormat: 1,
        StartDate: '2023-04-03T00:00:00',
        EndDate: '2023-04-14T00:00:00',
        NavigationItemType: 1,
        ProjectId: 0,
        ApprovalManagerUserID: 1,
        ApprovalForUserUrl:
          'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=1&startDate=2023-03-13&endDate=2023-03-19&approvalNavigationItemType=1',
      },
      {
        ApprovalStatus: 0,
        EmployeeUserId: 1,
        EmployeeInfo: 'Peter Summersens',
        ProjectName: null,
        CustomerDescription: null,
        Hours: 1.5,
        NormalWorkingTimeHoursInPeriod: 0.0,
        TimeTrackingFormat: 2,
        StartDate: '2023-04-24T00:00:00',
        EndDate: '2023-04-26T00:00:00',
        NavigationItemType: 1,
        ProjectId: 0,
        ApprovalManagerUserID: 1,
        ApprovalForUserUrl:
          'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=1&startDate=2023-03-13&endDate=2023-03-19&approvalNavigationItemType=1',
      },
      {
        ApprovalStatus: 0,
        EmployeeUserId: 5,
        EmployeeInfo: 'Anders Pedersen',
        ProjectName: null,
        CustomerDescription: null,
        Hours: 1.0,
        NormalWorkingTimeHoursInPeriod: 0.0,
        TimeTrackingFormat: 1,
        StartDate: '2023-02-01T00:00:00',
        EndDate: '2023-02-01T00:00:00',
        NavigationItemType: 1,
        ProjectId: 0,
        ApprovalManagerUserID: 1,
        ApprovalForUserUrl:
          'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=5&startDate=2023-03-13&endDate=2023-03-19&approvalNavigationItemType=1',
      },
    ],
    NotificationCenterTimesheetItems: [
      {
        EmployeeUserId: 1,
        EmployeeFullName: 'Peter Summersens',
        EmployeeInitials: 'psu',
        ApprovalManagerUserID: 1,
        WeeklyTimesheetDetails: [
          {
            Number: 17,
            TimesheetStatus: 1,
            StartDate: '2023-04-24T00:00:00',
            EndDate: '2023-04-30T00:00:00',
            SubmitTimeSheetUrl: 'http://localhost/tlp/Reports/Employee/StatusForTimeReports',
            Hours: 11.100000000000001,
            NormalWorkingTime: 37,
          },
        ],
      },
      {
        EmployeeUserId: 5,
        EmployeeFullName: 'Anders Pedersen',
        EmployeeInitials: 'apd',
        ApprovalManagerUserID: 1,
        WeeklyTimesheetDetails: [
          {
            Number: 17,
            TimesheetStatus: 0,
            StartDate: '2023-04-24T00:00:00',
            EndDate: '2023-04-30T00:00:00',
            SubmitTimeSheetUrl: 'http://localhost/tlp/Reports/Employee/StatusForTimeReports',
            Hours: 0,
            NormalWorkingTime: 37,
          },
        ],
      },
      {
        EmployeeUserId: 6,
        EmployeeFullName: 'Paul Scholes',
        EmployeeInitials: 'psc',
        ApprovalManagerUserID: 1,
        WeeklyTimesheetDetails: [
          {
            Number: 17,
            TimesheetStatus: 0,
            StartDate: '2023-04-24T00:00:00',
            EndDate: '2023-04-30T00:00:00',
            SubmitTimeSheetUrl: 'http://localhost/tlp/Reports/Employee/StatusForTimeReports',
            Hours: 0,
            NormalWorkingTime: 37,
          },
          {
            Number: 18,
            TimesheetStatus: 0,
            StartDate: '2023-05-01T00:00:00',
            EndDate: '2023-05-07T00:00:00',
            SubmitTimeSheetUrl: 'http://localhost/tlp/Reports/Employee/StatusForTimeReports',
            Hours: 0,
            NormalWorkingTime: 37,
          },
        ],
      },
    ],
    NumberOfExpensesPendingApproval: 3,
    NumberOfMileagePendingApproval: 1,
    CurrentUserHasUnsubmittedTimeRegistrationsLastWeek: false,
    CloseTimeSheetReportUrl: 'http://localhost/tlp/Reports/Employee/StatusForTimeReports',
    ApprovalUrl:
      'http://localhost/tlp/Employee/Approval?approvalManagerUserID=1&userID=-1&startDate=2023-03-13&endDate=2023-03-19',
    ApproveExpensesUrl: 'http://localhost/tlp/Organization/Employee/ApproveExpenses',
    TimeSheetUrl:
      'http://localhost/tlp/Registration/TimeTracking?startDate=2023-03-13&endDate=2023-03-19',
  },
  Links: [{ Href: 'http://localhost/tlp/api/v2/notification-center', Rel: 'self' }],
};
