import { Delete } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProjectActions } from 'src/apis/projectsAPI';
import { IActions } from 'src/apis/projectsAPI/types';
import { ReportingToolbar } from 'src/components/global/ReportingTable';
import { IconButton } from 'src/components/mui-components';
import { ProjectActionDialog } from '../ProjectActionDialog';
import { ProjectRemoveRPDialog } from '../ProjectRemoveRPDialog';
import { useExcelConfig } from './config';

interface IToolbarProps {
  selectedRowProject: string[];
  setColumnsButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  filterSection?: ReactNode;
  applyProjectsUpdate: (uri?: string, validDate?: Date, action?: any) => void;
  columns: GridColDef[];
}

export const Toolbar = ({
  selectedRowProject,
  columns,
  setColumnsButtonEl,
  filterSection,
  applyProjectsUpdate,
}: IToolbarProps) => {
  const { t } = useTranslation('searchProjects');
  const excelConfig = useExcelConfig();

  const [status, setStatus] = useState('');
  const [state, setState] = useState('');

  // Project remove resource planner dialog
  const [showRemoveResourcePlannerDialog, setShowRemoveResourcePlannerDialog] = useState(false);

  const [selectedAction, setSelectedAction] = useState<IActions>({
    name: '',
    uri: '',
  });

  // Project status or stage update Dialog
  const [showActionUpdate, setShowActionUpdate] = useState(false);

  const { actions } = useGetProjectActions();

  const statusActions = actions.find((item) => item.name === 'ChangeStatusTo')?.actions || [];
  const stageActions = actions.find((item) => item.name === 'ChangeStageTo')?.actions || [];

  useEffect(() => {
    setStatus('');
    setState('');
  }, [selectedRowProject]);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedActionBody = statusActions.find((action) => action.name === event.target.value);
    if (selectedActionBody) {
      setStatus(event.target.value);
      setShowActionUpdate(true);
      setSelectedAction({
        name: selectedActionBody.name,
        uri: 'status',
        body: selectedActionBody?.body,
      });
    }
  };

  const handleStageChange = (event: SelectChangeEvent) => {
    const selectedActionBody = stageActions.find((action) => action.name === event.target.value);
    if (selectedActionBody) {
      setState(event.target.value);
      setShowActionUpdate(true);
      setSelectedAction({
        name: selectedActionBody.name,
        uri: 'stage',
        body: selectedActionBody?.body,
      });
    }
  };

  return (
    <>
      <ReportingToolbar
        selectedRowProject={selectedRowProject}
        setColumnsButtonEl={setColumnsButtonEl}
        filterSection={filterSection}
        toolbarContent={
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} sx={{ flexWrap: 'wrap' }}>
            {statusActions && (
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="SelectStatus-label">{t('ChangeStatusToText')}</InputLabel>
                <Select
                  labelId="SelectStatus-label"
                  name="status"
                  value={status}
                  label={t('ChangeStatusToText')}
                  onChange={handleChange}
                  sx={{ backgroundColor: 'white' }}
                  data-automation-id="ProjectStatusSelect"
                >
                  {statusActions.map((action) => (
                    <MenuItem key={action.name} value={action.name}>
                      {t(`Status${action.name}Text`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl sx={{ minWidth: 200 }} size="small">
              <InputLabel id="SelectStage">{t('ChangeStageToText')}</InputLabel>
              <Select
                labelId="SelectStage"
                value={state}
                label={t('ChangeStageToText')}
                onChange={handleStageChange}
                sx={{ backgroundColor: 'white' }}
                data-automation-id="ProjectStageSelect"
              >
                {stageActions.map((action) => (
                  <MenuItem key={action.name} value={action.name}>
                    {action.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              title={t('DeleteProjectText')}
              data-automation-id="ProjectDeleteTriggerButton"
              onClick={(event) => {
                event.preventDefault();
                applyProjectsUpdate('delete');
              }}
              sx={{ display: 'flex' }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Stack>
        }
        // columns and excelConfig are required for export
        columns={columns}
        excelConfig={excelConfig}
      />
      {/* Project remove from resource planner Dialog */}
      <ProjectRemoveRPDialog
        showRemoveResourcePlannerDialog={showRemoveResourcePlannerDialog}
        setShowRemoveResourcePlannerDialog={setShowRemoveResourcePlannerDialog}
        applyProjectsUpdate={applyProjectsUpdate}
      />
      {/* Project status or stage Dialog when click Apply */}
      <ProjectActionDialog
        showActionUpdate={showActionUpdate}
        setShowActionUpdate={setShowActionUpdate}
        applyProjectsUpdate={applyProjectsUpdate}
        selectedAction={selectedAction}
      />
    </>
  );
};
