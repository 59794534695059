import { Box, MenuItem, Select } from '@mui/material';
import { TFunction } from 'i18next';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetFrontPageFeature, usePutFeature } from 'src/apis/featureAPI';
import { useUpdateMilestoneDays } from 'src/apis/settingsAPI';
import { ToastifyAlert } from 'src/components/mui-components';
import { useIsPsaProduct } from 'src/utils/siteInfo';
import { SettingsContentWrapper } from 'src/components/layout/SettingsContentWrapper';
import { milestoneDropdownOptions } from 'src/constants/dropdown';
import { SwitchSection } from '../../components';
import { useFeatures } from '../../components/hook/useFeaturesHook';

interface IExtra {
  checkedFeatures: Record<string, any>;
  featureValues: Record<string, any>;
  onChange: (value: number) => void;
  setFeatureValues: (value: SetStateAction<Record<string, any>>) => void;
  tSysAdminFrontPage: TFunction<'systemAdminFrontPage', undefined>;
  tFrontPage: TFunction<'frontpage', undefined>;
}

const Extra = ({
  checkedFeatures,
  featureValues,
  onChange,
  setFeatureValues,
  tSysAdminFrontPage,
  tFrontPage,
}: IExtra) => (
  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
    {tSysAdminFrontPage('MilestonesMessage')}
    <Select
      aria-label="Milestone Days"
      data-automation-id="MilestonesDaysDropdown"
      id="Milestones"
      value={featureValues?.ShowMileStones !== undefined ? featureValues.ShowMileStones : ''}
      variant="outlined"
      size="small"
      disabled={!checkedFeatures?.ShowMileStones}
      onChange={(e) => {
        setFeatureValues({ ...featureValues, ShowMileStones: e.target.value });
        onChange(e.target.value);
      }}
      sx={{ ml: 1, mr: 1 }}
    >
      {Object.entries(milestoneDropdownOptions).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {tFrontPage(value)}
        </MenuItem>
      ))}
    </Select>
  </Box>
);

export const FrontPage = () => {
  const { data } = useGetFrontPageFeature();
  const { mutate: updateFeature } = usePutFeature();
  const { mutate: updateMilestoneDays } = useUpdateMilestoneDays();
  const isPsaProduct = useIsPsaProduct();

  const { t: tSysAdminFrontPage } = useTranslation('systemAdminFrontPage');
  const { t: tFrontPage } = useTranslation('frontpage');
  const { t: tNotification } = useTranslation('notificationCenter');

  const { checkedFeatures, setCheckedFeatures, featureValues, setFeatureValues } =
    useFeatures(data);

  const toggleCheckbox = async (name: string, isChecked: boolean, label: string) => {
    const feature = data.find((feat: { name: string }) => feat.name === name);

    if (!feature) return;

    const response = await updateFeature(
      {
        featureId: feature.identifier,
        featureEnable: isChecked,
      },
      {
        onSuccess: () => {
          const toastText = isChecked
            ? tSysAdminFrontPage('FeatureEnabled', {
                NAME: label,
              })
            : tSysAdminFrontPage('FeatureDisabled', {
                NAME: label,
              });
          toast.success(<ToastifyAlert description={toastText} />, {
            autoClose: 2000,
            closeButton: false,
          });
        },
      },
    );

    if (response !== undefined && response !== null) {
      setCheckedFeatures((prev) => ({ ...prev, [name]: isChecked }));
    }
  };

  const onChange = (value: number) => {
    updateMilestoneDays(value, {
      onSuccess: () => {
        toast.success(
          <ToastifyAlert description={tSysAdminFrontPage('MilestonesUpdateSuccessMessage')} />,
          {
            autoClose: 2000,
            closeButton: false,
          },
        );
      },
    });
  };

  const sections = [
    {
      title: tSysAdminFrontPage('SidebarSectionName'),
      items: [
        {
          name: 'FrontPageInternalMessage',
          label: tFrontPage('InternalInformationHeading'),
          tooltipTitle: tFrontPage('InternalInformationHeading'),
          tooltipDesc: tFrontPage('InternalInformationText'),
          isVisible: isPsaProduct,
        },
        {
          name: 'DesignSevenNotificationCenter',
          label: tNotification('Heading'),
          tooltipTitle: tNotification('InfoModalHeading'),
          tooltipDesc: tNotification('InfoModalText'),
          isVisible: isPsaProduct,
        },
        {
          name: 'ShowMileStones',
          label: tSysAdminFrontPage('MilestonesInInfoboxName'),
          tooltipTitle: tSysAdminFrontPage('MilestonesName'),
          tooltipDesc: tSysAdminFrontPage('MilestonesText'),
          extra: (
            <Extra
              checkedFeatures={checkedFeatures}
              featureValues={featureValues}
              onChange={onChange}
              setFeatureValues={setFeatureValues}
              tSysAdminFrontPage={tSysAdminFrontPage}
              tFrontPage={tFrontPage}
            />
          ),
          isVisible: true,
        },
      ],
    },
  ];

  return (
    <SettingsContentWrapper
      header={tSysAdminFrontPage('Heading')}
      description={tSysAdminFrontPage('Description')}
    >
      {sections.map((section) => {
        // Check if all feature names in the section are in checkedFeatures
        const allFeaturesExist = section.items.every((item) => item.name in checkedFeatures);
        return (
          allFeaturesExist && (
            <SwitchSection
              key={section.title}
              title={section.title}
              items={section.items}
              checkedItems={checkedFeatures}
              onToggle={toggleCheckbox}
              translation="systemAdminFrontPage"
            />
          )
        );
      })}
    </SettingsContentWrapper>
  );
};
