import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from 'src/components/mui-components';
import { TFormDialogMode, ISavedViewForm } from 'src/apis/savedViewAPI';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useRef } from 'react';
import { useInitialFocus } from 'src/hooks/useInitialFocus';

interface ISaveViewFormDialog {
  showViewFormDialog: TFormDialogMode;
  setShowViewFormDialog: (showViewFormDialog: TFormDialogMode | '') => void;
  onSubmit: (data: ISavedViewForm) => void;
  form: UseFormReturn<ISavedViewForm, any>;
}

export const SaveViewFormDialog = ({
  showViewFormDialog,
  setShowViewFormDialog,
  onSubmit,
  form,
}: ISaveViewFormDialog) => {
  const { t } = useTranslation('savedView');
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  // Initial focus
  const initialFocusElement = useRef<HTMLInputElement | null>(null);
  useInitialFocus(initialFocusElement);

  const resetForm = () => {
    form.setValue('title', '');
    form.setValue('isDefault', false);
  };

  const handleCancel = () => {
    resetForm();
    setShowViewFormDialog('');
  };

  const dialogTitle = (view: TFormDialogMode) => {
    switch (view) {
      case 'save':
        return t('SaveViewModalTitle');
      case 'edit':
        return t('UpdateViewModalTitle');
      default:
        return '';
    }
  };

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="dialog-view-form"
      fullWidth
      maxWidth="xs"
      open={!!showViewFormDialog}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="dialog-view-form">{dialogTitle(showViewFormDialog)}</DialogTitle>
        <DialogContent>
          <Stack gap={2} sx={{ mt: 2 }}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  inputRef={initialFocusElement}
                  data-automation-id="SavedViewTitle"
                  label={t('ViewNameText')}
                  variant="outlined"
                  ariaLabel={t('ViewNameText')}
                  required
                  autoFocus
                  error={!!errors.title?.message?.toString()}
                  {...field}
                />
              )}
            />
            <FormControlLabel
              control={
                <Controller
                  name="isDefault"
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={t('DialogIsDefaultText')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => handleCancel()}>
            {t('DialogCancelText')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid}
            onClick={handleSubmit(onSubmit)}
            autoFocus
          >
            {t('DialogSaveText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
