export default {
  Model: {
    class: 'migrationstatusresponse',
    properties: {
      tenantId: '10007',
      isDataSynchronizationEnabled: true,
      isResourceMigrated: true,
      isProjectMigrated: true,
      canTriggerMigration: true,
      canAddResourcePlan: true,
      isResourcePlanMigrated: true,
    },
    links: [
      {
        href: 'http://test1.resourceplanner2.aks.timelog.com:80/migration/getmigrationstatus?canaddresourceplan=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: -1359565704,
  StatusCode: 200,
};
