// getGroupByProjectAPI
export const GROUP_BY_PROJECT_KEY = 'GetGroupByProjectAPI';
export const PARTIAL_GROUP_BY_PROJECT_KEY = 'GetPartialGroupByProjectAPI';

// getGroupByResourceAPI
export const GROUP_BY_RESOURCE_KEY = 'GetGroupByResourceAPI';
export const PARTIAL_GROUP_BY_RESOURCE_KEY = 'GetPartialGroupByResourceAPI';

// getGroupByResourceAPI
export const GROUP_BY_RESOURCE_TOTAL_KEY = 'GROUP_BY_RESOURCE_TOTAL';

// getMigrationStatusAPI
export const MIGRATION_STATUS_KEY = 'MIGRATION_STATUS';

// get[X]CountAPI
export const REFETCH_INTERVAL = 2000;

// getRpEmployeeCountAPI
export const RP_EMPLOYEE_COUNT_KEY = 'RP_EMPLOYEE_COUNT';

// getRpPlanCountAPI
export const RP_PLAN_COUNT_KEY = 'RP_PLAN_COUNT';

// getRpProjectCountAPI
export const RP_PROJECT_COUNT_KEY = 'RP_PROJECT_COUNT';

// getTlpEmployeeCountAPI
export const TLP_EMPLOYEE_COUNT_KEY = 'TLP_EMPLOYEE_COUNT';

// getTlpPlanCountAPI
export const TLP_PLAN_COUNT_KEY = 'TLP_PLAN_COUNT';

// getTlpProjectCountAPI
export const TLP_PROJECT_COUNT_KEY = 'TLP_PROJECT_COUNT';
