import { useCallback, useEffect, useRef } from 'react';

export const useInfiniteScrolling = (fetchNextPage: () => void) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRef?: HTMLDivElement | null) => {
      if (containerRef) {
        const containerRect = containerRef.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const distanceToViewportBottom = viewportHeight - containerRect.bottom;
        // once the user has scrolled within 50px of the bottom of the table, fetch more data if we can
        if (distanceToViewportBottom > -50) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage],
  );

  useEffect(() => {
    const handleScroll = () => {
      fetchMoreOnBottomReached(tableContainerRef.current);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchMoreOnBottomReached]);

  // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return { tableContainerRef };
};
