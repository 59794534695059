import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { CheckCircle } from '@mui/icons-material';
import { Box, List, ListItem, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MIGRATION_STATUS_KEY,
  RP_EMPLOYEE_COUNT_KEY,
  RP_PROJECT_COUNT_KEY,
  TLP_EMPLOYEE_COUNT_KEY,
  TLP_PROJECT_COUNT_KEY,
  useGetRPSignalRConfig,
} from 'src/apis/resourcePlannerAPI';
import rpImport from 'src/assets/images/rp-import-wait.webp';
import { CircularProgress } from 'src/components/mui-components';
import { Deck } from 'src/components/ui-components';
import styles from './InitialSetup.module.scss';
import { useInitialSetup } from './useInitialSetup';

export const InitialSetup = () => {
  const { t } = useTranslation('resourcePlanner');
  const { tenantId, serviceUrl } = useGetRPSignalRConfig();
  const qc = useQueryClient();
  const {
    canAddResourcePlan,
    employee,
    isProjectMigrated,
    isResourceMigrated,
    isResourcePlanMigrated,
    project,
    resourcePlan,
  } = useInitialSetup();

  const stepTemplate = (condition: boolean, string: string, count: string) =>
    condition ? (
      <>
        <CheckCircle color="primary" />
        <strong>{string}</strong>
      </>
    ) : (
      <>
        <Stack padding={0.25}>
          <CircularProgress size={20} />
        </Stack>
        {`${string} ${count.includes('/0') ? '' : `(${count})`}`}
      </>
    );

  useEffect(() => {
    (async () => {
      if (serviceUrl) {
        try {
          const hubConnection = new HubConnectionBuilder()
            .withUrl(serviceUrl)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

          // Starts the SignalR connection
          await hubConnection.start();

          // Once started, invokes the sendConnectionId in our ChatHub inside our ASP.NET Core application.
          if (hubConnection.connectionId) {
            hubConnection.invoke('SubscribeToTenantId', tenantId);
          }

          hubConnection.on('ReloadMigrationStatus', () => {
            qc.invalidateQueries([MIGRATION_STATUS_KEY]);
          });

          hubConnection.on('ReloadEmployeeCount', () => {
            qc.invalidateQueries([RP_EMPLOYEE_COUNT_KEY]);
            qc.invalidateQueries([TLP_EMPLOYEE_COUNT_KEY]);
          });

          hubConnection.on('ReloadProjectCount', () => {
            qc.invalidateQueries([RP_PROJECT_COUNT_KEY]);
            qc.invalidateQueries([TLP_PROJECT_COUNT_KEY]);
          });
        } catch {
          // ignore singalr connection error
        }
      }
    })();
  }, [serviceUrl, tenantId, qc]);

  return (
    <Deck.Item>
      <Stack className={styles.intro}>
        <Stack className={styles.introContent}>
          <Stack className={styles.list}>
            <List sx={{ width: 'auto' }}>
              <ListItem>
                <Stack alignItems="center" direction="row">
                  {stepTemplate(isResourceMigrated, t('ImportProgressStepEmployees'), employee)}
                </Stack>
              </ListItem>
              <ListItem>
                <Stack alignItems="center" direction="row">
                  {stepTemplate(isProjectMigrated, t('ImportProgressStepProjects'), project)}
                </Stack>
              </ListItem>
              {canAddResourcePlan ? (
                <ListItem>
                  <Stack alignItems="center" direction="row">
                    {stepTemplate(
                      isResourcePlanMigrated,
                      t('ImportProgressStepResourcePlan'),
                      resourcePlan,
                    )}
                  </Stack>
                </ListItem>
              ) : null}
            </List>
          </Stack>
          <Box className={styles.image}>
            <img alt="rp-import-wait" src={rpImport} />
          </Box>
        </Stack>
      </Stack>
    </Deck.Item>
  );
};
