import { useGetLocale } from 'src/components/global/LocaleProvider';
import { Trans, useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import {
  useGetAddedValueBarPerMonthReportingWidget,
  useGetAddedValuePerMonthPrivilegeOptions,
} from 'src/apis/reportingWidgetsAPI';
import { IAddedValueBarPerMonthWidgetDataItem } from 'src/apis/reportingWidgetsAPI/types/addedValuePerMonth';
import { BarChart } from '@mui/x-charts/BarChart';
import React, { memo, useState } from 'react';
import { MenuItem, SelectChangeEvent, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box, CircularProgress, Select } from 'src/components/mui-components';

import { currencyFormatter } from 'src/utils/currency';
import ReportingWidgetCard from '../ReportingWidgetCard';
import { WidgetEmpty, WidgetError } from '../ReportingWidgetState';
import { chartSetting, convertPrivilegeOptionsToFilterOptions, slotPropsConfig } from './utils';
import { CustomTooltipWithBar } from './components';

export const AddedValueBarPerMonthReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const { privilegeOptions } = useGetAddedValuePerMonthPrivilegeOptions();

  const [alignment, setAlignment] = useState('reg');
  const [valueFilter, setValueFilter] = useState('1');

  const { widgetName, widgetData, currencyAbb, isError, isLoading, isEmpty } =
    useGetAddedValueBarPerMonthReportingWidget(valueFilter);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setValueFilter(event.target.value as string);
  };

  const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const parseRowData = (data: IAddedValueBarPerMonthWidgetDataItem[]) =>
    data.map((item) => ({
      ...item,
      month: translationAnyText(t, `Month${item.month}`),
    }));

  const parsedWidgetData = parseRowData(widgetData);

  const findMaxAmount = (data: IAddedValueBarPerMonthWidgetDataItem[]) =>
    data.reduce((max, item) => {
      const currentMax = Math.max(item.amountRegistered, item.amountEstimated, item?.bar);
      return Math.max(max, currentMax);
    }, 0);

  const valueFormatter = (value: number | null) => currencyFormatter(locale, 2).format(value ?? 0);

  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (isError) {
      return <WidgetError />;
    }
    if (isEmpty) {
      return <WidgetEmpty noDataMessage={t('EmptyStateGeneralAlertMessage')} />;
    }
    return (
      <div data-automation-id={`ReportingWidgetBarChart-${widgetName}`}>
        <BarChart
          grid={{ vertical: true }}
          dataset={parsedWidgetData}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'month',
              tickPlacement: 'middle',
              barGapRatio: 0,
              // To fix param barGapRatio not resolve in typescript issue
              // https://github.com/mui/mui-x/issues/10334
            } as any,
          ]}
          yAxis={[
            {
              label: currencyAbb?.toString() ?? '',
              labelStyle: {
                fontSize: 12,
              },
              valueFormatter,
              max: findMaxAmount(parsedWidgetData) * 1.2,
            },
          ]}
          series={[
            {
              dataKey: alignment === 'reg' ? 'amountRegistered' : 'amountEstimated',
              label:
                alignment === 'reg'
                  ? `${t('AddedValuePerMonthRegAmount')} ${currencyAbb?.toString() ?? ''}`
                  : `${t('AddedValuePerMonthEstAmount')} ${currencyAbb?.toString() ?? ''}`,
              valueFormatter,
            },
            {
              dataKey: 'bar',
              label: `${t('AddedValuePerMonthBarAmount')} ${currencyAbb?.toString() ?? ''}`,
              valueFormatter,
            },
          ]}
          slots={{
            axisContent: memo((props) => (
              <CustomTooltipWithBar
                axisValue={props.axisValue}
                data={parsedWidgetData}
                alignment={alignment}
                currencyAbb={currencyAbb}
                t={t}
                valueFormatter={valueFormatter}
              />
            )),
          }}
          slotProps={slotPropsConfig}
          {...chartSetting(alignment)}
        />
      </div>
    );
  };

  const filterOptions = convertPrivilegeOptionsToFilterOptions(privilegeOptions, t);

  return (
    <ReportingWidgetCard
      name={widgetName}
      title={t('AddedValueBarPerMonth')}
      infoIconText={
        <Trans
          i18nKey="AddedValueBarPerMonthWidgetDescription"
          key="AddedValueBarPerMonthWidgetDescription"
          defaults={t('AddedValueBarPerMonthWidgetDescription')}
          components={{ 1: <br /> }}
          values={{ currency: currencyAbb?.toString() }}
        />
      }
      toggleContent={
        <Stack direction="row" sx={{ mr: 3 }}>
          <Select
            labelId="filter-label"
            id="filter-select"
            value={valueFilter}
            label={t('Filter')}
            onChange={handleSelectChange}
            size="small"
            fullWidth
            data-automation-id="AddedValueBarPerMonthFilterSelect"
            sx={{ minWidth: 180 }}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <ToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignmentChange}
            aria-label={t('AddedValuePerMonth')}
            sx={{ ml: 1 }}
          >
            <ToggleButton
              value="reg"
              title={t('AddedValuePerMonthShowRegisteredValue')}
              data-automation-id="AddedValueBarPerMonthRegButton"
            >
              {t('RegText')}
            </ToggleButton>
            <ToggleButton
              value="est"
              title={t('AddedValuePerMonthShowEstimatedValue')}
              data-automation-id="AddedValueBarPerMonthEstButton"
            >
              {t('EstText')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      }
      hasAction
    >
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};
