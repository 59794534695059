import isEqual from 'lodash/isEqual';
import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { INewSavedView } from 'src/apis/savedViewAPI';
import { FilterValues, TViewOptions } from 'src/apis/savedViewAPI/types';
import { TFilterType } from 'src/apis/types/filterListAPI';
import { Deck } from 'src/components/ui-components';
import { IFilterProps } from 'src/reducers/FilterReducer/FilterReducer';
import { useFilterDispatch, useFilterStore } from 'src/stores/FilterStore';
import { SaveViewContainer } from '../SaveViewContainer';

interface IMainContainer {
  children: ReactNode;
  savedViewsList: INewSavedView[];
  changedViewOptions?: TViewOptions;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  allowSavedViews: boolean;
  preDefinedPeriod?: string;
  setViewOptions?: () => void;
}

export const MainContainer = ({
  children,
  savedViewsList,
  changedViewOptions,
  activeView,
  setActiveView,
  allowSavedViews,
  preDefinedPeriod,
  setViewOptions,
}: IMainContainer) => {
  const dispatch = useFilterDispatch();
  const [isInitialSetupDone, setIsInitialSetupDone] = useState(false);

  const { filterList, selectedFilterList } = useFilterStore();

  const constructFilterPayload = useCallback(
    (payload: FilterValues[]) => {
      // Create a map from filterList for quick lookups
      const filterMap: { [key: string]: Omit<IFilterProps, 'values' | 'label'> } = {};
      filterList.forEach((filterCategory) => {
        filterCategory?.filterItems?.forEach((filterItem) => {
          filterMap[filterItem.name] = filterItem;
        });
      });

      // Construct the new payload
      const newPayload: Record<string, IFilterProps> = {};
      payload.forEach((payloadItem) => {
        const filterItem = filterMap[payloadItem.name];
        if (filterItem) {
          newPayload[payloadItem.name as TFilterType] = {
            label: payloadItem.name,
            values: payloadItem.value,
            contentUrl: filterItem.contentUrl,
            childFilters: filterItem.childFilters ?? [],
            parentFilters: filterItem.parentFilters ?? [],
            isInclude: payloadItem.isInclusive,
            type: filterItem.type,
          };
        }
      });
      return newPayload;
    },
    [filterList],
  );

  const handleFilterChanges = useCallback(
    (id: string) => {
      const payload = savedViewsList?.find((v) => v.filterViewId === id)?.filterValues;
      if (dispatch) {
        dispatch({ type: 'RESET_FILTER' });
        if (payload && Object.keys(payload).length) {
          const finalPayload = constructFilterPayload(payload);
          dispatch?.({ type: 'ADD_OR_UPDATE_FILTER', payload: finalPayload });
        }
        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    },
    [constructFilterPayload, dispatch, savedViewsList],
  );

  const tabOnChange = (id: string) => {
    handleFilterChanges(id);
    // Reset view options if the active view is changed
    if (id === activeView) {
      setViewOptions?.();
    } else {
      setActiveView(id);
    }
  };

  // To set filter on first load of saved view when there is filter and active view
  useEffect(() => {
    if (isInitialSetupDone) return;
    if (savedViewsList && savedViewsList.length > 0 && activeView) {
      const payload = savedViewsList?.find((v) => v.filterViewId === activeView)?.filterValues;
      if (dispatch) {
        if (payload && Object.keys(payload).length) {
          const finalPayload = constructFilterPayload(payload);
          if (!isEqual(finalPayload, selectedFilterList)) {
            dispatch({ type: 'RESET_FILTER' });
            dispatch({ type: 'ADD_OR_UPDATE_FILTER', payload: finalPayload });
          }
          dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
          dispatch({ type: 'DEACTIVATE_PANEL_ID' });
        } else {
          dispatch({ type: 'RESET_FILTER' });
        }
      }
      setIsInitialSetupDone(true);
    }
  }, [
    activeView,
    constructFilterPayload,
    dispatch,
    isInitialSetupDone,
    savedViewsList,
    selectedFilterList,
  ]);

  return (
    <Deck data-automation-id="MainContainer">
      {allowSavedViews && (
        <Deck.Item>
          <SaveViewContainer
            savedViewsList={savedViewsList}
            tabOnChange={tabOnChange}
            activeView={activeView}
            setActiveView={setActiveView}
            changedViewOptions={changedViewOptions}
            constructFilterPayload={constructFilterPayload}
            preDefinedPeriod={preDefinedPeriod}
          />
        </Deck.Item>
      )}
      {children}
    </Deck>
  );
};
