const getChildren = (expandId: string | null) => {
  switch (String(expandId)) {
    case '1': // Anders Nielsen (ANI)
      return [
        {
          StartsAt: '2023-01-10T00:00:00',
          EndsAt: '2023-03-15T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 100,
          TotalBooked: 0,
          TotalActualWorkload: 13,
          TotalEAC: 13,
          NotPlanned: 87,
          PositiveUnplannedHours: 87,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843773288022017',
          workItemSourceReferenceId: '11',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843773288022017',
          name: '_Administration',
          sourceReferenceId: '11',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/_Administration',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-11-02T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843969505951745',
          workItemSourceReferenceId: '78',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843969505951745',
          name: 'Anson has a Tree',
          sourceReferenceId: '78',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Anson has a Tree',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-11-20T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843850383523841',
          workItemSourceReferenceId: '17',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843850383523841',
          name: 'ELL - Ny borgerløsning til skat (FPSA DKK)',
          sourceReferenceId: '17',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844089588875265',
          workItemSourceReferenceId: '74',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844089588875265',
          name: 'Simple test 1',
          sourceReferenceId: '74',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Simple test 1',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2024-05-17T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843924249411585',
          workItemSourceReferenceId: '19',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843924249411585',
          name: 'SWE - National Survey Board (MTEB EUR)',
          sourceReferenceId: '19',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 60,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 60,
          PositiveUnplannedHours: 60,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844343725948929',
          workItemSourceReferenceId: '54',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844343725948929',
          name: 'TCC1204-quick create',
          sourceReferenceId: '54',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204-quick create',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-03-16T00:00:00',
          EndsAt: '2022-06-30T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844041731866625',
          workItemSourceReferenceId: '76',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844041731866625',
          name: 'Z - Test 30 days - 2',
          sourceReferenceId: '76',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Z - Test 30 days - 2',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '11': // _Administration
      return [
        {
          StartsAt: '2023-02-15T00:00:00',
          EndsAt: '2023-02-28T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 100,
          TotalBooked: 0,
          TotalActualWorkload: 13,
          TotalEAC: 13,
          NotPlanned: 87,
          PositiveUnplannedHours: 87,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843773384491010',
          workItemSourceReferenceId: '24',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843773384491010',
          name: 'Fakturering (O0002)',
          sourceReferenceId: '24',
          tenantId: '10002',
          parentId: '183843773288022017',
          type: 'Task',
          pathToParent: '/183843773288022017/',
          sortOrder: 2,
          level: 2,
          sortablePath: '/_Administration/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '78': // Anson has a Tree
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843969615003657',
          workItemSourceReferenceId: '492',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843969615003657',
          name: 'TMSA006 (O0469)',
          sourceReferenceId: '492',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 9,
          level: 2,
          sortablePath: '/Anson has a Tree/009',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '492': // TMSA006 (O0469)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843969615003658',
          workItemSourceReferenceId: '500',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843969615003658',
          name: 'TMSA0061 (O0477)',
          sourceReferenceId: '500',
          tenantId: '10002',
          parentId: '183843969615003657',
          type: 'Task',
          pathToParent: '/183843969505951745/183843969615003657/',
          sortOrder: 10,
          level: 3,
          sortablePath: '/Anson has a Tree/009/010',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '500': // TMSA0061 (O0477)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843969615003659',
          workItemSourceReferenceId: '501',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843969615003659',
          name: 'TMSA00611 (O0478)',
          sourceReferenceId: '501',
          tenantId: '10002',
          parentId: '183843969615003658',
          type: 'Task',
          pathToParent: '/183843969505951745/183843969615003657/183843969615003658/',
          sortOrder: 11,
          level: 4,
          sortablePath: '/Anson has a Tree/009/010/011',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '501': // TMSA00611 (O0478)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843969615003660',
          workItemSourceReferenceId: '502',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843969615003660',
          name: 'TMSA006111 (O0479)',
          sourceReferenceId: '502',
          tenantId: '10002',
          parentId: '183843969615003659',
          type: 'Task',
          pathToParent:
            '/183843969505951745/183843969615003657/183843969615003658/183843969615003659/',
          sortOrder: 12,
          level: 5,
          sortablePath: '/Anson has a Tree/009/010/011/012',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '502': // TMSA006111 (O0479)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843969615003661',
          workItemSourceReferenceId: '503',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843969615003661',
          name: '4 parents task (O0480)',
          sourceReferenceId: '503',
          tenantId: '10002',
          parentId: '183843969615003660',
          type: 'Task',
          pathToParent:
            '/183843969505951745/183843969615003657/183843969615003658/183843969615003659/183843969615003660/',
          sortOrder: 13,
          level: 6,
          sortablePath: '/Anson has a Tree/009/010/011/012/013',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '17': // ELL - Ny borgerløsning til skat (FPSA DKK)
      return [
        {
          StartsAt: '2023-03-20T00:00:00',
          EndsAt: '2023-09-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843850509352965',
          workItemSourceReferenceId: '62',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843850509352965',
          name: 'Fase 2 (O0040)',
          sourceReferenceId: '62',
          tenantId: '10002',
          parentId: '183843850383523841',
          type: 'Task',
          pathToParent: '/183843850383523841/',
          sortOrder: 6,
          level: 2,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '62': // Fase 2 (O0040)
      return [
        {
          StartsAt: '2023-05-18T00:00:00',
          EndsAt: '2023-07-19T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843850509352967',
          workItemSourceReferenceId: '64',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843850509352967',
          name: 'Design af brugergrænseflader (O0042)',
          sourceReferenceId: '64',
          tenantId: '10002',
          parentId: '183843850509352965',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352965/',
          sortOrder: 8,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/006/008',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '74': // Simple test 1
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844089685344260',
          workItemSourceReferenceId: '459',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844089685344260',
          name: 'Simple contact (O0436)',
          sourceReferenceId: '459',
          tenantId: '10002',
          parentId: '183844089588875265',
          type: 'Task',
          pathToParent: '/183844089588875265/',
          sortOrder: 4,
          level: 2,
          sortablePath: '/Simple test 1/004',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '459': // Simple contact (O0436)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844089685344261',
          workItemSourceReferenceId: '463',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844089685344261',
          name: 'Ip test (O0440)',
          sourceReferenceId: '463',
          tenantId: '10002',
          parentId: '183844089685344260',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344260/',
          sortOrder: 5,
          level: 3,
          sortablePath: '/Simple test 1/004/005',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '463': // Ip test (O0440)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844089685344264',
          workItemSourceReferenceId: '460',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844089685344264',
          name: 'Maybe it is (O0437)',
          sourceReferenceId: '460',
          tenantId: '10002',
          parentId: '183844089685344261',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344260/183844089685344261/',
          sortOrder: 8,
          level: 4,
          sortablePath: '/Simple test 1/004/005/008',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '19': // SWE - National Survey Board (MTEB EUR)
      return [
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-07-19T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843924345880577',
          workItemSourceReferenceId: '75',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843924345880577',
          name: 'Survey Production (start-up) (O0053)',
          sourceReferenceId: '75',
          tenantId: '10002',
          parentId: '183843924249411585',
          type: 'Task',
          pathToParent: '/183843924249411585/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '75': // Survey Production (start-up) (O0053)
      return [
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-07-19T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843924345880578',
          workItemSourceReferenceId: '72',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843924345880578',
          name: 'Build Survey Framework (O0050)',
          sourceReferenceId: '72',
          tenantId: '10002',
          parentId: '183843924345880577',
          type: 'Task',
          pathToParent: '/183843924249411585/183843924345880577/',
          sortOrder: 2,
          level: 3,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/001/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-03-20T00:00:00',
          EndsAt: '2023-05-29T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843924345880579',
          workItemSourceReferenceId: '73',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183843924345880579',
          name: 'Setup Survey Monkey (O0051)',
          sourceReferenceId: '73',
          tenantId: '10002',
          parentId: '183843924345880577',
          type: 'Task',
          pathToParent: '/183843924249411585/183843924345880577/',
          sortOrder: 3,
          level: 3,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/001/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '54': // TCC1204-quick create
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 60,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 60,
          PositiveUnplannedHours: 60,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844343826612229',
          workItemSourceReferenceId: '272',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844343826612229',
          name: 'FPT004 (O0250)',
          sourceReferenceId: '272',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 5,
          level: 2,
          sortablePath: '/TCC1204-quick create/005',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '272': // FPT004 (O0250)
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 60,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 60,
          PositiveUnplannedHours: 60,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844343826612230',
          workItemSourceReferenceId: '280',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844343826612230',
          name: 'FPT0041 (O0258)',
          sourceReferenceId: '280',
          tenantId: '10002',
          parentId: '183844343826612229',
          type: 'Task',
          pathToParent: '/183844343725948929/183844343826612229/',
          sortOrder: 6,
          level: 3,
          sortablePath: '/TCC1204-quick create/005/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '280': // "FPT0041 (O0258)"
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 60,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 60,
          PositiveUnplannedHours: 60,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844343826612231',
          workItemSourceReferenceId: '281',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844343826612231',
          name: 'FPT00411 (O0259)',
          sourceReferenceId: '281',
          tenantId: '10002',
          parentId: '183844343826612230',
          type: 'Task',
          pathToParent: '/183844343725948929/183844343826612229/183844343826612230/',
          sortOrder: 7,
          level: 4,
          sortablePath: '/TCC1204-quick create/005/006/007',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '76': // Z - Test 30 days - 2
      return [
        {
          StartsAt: '2022-03-16T00:00:00',
          EndsAt: '2022-06-30T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844041828335617',
          workItemSourceReferenceId: '471',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: 'Anders Nielsen',
          id: '183844041828335617',
          name: 'Test 30 days - Task 1 (O0448)',
          sourceReferenceId: '471',
          tenantId: '10002',
          parentId: '183844041731866625',
          type: 'Task',
          pathToParent: '/183844041731866625/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/Z - Test 30 days - 2/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    default:
      return [];
  }
};

export default (expandId: string | null) => ({
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'GroupByResource',
      viewOptions: {
        'grouped-by': 'group-by-resource',
        'reporting-types': 'availability',
        'period-types': 'day',
        'unit-types': 'hours',
        'period-starts-at': '2024-10-22',
        'period-ends-at': '2024-11-17',
      },
      periods: [
        {
          startsAt: '2024-10-22T00:00:00',
          endsAt: '2024-10-22T00:00:00',
          type: 'Day',
          identifier: '2024Oct22',
        },
        {
          startsAt: '2024-10-23T00:00:00',
          endsAt: '2024-10-23T00:00:00',
          type: 'Day',
          identifier: '2024Oct23',
        },
        {
          startsAt: '2024-10-24T00:00:00',
          endsAt: '2024-10-24T00:00:00',
          type: 'Day',
          identifier: '2024Oct24',
        },
        {
          startsAt: '2024-10-25T00:00:00',
          endsAt: '2024-10-25T00:00:00',
          type: 'Day',
          identifier: '2024Oct25',
        },
        {
          startsAt: '2024-10-26T00:00:00',
          endsAt: '2024-10-26T00:00:00',
          type: 'Day',
          identifier: '2024Oct26',
        },
        {
          startsAt: '2024-10-27T00:00:00',
          endsAt: '2024-10-27T00:00:00',
          type: 'Day',
          identifier: '2024Oct27',
        },
        {
          startsAt: '2024-10-28T00:00:00',
          endsAt: '2024-10-28T00:00:00',
          type: 'Day',
          identifier: '2024Oct28',
        },
        {
          startsAt: '2024-10-29T00:00:00',
          endsAt: '2024-10-29T00:00:00',
          type: 'Day',
          identifier: '2024Oct29',
        },
        {
          startsAt: '2024-10-30T00:00:00',
          endsAt: '2024-10-30T00:00:00',
          type: 'Day',
          identifier: '2024Oct30',
        },
        {
          startsAt: '2024-10-31T00:00:00',
          endsAt: '2024-10-31T00:00:00',
          type: 'Day',
          identifier: '2024Oct31',
        },
        {
          startsAt: '2024-11-01T00:00:00',
          endsAt: '2024-11-01T00:00:00',
          type: 'Day',
          identifier: '2024Nov01',
        },
        {
          startsAt: '2024-11-02T00:00:00',
          endsAt: '2024-11-02T00:00:00',
          type: 'Day',
          identifier: '2024Nov02',
        },
        {
          startsAt: '2024-11-03T00:00:00',
          endsAt: '2024-11-03T00:00:00',
          type: 'Day',
          identifier: '2024Nov03',
        },
        {
          startsAt: '2024-11-04T00:00:00',
          endsAt: '2024-11-04T00:00:00',
          type: 'Day',
          identifier: '2024Nov04',
        },
        {
          startsAt: '2024-11-05T00:00:00',
          endsAt: '2024-11-05T00:00:00',
          type: 'Day',
          identifier: '2024Nov05',
        },
        {
          startsAt: '2024-11-06T00:00:00',
          endsAt: '2024-11-06T00:00:00',
          type: 'Day',
          identifier: '2024Nov06',
        },
        {
          startsAt: '2024-11-07T00:00:00',
          endsAt: '2024-11-07T00:00:00',
          type: 'Day',
          identifier: '2024Nov07',
        },
        {
          startsAt: '2024-11-08T00:00:00',
          endsAt: '2024-11-08T00:00:00',
          type: 'Day',
          identifier: '2024Nov08',
        },
        {
          startsAt: '2024-11-09T00:00:00',
          endsAt: '2024-11-09T00:00:00',
          type: 'Day',
          identifier: '2024Nov09',
        },
        {
          startsAt: '2024-11-10T00:00:00',
          endsAt: '2024-11-10T00:00:00',
          type: 'Day',
          identifier: '2024Nov10',
        },
        {
          startsAt: '2024-11-11T00:00:00',
          endsAt: '2024-11-11T00:00:00',
          type: 'Day',
          identifier: '2024Nov11',
        },
        {
          startsAt: '2024-11-12T00:00:00',
          endsAt: '2024-11-12T00:00:00',
          type: 'Day',
          identifier: '2024Nov12',
        },
        {
          startsAt: '2024-11-13T00:00:00',
          endsAt: '2024-11-13T00:00:00',
          type: 'Day',
          identifier: '2024Nov13',
        },
        {
          startsAt: '2024-11-14T00:00:00',
          endsAt: '2024-11-14T00:00:00',
          type: 'Day',
          identifier: '2024Nov14',
        },
        {
          startsAt: '2024-11-15T00:00:00',
          endsAt: '2024-11-15T00:00:00',
          type: 'Day',
          identifier: '2024Nov15',
        },
        {
          startsAt: '2024-11-16T00:00:00',
          endsAt: '2024-11-16T00:00:00',
          type: 'Day',
          identifier: '2024Nov16',
        },
        {
          startsAt: '2024-11-17T00:00:00',
          endsAt: '2024-11-17T00:00:00',
          type: 'Day',
          identifier: '2024Nov17',
        },
      ],
      children: getChildren(expandId),
    },
    links: [
      {
        href: 'http://uat2.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyemployee?periodstartsat=2024-10-22&periodendsat=2024-11-17&periodtypes=day&unittypes=hours&reportingtypes=availability&showtaskno=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 1688950302,
  StatusCode: 200,
});
