import { FormControl, MenuItem, Button, Stack } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTaskOptions } from 'src/apis/moveHoursAPI';
import { Autocomplete } from 'src/components/mui-components';
import { HighlightMatchingText } from 'src/components/utils/HighlightMatchingText';
import { IProjectOptions, ITaskOptions } from 'src/apis/moveHoursAPI/types';
import { ReportingToolbar } from 'src/components/global/ReportingTable';

interface IToolbarProps {
  selectedRowProject: string[];
  setColumnsButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  filterSection?: ReactNode;
  projectOptions?: IProjectOptions[];
  onApply: (selectedTaskValue: number) => void;
}

interface IFilteredTaskOptions {
  value: number;
  name: string;
  depth: number;
  isClickable: boolean;
}

export const Toolbar = ({
  selectedRowProject,
  setColumnsButtonEl,
  filterSection,
  projectOptions,
  onApply,
}: IToolbarProps) => {
  const { t } = useTranslation('moveHours');

  const [projectId, setProjectId] = useState<number>(0);
  const [selectedTask, setSelectedTask] = useState<IFilteredTaskOptions | null>(null);

  const { taskOptions } = useGetTaskOptions(projectId);

  const flattenTaskOptions = (options: ITaskOptions[]) => {
    const flattened: { value: number; name: string; depth: number; isClickable: boolean }[] = [];

    const flatten = (opts: ITaskOptions[], depth = 1) => {
      opts.forEach((option) => {
        flattened.push({
          value: option.value,
          name: option.name,
          depth,
          isClickable: !option.items || option.items.length === 0,
        });
        if (option.items) {
          flatten(option.items, depth + 1);
        }
      });
    };
    flatten(options);
    return flattened;
  };

  useEffect(() => {
    setProjectId(0);
    setSelectedTask(null);
  }, [selectedRowProject]);

  return (
    <ReportingToolbar
      selectedRowProject={selectedRowProject}
      setColumnsButtonEl={setColumnsButtonEl}
      filterSection={filterSection}
      toolbarContent={
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2} sx={{ flexWrap: 'wrap' }}>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Autocomplete
              size="small"
              label={t('SelectProjectText')}
              placeholder=""
              options={projectOptions ?? []}
              getOptionLabel={(option) => (typeof option === 'object' ? option.name : option)}
              isOptionEqualToValue={(o, v) => o.name === v.name}
              componentsProps={{ popper: { style: { width: 200 } } }}
              renderOption={(props, option, state) => (
                <li
                  data-automation-id={`${option.name}Item`}
                  {...props}
                  key={`${option.name}-${state.index}`}
                >
                  <HighlightMatchingText name={option.name} matchName={state.inputValue} />
                </li>
              )}
              onChange={(event, newValue) => {
                if (newValue) {
                  setProjectId((newValue as IProjectOptions).value);
                  setSelectedTask(null);
                } else {
                  setSelectedTask(null);
                  setProjectId(0);
                }
              }}
              sx={{ backgroundColor: 'white' }}
              data-automation-id="ToolbarProjectNameDropdown"
            />
          </FormControl>
          <FormControl sx={{ minWidth: 200 }} size="small">
            <Autocomplete
              size="small"
              label={t('SelectTaskText')}
              placeholder=""
              options={flattenTaskOptions(taskOptions)}
              getOptionLabel={(option) => (typeof option === 'object' ? option.name : option)}
              isOptionEqualToValue={(o, v) => o.name === v.name}
              componentsProps={{ popper: { style: { width: 200 } } }}
              disabled={!projectId || projectId === 0}
              renderOption={(props, option, state) =>
                option.isClickable ? (
                  <li
                    {...props}
                    data-automation-id={`${option.name}Item`}
                    key={`${option.name}-${state.index}`}
                    style={{ paddingLeft: `${option.depth}rem`, whiteSpace: 'normal' }}
                  >
                    <HighlightMatchingText name={option.name} matchName={state.inputValue} />
                  </li>
                ) : (
                  <MenuItem
                    key={`${option.name}-${state.index}`}
                    sx={{ pl: option.depth * 2, whiteSpace: 'normal' }}
                    disabled
                  >
                    {option.name}
                  </MenuItem>
                )
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedTask(newValue as IFilteredTaskOptions);
                } else {
                  setSelectedTask(null);
                }
              }}
              value={selectedTask}
              sx={{ backgroundColor: !projectId || projectId === 0 ? 'initial' : 'white' }}
              data-automation-id="ToolbarTaskNameDropdown"
            />
          </FormControl>
          <Button
            variant="outlined"
            size="small"
            disabled={!selectedTask}
            onClick={() => {
              if (selectedTask) {
                onApply(selectedTask.value);
              }
            }}
          >
            {t('MoveHoursText')}
          </Button>
        </Stack>
      }
    />
  );
};
