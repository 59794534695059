export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10003',
      responseType: 'GroupByWorkItem',
      viewOptions: {
        'grouped-by': 'group-by-work-item',
        'reporting-types': 'availability',
        'period-types': 'week',
        'unit-types': 'hours',
        'period-starts-at': '2024-11-01',
        'period-ends-at': '2024-12-31',
      },
      periods: [
        {
          startsAt: '2024-10-28T00:00:00',
          endsAt: '2024-11-03T00:00:00',
          type: 'Week',
          identifier: '2024W44',
        },
        {
          startsAt: '2024-11-04T00:00:00',
          endsAt: '2024-11-10T00:00:00',
          type: 'Week',
          identifier: '2024W45',
        },
        {
          startsAt: '2024-11-11T00:00:00',
          endsAt: '2024-11-17T00:00:00',
          type: 'Week',
          identifier: '2024W46',
        },
        {
          startsAt: '2024-11-18T00:00:00',
          endsAt: '2024-11-24T00:00:00',
          type: 'Week',
          identifier: '2024W47',
        },
        {
          startsAt: '2024-11-25T00:00:00',
          endsAt: '2024-12-01T00:00:00',
          type: 'Week',
          identifier: '2024W48',
        },
        {
          startsAt: '2024-12-02T00:00:00',
          endsAt: '2024-12-08T00:00:00',
          type: 'Week',
          identifier: '2024W49',
        },
        {
          startsAt: '2024-12-09T00:00:00',
          endsAt: '2024-12-15T00:00:00',
          type: 'Week',
          identifier: '2024W50',
        },
        {
          startsAt: '2024-12-16T00:00:00',
          endsAt: '2024-12-22T00:00:00',
          type: 'Week',
          identifier: '2024W51',
        },
        {
          startsAt: '2024-12-23T00:00:00',
          endsAt: '2024-12-29T00:00:00',
          type: 'Week',
          identifier: '2024W52',
        },
        {
          startsAt: '2024-12-30T00:00:00',
          endsAt: '2025-01-05T00:00:00',
          type: 'Week',
          identifier: '2025W1',
        },
      ],
      children: [
        {
          StartsAt: '2018-01-01T00:00:00',
          EndsAt: '2020-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 986703.94,
          TotalEAC: 986703.94,
          NotPlanned: -986703.94,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -986703.94,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362593988550590465',
          workItemSourceReferenceId: '8606',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362593988550590465',
          name: '- Interntid',
          sourceReferenceId: '8606',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/- Interntid',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2030-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 123.5,
          TotalEAC: 123.5,
          NotPlanned: -123.5,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -123.5,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362594374883737601',
          workItemSourceReferenceId: '27392',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362594374883737601',
          name: '0125 IAVF Investment Management filial_4194_ACC_LÖP',
          sourceReferenceId: '27392',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/0125 IAVF Investment Management filial_4194_ACC_LÖP',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2024-02-13T00:00:00',
          EndsAt: '2030-01-01T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 30582.06,
          TotalEAC: 30582.06,
          NotPlanned: -30582.06,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -30582.06,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597646021427201',
          workItemSourceReferenceId: '34395',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597646021427201',
          name: '(E) Accountor + Intern tid ',
          sourceReferenceId: '34395',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Accountor + Intern tid ',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-01-01T00:00:00',
          EndsAt: '2024-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 52399.49,
          TotalEAC: 52399.49,
          NotPlanned: -52399.49,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -52399.49,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362593994091266049',
          workItemSourceReferenceId: '19892',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362593994091266049',
          name: 'Accountor Services intern tid',
          sourceReferenceId: '19892',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Accountor Services intern tid',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-01-01T00:00:00',
          EndsAt: '2021-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 10562.76,
          TotalEAC: 10562.76,
          NotPlanned: -10562.76,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -10562.76,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362593998822440961',
          workItemSourceReferenceId: '22033',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362593998822440961',
          name: 'Accountors förvärv',
          sourceReferenceId: '22033',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Accountors förvärv',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2030-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 67.71,
          TotalEAC: 67.71,
          NotPlanned: -67.71,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -67.71,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362595501968719873',
          workItemSourceReferenceId: '30506',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362595501968719873',
          name: 'Advokat Emine Lundkvist AB_12056_ACC_LÖP',
          sourceReferenceId: '30506',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Advokat Emine Lundkvist AB_12056_ACC_LÖP',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2030-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 23.25,
          TotalEAC: 23.25,
          NotPlanned: -23.25,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -23.25,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362595834841268225',
          workItemSourceReferenceId: '31118',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362595834841268225',
          name: 'Aktiebolaget Drivfjädern_12366_ACC_LÖP',
          sourceReferenceId: '31118',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Aktiebolaget Drivfjädern_12366_ACC_LÖP',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2030-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 24.0,
          TotalEAC: 24.0,
          NotPlanned: -24.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -24.0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362595136951025665',
          workItemSourceReferenceId: '29347',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362595136951025665',
          name: 'Dr Mehran Frödenlund AB_11355_ACC_LÖP',
          sourceReferenceId: '29347',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Dr Mehran Frödenlund AB_11355_ACC_LÖP',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-10-01T00:00:00',
          EndsAt: '2023-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 11926.61,
          TotalEAC: 11926.61,
          NotPlanned: -11926.61,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -11926.61,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362593980518498305',
          workItemSourceReferenceId: '18802',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362593980518498305',
          name: 'IT/Systemadministration',
          sourceReferenceId: '18802',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/IT/Systemadministration',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2024-01-01T00:00:00',
          EndsAt: '2030-01-01T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 11.25,
          TotalEAC: 11.25,
          NotPlanned: -11.25,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -11.25,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362597757975789569',
          workItemSourceReferenceId: '34529',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362597757975789569',
          name: 'Nahid Farmaci Hägersten AB_119390_ACC_240101',
          sourceReferenceId: '34529',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Nahid Farmaci Hägersten AB_119390_ACC_240101',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2024-09-05T00:00:00',
          EndsAt: '2030-01-01T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 309.67,
          TotalEAC: 309.67,
          NotPlanned: -309.67,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -309.67,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362600743665926145',
          workItemSourceReferenceId: '38406',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362600743665926145',
          name: 'NexOS',
          sourceReferenceId: '38406',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/NexOS',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2030-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 34.8,
          TotalEAC: 34.8,
          NotPlanned: -34.8,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -34.8,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362594361742983169',
          workItemSourceReferenceId: '27338',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362594361742983169',
          name: 'Omvårdnadsinstitutet i Sverige AB_9056_ACC_LÖP',
          sourceReferenceId: '27338',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Omvårdnadsinstitutet i Sverige AB_9056_ACC_LÖP',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2030-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.02,
          TotalEAC: 0.02,
          NotPlanned: -0.02,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -0.02,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362595331617062913',
          workItemSourceReferenceId: '29867',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362595331617062913',
          name: 'Övningsbolaget AB_9449_ACC_LÖP',
          sourceReferenceId: '29867',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Övningsbolaget AB_9449_ACC_LÖP',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2023-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 1.0,
          TotalEAC: 1.0,
          NotPlanned: -1.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -1.0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362595332518838273',
          workItemSourceReferenceId: '29871',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362595332518838273',
          name: 'Övningsbolaget AB_9449_ACC_LÖP_230101-231231',
          sourceReferenceId: '29871',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Övningsbolaget AB_9449_ACC_LÖP_230101-231231',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-01-01T00:00:00',
          EndsAt: '2022-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362594035510018049',
          workItemSourceReferenceId: '25078',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362594035510018049',
          name: 'ÖvningsbolagetAB_9449_Test_2022-01-01-2022-12-31',
          sourceReferenceId: '25078',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/ÖvningsbolagetAB_9449_Test_2022-01-01-2022-12-31',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-04-17T00:00:00',
          EndsAt: '2023-07-17T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 4.0,
          TotalEAC: 4.0,
          NotPlanned: -4.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -4.0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362595437229637633',
          workItemSourceReferenceId: '30207',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362595437229637633',
          name: 'TEST TIMELOG',
          sourceReferenceId: '30207',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TEST TIMELOG',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-10-17T00:00:00',
          EndsAt: '2022-10-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362594033714855937',
          workItemSourceReferenceId: '24838',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362594033714855937',
          name: 'TestLinnea',
          sourceReferenceId: '24838',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TestLinnea',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-01T00:00:00',
          EndsAt: '2023-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024W44': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W45': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W46': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W47': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W48': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W49': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W50': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W51': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024W52': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2025W1': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '362594429552295937',
          workItemSourceReferenceId: '27538',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '362594429552295937',
          name: 'United media group AB_ ADV_230101-231231',
          sourceReferenceId: '27538',
          tenantId: '10003',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/United media group AB_ ADV_230101-231231',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ],
    },
    links: [
      {
        href: 'http://uat3.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyworkitem?periodstartsat=2024-11-01&periodendsat=2024-12-31&periodtypes=week&unittypes=hours&reportingtypes=availability&firstload=true&requesthavefilters=true&requesthaveemployeefilters=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 334845800,
  StatusCode: 200,
};
