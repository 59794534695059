import { useQuery } from '@tanstack/react-query';
import { IRpCountResponse } from 'src/apis/types/resourcePlannerAPI';
import { getFetch } from 'src/utils/fetcher';
import { REFETCH_INTERVAL, RP_EMPLOYEE_COUNT_KEY } from '../constants';

const RP_EMPLOYEE_COUNT_URL_PATH = '/api/v2/resource-planner/rp-active-employee-count';

const resourcePlannerFetch = (): Promise<IRpCountResponse> =>
  getFetch({ path: RP_EMPLOYEE_COUNT_URL_PATH, key: RP_EMPLOYEE_COUNT_KEY });

export const useGetRpEmployeeCount = (refetch: boolean) => {
  const { data, ...restProps } = useQuery({
    queryFn: resourcePlannerFetch,
    queryKey: [RP_EMPLOYEE_COUNT_KEY],
    refetchInterval: refetch ? REFETCH_INTERVAL : undefined,
  });

  return {
    count: data?.model?.properties.count ?? 0,
    ...restProps,
  };
};
