// To test infinite scrolling, change `take` in `ResourceTableGroupedByEmployee.constants.ts` to 5
const getChildren = (skip: number) => {
  switch (skip) {
    case 0:
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 160.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 13.0,
          TotalEAC: 13.0,
          NotPlanned: 147.0,
          PositiveUnplannedHours: 147.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 76.0,
            value: 76.0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 0.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843658015965184',
          resourceSourceReferenceId: 34,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843658015965184',
          name: 'Anett Korterup (AK)',
          role: 'Software',
          isActive: false,
          hiredAt: '2021-07-05',
          tenantId: '10002',
          sourceReferenceId: 34,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 10.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 20.0,
          TotalEAC: 20.0,
          NotPlanned: -10.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: -10.0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843654257868800',
          resourceSourceReferenceId: 36,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843654257868800',
          name: 'Brian Thor Christensen (BTC)',
          role: 'Software',
          isActive: false,
          hiredAt: '2021-09-16',
          tenantId: '10002',
          sourceReferenceId: 36,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 0.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843666681397248',
          resourceSourceReferenceId: 15,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843666681397248',
          name: 'Bruno Mars (BRM)',
          role: 'PO',
          isActive: false,
          hiredAt: '2021-08-18',
          tenantId: '10002',
          sourceReferenceId: 15,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 0.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843672704417792',
          resourceSourceReferenceId: 6,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843672704417792',
          name: 'Carl Chan (CCH)',
          role: 'Leder',
          isActive: false,
          hiredAt: '2020-01-01',
          tenantId: '10002',
          sourceReferenceId: 6,
          type: 'Employee',
        },
      ];

    case 5:
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 105.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 15.0,
          TotalEAC: 15.0,
          NotPlanned: 90.0,
          PositiveUnplannedHours: 90.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 97.4,
            value: 97.4,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843667503480832',
          resourceSourceReferenceId: 9,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843667503480832',
          name: 'Erling Sørensen (ESØ)',
          role: 'Bogholder',
          isActive: false,
          hiredAt: '2020-01-01',
          tenantId: '10002',
          sourceReferenceId: 9,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 430.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 430.0,
          PositiveUnplannedHours: 430.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843670116532224',
          resourceSourceReferenceId: 10,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843670116532224',
          name: 'Frederik Olsen (FSO)',
          role: 'Konsulent',
          isActive: false,
          hiredAt: '2020-01-01',
          tenantId: '10002',
          sourceReferenceId: 10,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 0.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843668799520768',
          resourceSourceReferenceId: 12,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843668799520768',
          name: 'George Best (GEB)',
          role: 'Developer',
          isActive: false,
          hiredAt: '2020-12-08',
          tenantId: '10002',
          sourceReferenceId: 12,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 273.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 273.0,
          PositiveUnplannedHours: 273.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843662482898944',
          resourceSourceReferenceId: 16,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843662482898944',
          name: 'Jacky Chan (JAC)',
          role: '',
          isActive: false,
          hiredAt: '2021-08-19',
          tenantId: '10002',
          sourceReferenceId: 16,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 0.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843659790155776',
          resourceSourceReferenceId: 33,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843659790155776',
          name: 'Jens Østergaard (JOE)',
          role: 'Leder',
          isActive: false,
          hiredAt: '2021-09-16',
          tenantId: '10002',
          sourceReferenceId: 33,
          type: 'Employee',
        },
      ];

    case 10:
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 4.0,
              value: 4.0,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 12.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 12.0,
          PositiveUnplannedHours: 12.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 76.0,
            value: 76.0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843661249773568',
          resourceSourceReferenceId: 32,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843661249773568',
          name: 'Klaus Lingskov (KL)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2021-05-03',
          tenantId: '10002',
          sourceReferenceId: 32,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 15.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 15.0,
          PositiveUnplannedHours: 15.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843655151255552',
          resourceSourceReferenceId: 35,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843655151255552',
          name: 'Mads Klavstrup  Mathiasen (MKM)',
          role: 'CEO',
          isActive: false,
          hiredAt: '2021-07-05',
          tenantId: '10002',
          sourceReferenceId: 35,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 164.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 164.0,
          PositiveUnplannedHours: 164.0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843662986215424',
          resourceSourceReferenceId: 31,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843662986215424',
          name: 'Maj Britt  Brøndum (MB)',
          role: 'Software',
          isActive: false,
          hiredAt: '2021-05-03',
          tenantId: '10002',
          sourceReferenceId: 31,
          type: 'Employee',
        },
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct22': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 7.4,
              value: 7.4,
              heatmapCode: 'bgAvailability100',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0.0,
              value: 0.0,
              heatmapCode: 'bgLightGray',
              editable: false,
            },
          },
          Budget: 0.0,
          TotalBooked: 0.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 0.0,
          NotPlanned: 0.0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 140.6,
            value: 140.6,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '183843661866336256',
          resourceSourceReferenceId: 19,
          resourceType: 'Employee',
          workItemId: '0',
          workItemSourceReferenceId: '0',
          workItemType: 'Unknown',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843661866336256',
          name: 'Mary Wong (MAW)',
          role: '',
          isActive: false,
          hiredAt: '2021-09-02',
          tenantId: '10002',
          sourceReferenceId: 19,
          type: 'Employee',
        },
      ];

    default:
      return [];
  }
};

export default (skip: number) => ({
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'GroupByResource',
      viewOptions: {
        'grouped-by': 'group-by-resource',
        'reporting-types': 'availability',
        'period-types': 'day',
        'unit-types': 'hours',
        'period-starts-at': '2024-10-22',
        'period-ends-at': '2024-11-17',
      },
      periods: [
        {
          startsAt: '2024-10-22T00:00:00',
          endsAt: '2024-10-22T00:00:00',
          type: 'Day',
          identifier: '2024Oct22',
        },
        {
          startsAt: '2024-10-23T00:00:00',
          endsAt: '2024-10-23T00:00:00',
          type: 'Day',
          identifier: '2024Oct23',
        },
        {
          startsAt: '2024-10-24T00:00:00',
          endsAt: '2024-10-24T00:00:00',
          type: 'Day',
          identifier: '2024Oct24',
        },
        {
          startsAt: '2024-10-25T00:00:00',
          endsAt: '2024-10-25T00:00:00',
          type: 'Day',
          identifier: '2024Oct25',
        },
        {
          startsAt: '2024-10-26T00:00:00',
          endsAt: '2024-10-26T00:00:00',
          type: 'Day',
          identifier: '2024Oct26',
        },
        {
          startsAt: '2024-10-27T00:00:00',
          endsAt: '2024-10-27T00:00:00',
          type: 'Day',
          identifier: '2024Oct27',
        },
        {
          startsAt: '2024-10-28T00:00:00',
          endsAt: '2024-10-28T00:00:00',
          type: 'Day',
          identifier: '2024Oct28',
        },
        {
          startsAt: '2024-10-29T00:00:00',
          endsAt: '2024-10-29T00:00:00',
          type: 'Day',
          identifier: '2024Oct29',
        },
        {
          startsAt: '2024-10-30T00:00:00',
          endsAt: '2024-10-30T00:00:00',
          type: 'Day',
          identifier: '2024Oct30',
        },
        {
          startsAt: '2024-10-31T00:00:00',
          endsAt: '2024-10-31T00:00:00',
          type: 'Day',
          identifier: '2024Oct31',
        },
        {
          startsAt: '2024-11-01T00:00:00',
          endsAt: '2024-11-01T00:00:00',
          type: 'Day',
          identifier: '2024Nov01',
        },
        {
          startsAt: '2024-11-02T00:00:00',
          endsAt: '2024-11-02T00:00:00',
          type: 'Day',
          identifier: '2024Nov02',
        },
        {
          startsAt: '2024-11-03T00:00:00',
          endsAt: '2024-11-03T00:00:00',
          type: 'Day',
          identifier: '2024Nov03',
        },
        {
          startsAt: '2024-11-04T00:00:00',
          endsAt: '2024-11-04T00:00:00',
          type: 'Day',
          identifier: '2024Nov04',
        },
        {
          startsAt: '2024-11-05T00:00:00',
          endsAt: '2024-11-05T00:00:00',
          type: 'Day',
          identifier: '2024Nov05',
        },
        {
          startsAt: '2024-11-06T00:00:00',
          endsAt: '2024-11-06T00:00:00',
          type: 'Day',
          identifier: '2024Nov06',
        },
        {
          startsAt: '2024-11-07T00:00:00',
          endsAt: '2024-11-07T00:00:00',
          type: 'Day',
          identifier: '2024Nov07',
        },
        {
          startsAt: '2024-11-08T00:00:00',
          endsAt: '2024-11-08T00:00:00',
          type: 'Day',
          identifier: '2024Nov08',
        },
        {
          startsAt: '2024-11-09T00:00:00',
          endsAt: '2024-11-09T00:00:00',
          type: 'Day',
          identifier: '2024Nov09',
        },
        {
          startsAt: '2024-11-10T00:00:00',
          endsAt: '2024-11-10T00:00:00',
          type: 'Day',
          identifier: '2024Nov10',
        },
        {
          startsAt: '2024-11-11T00:00:00',
          endsAt: '2024-11-11T00:00:00',
          type: 'Day',
          identifier: '2024Nov11',
        },
        {
          startsAt: '2024-11-12T00:00:00',
          endsAt: '2024-11-12T00:00:00',
          type: 'Day',
          identifier: '2024Nov12',
        },
        {
          startsAt: '2024-11-13T00:00:00',
          endsAt: '2024-11-13T00:00:00',
          type: 'Day',
          identifier: '2024Nov13',
        },
        {
          startsAt: '2024-11-14T00:00:00',
          endsAt: '2024-11-14T00:00:00',
          type: 'Day',
          identifier: '2024Nov14',
        },
        {
          startsAt: '2024-11-15T00:00:00',
          endsAt: '2024-11-15T00:00:00',
          type: 'Day',
          identifier: '2024Nov15',
        },
        {
          startsAt: '2024-11-16T00:00:00',
          endsAt: '2024-11-16T00:00:00',
          type: 'Day',
          identifier: '2024Nov16',
        },
        {
          startsAt: '2024-11-17T00:00:00',
          endsAt: '2024-11-17T00:00:00',
          type: 'Day',
          identifier: '2024Nov17',
        },
      ],
      children: getChildren(skip),
    },
    links: [
      {
        href: 'http://uat2.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyemployee?periodstartsat=2024-10-22&periodendsat=2024-11-17&periodtypes=day&unittypes=hours&reportingtypes=availability&showtaskno=true&firstload=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 1867581552,
  StatusCode: 200,
});
