import { useQuery } from '@tanstack/react-query';
import { ITlpCountResponse } from 'src/apis/types/resourcePlannerAPI';
import { getFetch } from 'src/utils/fetcher';
import { TLP_PLAN_COUNT_KEY } from '../constants';

const TLP_PLAN_COUNT_URL_PATH = '/api/v2/resource-planner/tlp-plan-count';

const resourcePlannerFetch = (): Promise<ITlpCountResponse> =>
  getFetch({ path: TLP_PLAN_COUNT_URL_PATH, key: TLP_PLAN_COUNT_KEY });

export const useGetTlpPlanCount = (enabled: boolean) => {
  const { data, ...restProps } = useQuery({
    enabled,
    queryFn: resourcePlannerFetch,
    queryKey: [TLP_PLAN_COUNT_KEY],
  });

  return {
    count: data?.properties.count ?? 0,
    ...restProps,
  };
};
