// To test infinite scrolling, change `take` in `ResourceTableGroupedByProject.constants.ts` to 10
const getChildren = (skip: number) => {
  switch (skip) {
    case 0:
      return [
        {
          StartsAt: '2023-01-10T00:00:00',
          EndsAt: '2023-03-15T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843773288022017',
          workItemSourceReferenceId: '11',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843773288022017',
          name: '(E) _Administration',
          sourceReferenceId: '11',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/_Administration',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2024-01-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843717868683265',
          workItemSourceReferenceId: '12',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843717868683265',
          name: '_Sales work',
          sourceReferenceId: '12',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/_Sales work',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-10-01T00:00:00',
          EndsAt: '2021-12-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844215308943361',
          workItemSourceReferenceId: '64',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844215308943361',
          name: '_TIMJenm70007EUR',
          sourceReferenceId: '64',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/_TIMJenm70007EUR',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-11-02T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969505951745',
          workItemSourceReferenceId: '78',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969505951745',
          name: '(E) Anson has a Tree',
          sourceReferenceId: '78',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Anson has a Tree',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2025-08-06T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843753071476737',
          workItemSourceReferenceId: '13',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843753071476737',
          name: 'BAD - Rådgivning EU godkendelse (TMSA DKK)',
          sourceReferenceId: '13',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/BAD - Rådgivning EU godkendelse (TMSA DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-09-01T00:00:00',
          EndsAt: '2021-08-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843943031504897',
          workItemSourceReferenceId: '15',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843943031504897',
          name: 'BIQ - Markedsovervågning (FPCS DKK)',
          sourceReferenceId: '15',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/BIQ - Markedsovervågning (FPCS DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-09-15T00:00:00',
          EndsAt: '2021-12-15T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844122916814849',
          workItemSourceReferenceId: '65',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844122916814849',
          name: 'Business Central Implementering',
          sourceReferenceId: '65',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Business Central Implementering',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-10T00:00:00',
          EndsAt: '2023-04-04T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844488341356545',
          workItemSourceReferenceId: '68',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844488341356545',
          name: 'Business Central implementering CIS',
          sourceReferenceId: '68',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Business Central implementering CIS',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-10-22T00:00:00',
          EndsAt: '2022-01-14T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844168500510721',
          workItemSourceReferenceId: '67',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844168500510721',
          name: 'Business Central Implementering CPH',
          sourceReferenceId: '67',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Business Central Implementering CPH',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-10T00:00:00',
          EndsAt: '2023-08-09T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843870646206465',
          workItemSourceReferenceId: '16',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843870646206465',
          name: 'DAN - Ny administrationsplatform (FPSA DKK)',
          sourceReferenceId: '16',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/DAN - Ny administrationsplatform (FPSA DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case 10:
      return [
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-11-20T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850383523841',
          workItemSourceReferenceId: '17',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850383523841',
          name: '(E) ELL - Ny borgerløsning til skat (FPSA DKK)',
          sourceReferenceId: '17',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-09-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843956151287809',
          workItemSourceReferenceId: '14',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843956151287809',
          name: 'FRE - Advisory on EU approval (TMSA SEK)',
          sourceReferenceId: '14',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/FRE - Advisory on EU approval (TMSA SEK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2019-01-01T00:00:00',
          EndsAt: '2020-10-11T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844557618675713',
          workItemSourceReferenceId: '22',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844557618675713',
          name: 'FRE - Lobby Work EU',
          sourceReferenceId: '22',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/FRE - Lobby Work EU',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-02-20T00:00:00',
          EndsAt: '2021-05-20T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844293322997761',
          workItemSourceReferenceId: '59',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844293322997761',
          name: 'IMO project',
          sourceReferenceId: '59',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/IMO project',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-09-15T00:00:00',
          EndsAt: '2022-07-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844049369694209',
          workItemSourceReferenceId: '66',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844049369694209',
          name: 'New Website for Odense',
          sourceReferenceId: '66',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/New Website for Odense',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-09-01T00:00:00',
          EndsAt: '2020-12-02T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843809644249089',
          workItemSourceReferenceId: '18',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843809644249089',
          name: 'OSS - Klippekort 1000 timer (DKK)',
          sourceReferenceId: '18',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/OSS - Klippekort 1000 timer (DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-09T00:00:00',
          EndsAt: '2023-01-30T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843996987031553',
          workItemSourceReferenceId: '79',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843996987031553',
          name: 'Project 1',
          sourceReferenceId: '79',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Project 1',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-03-16T00:00:00',
          EndsAt: '2022-06-16T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844030608572417',
          workItemSourceReferenceId: '75',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844030608572417',
          name: 'Project for 30 days',
          sourceReferenceId: '75',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Project for 30 days',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-06-30T00:00:00',
          EndsAt: '2022-11-29T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844112544301057',
          workItemSourceReferenceId: '77',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844112544301057',
          name: 'Resource Planner Implementation',
          sourceReferenceId: '77',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Resource Planner Implementation',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-10-06T00:00:00',
          EndsAt: '2022-01-06T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844110426177537',
          workItemSourceReferenceId: '73',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844110426177537',
          name: 'RP template test',
          sourceReferenceId: '73',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/RP template test',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case 20:
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089588875265',
          workItemSourceReferenceId: '74',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089588875265',
          name: '(E) Simple test 1',
          sourceReferenceId: '74',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Simple test 1',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2024-05-17T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924249411585',
          workItemSourceReferenceId: '19',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924249411585',
          name: '(E) SWE - National Survey Board (MTEB EUR)',
          sourceReferenceId: '19',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2024-01-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843911490338817',
          workItemSourceReferenceId: '20',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843911490338817',
          name: 'SYN - Lønbehandling Outsourcing (FPVI DKK)',
          sourceReferenceId: '20',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/SYN - Lønbehandling Outsourcing (FPVI DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-02-26T00:00:00',
          EndsAt: '2021-10-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844298641375233',
          workItemSourceReferenceId: '58',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844298641375233',
          name: 'TCC0209-R1',
          sourceReferenceId: '58',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC0209-R1',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-11T00:00:00',
          EndsAt: '2024-04-10T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844280823971841',
          workItemSourceReferenceId: '60',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844280823971841',
          name: 'TCC0222-R1A',
          sourceReferenceId: '60',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC0222-R1A',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2021-05-31T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844430573207553',
          workItemSourceReferenceId: '50',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844430573207553',
          name: 'TCC1204',
          sourceReferenceId: '50',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2021-03-04T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844407814914049',
          workItemSourceReferenceId: '51',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844407814914049',
          name: 'TCC1204-Default FPSA',
          sourceReferenceId: '51',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204-Default FPSA',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844455835500545',
          workItemSourceReferenceId: '49',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844455835500545',
          name: 'TCC1204-DKK',
          sourceReferenceId: '49',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204-DKK',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844523653201921',
          workItemSourceReferenceId: '48',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844523653201921',
          name: 'TCC1204-EUR',
          sourceReferenceId: '48',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204-EUR',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2022-01-01T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844310226042881',
          workItemSourceReferenceId: '55',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844310226042881',
          name: 'TCC1204-existing project',
          sourceReferenceId: '55',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204-existing project',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case 30:
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343725948929',
          workItemSourceReferenceId: '54',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343725948929',
          name: '(E) TCC1204-quick create',
          sourceReferenceId: '54',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204-quick create',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844375627825153',
          workItemSourceReferenceId: '53',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844375627825153',
          name: 'TCC1204-template',
          sourceReferenceId: '53',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC1204-template',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-11T00:00:00',
          EndsAt: '2023-10-02T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844239212281857',
          workItemSourceReferenceId: '61',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844239212281857',
          name: 'TCC311',
          sourceReferenceId: '61',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/TCC311',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-05-11T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843828988379137',
          workItemSourceReferenceId: '21',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843828988379137',
          name: 'VEN - Ledelsesudvikling (TMSA DKK)',
          sourceReferenceId: '21',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/VEN - Ledelsesudvikling (TMSA DKK)',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'Quote',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-05-27T00:00:00',
          EndsAt: '2022-06-30T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844161860927489',
          workItemSourceReferenceId: '71',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844161860927489',
          name: 'Z - Project Without Task',
          sourceReferenceId: '71',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Z - Project Without Task',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-03-16T00:00:00',
          EndsAt: '2022-06-30T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844041731866625',
          workItemSourceReferenceId: '76',
          workItemType: 'Project',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844041731866625',
          name: 'Z - Test 30 days - 2',
          sourceReferenceId: '76',
          tenantId: '10002',
          type: 'Project',
          pathToParent: '/',
          sortOrder: 0,
          level: 1,
          sortablePath: '/Z - Test 30 days - 2',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    default:
      return [];
  }
};

export default (skip: number) => ({
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'GroupByWorkItem',
      viewOptions: {
        'grouped-by': 'group-by-work-item',
        'reporting-types': 'availability',
        'period-types': 'day',
        'unit-types': 'hours',
        'period-starts-at': '2024-10-18',
        'period-ends-at': '2024-11-10',
      },
      periods: [
        {
          startsAt: '2024-10-18T00:00:00',
          endsAt: '2024-10-18T00:00:00',
          type: 'Day',
          identifier: '2024Oct18',
        },
        {
          startsAt: '2024-10-19T00:00:00',
          endsAt: '2024-10-19T00:00:00',
          type: 'Day',
          identifier: '2024Oct19',
        },
        {
          startsAt: '2024-10-20T00:00:00',
          endsAt: '2024-10-20T00:00:00',
          type: 'Day',
          identifier: '2024Oct20',
        },
        {
          startsAt: '2024-10-21T00:00:00',
          endsAt: '2024-10-21T00:00:00',
          type: 'Day',
          identifier: '2024Oct21',
        },
        {
          startsAt: '2024-10-22T00:00:00',
          endsAt: '2024-10-22T00:00:00',
          type: 'Day',
          identifier: '2024Oct22',
        },
        {
          startsAt: '2024-10-23T00:00:00',
          endsAt: '2024-10-23T00:00:00',
          type: 'Day',
          identifier: '2024Oct23',
        },
        {
          startsAt: '2024-10-24T00:00:00',
          endsAt: '2024-10-24T00:00:00',
          type: 'Day',
          identifier: '2024Oct24',
        },
        {
          startsAt: '2024-10-25T00:00:00',
          endsAt: '2024-10-25T00:00:00',
          type: 'Day',
          identifier: '2024Oct25',
        },
        {
          startsAt: '2024-10-26T00:00:00',
          endsAt: '2024-10-26T00:00:00',
          type: 'Day',
          identifier: '2024Oct26',
        },
        {
          startsAt: '2024-10-27T00:00:00',
          endsAt: '2024-10-27T00:00:00',
          type: 'Day',
          identifier: '2024Oct27',
        },
        {
          startsAt: '2024-10-28T00:00:00',
          endsAt: '2024-10-28T00:00:00',
          type: 'Day',
          identifier: '2024Oct28',
        },
        {
          startsAt: '2024-10-29T00:00:00',
          endsAt: '2024-10-29T00:00:00',
          type: 'Day',
          identifier: '2024Oct29',
        },
        {
          startsAt: '2024-10-30T00:00:00',
          endsAt: '2024-10-30T00:00:00',
          type: 'Day',
          identifier: '2024Oct30',
        },
        {
          startsAt: '2024-10-31T00:00:00',
          endsAt: '2024-10-31T00:00:00',
          type: 'Day',
          identifier: '2024Oct31',
        },
        {
          startsAt: '2024-11-01T00:00:00',
          endsAt: '2024-11-01T00:00:00',
          type: 'Day',
          identifier: '2024Nov01',
        },
        {
          startsAt: '2024-11-02T00:00:00',
          endsAt: '2024-11-02T00:00:00',
          type: 'Day',
          identifier: '2024Nov02',
        },
        {
          startsAt: '2024-11-03T00:00:00',
          endsAt: '2024-11-03T00:00:00',
          type: 'Day',
          identifier: '2024Nov03',
        },
        {
          startsAt: '2024-11-04T00:00:00',
          endsAt: '2024-11-04T00:00:00',
          type: 'Day',
          identifier: '2024Nov04',
        },
        {
          startsAt: '2024-11-05T00:00:00',
          endsAt: '2024-11-05T00:00:00',
          type: 'Day',
          identifier: '2024Nov05',
        },
        {
          startsAt: '2024-11-06T00:00:00',
          endsAt: '2024-11-06T00:00:00',
          type: 'Day',
          identifier: '2024Nov06',
        },
        {
          startsAt: '2024-11-07T00:00:00',
          endsAt: '2024-11-07T00:00:00',
          type: 'Day',
          identifier: '2024Nov07',
        },
        {
          startsAt: '2024-11-08T00:00:00',
          endsAt: '2024-11-08T00:00:00',
          type: 'Day',
          identifier: '2024Nov08',
        },
        {
          startsAt: '2024-11-09T00:00:00',
          endsAt: '2024-11-09T00:00:00',
          type: 'Day',
          identifier: '2024Nov09',
        },
        {
          startsAt: '2024-11-10T00:00:00',
          endsAt: '2024-11-10T00:00:00',
          type: 'Day',
          identifier: '2024Nov10',
        },
      ],
      children: getChildren(skip),
    },
    links: [
      {
        href: 'http://uat2.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyworkitem?periodstartsat=2024-10-18&periodendsat=2024-11-10&periodtypes=day&unittypes=hours&reportingtypes=availability&showtaskno=true&firstload=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 1809017086,
  StatusCode: 200,
});
