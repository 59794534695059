import { Box, ListItemButton, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { KeyboardEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  IBulkAssignReq,
  useGetCapacity,
  useGetEmployeeAllocation,
  useGetUnallocatedEmployees,
  usePostBulkAssign,
} from 'src/apis/resourcePlannerAPI';
import elements from 'src/assets/styles/variables/colors/global/elements.module.scss';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  ToastifyAlert,
  Typography,
} from 'src/components/mui-components';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { eventKeyEnter } from 'src/constants/keyboardKey';
import { useAssignFlowStore } from 'src/stores/ResourcePlannerStore/AssignFlowStore';
import { getApiDateFormat } from 'src/utils/date/date';
import { formatNumber } from 'src/utils/number';
import { convertObjectValueToNumber } from '../../helpers';
import { useGetDefaultDates } from '../../hooks';
import { AvailabilityDateRangePicker } from '../AvailabilityDateRangePicker';
import { EmployeeCard } from '../EmployeeCard';
import { HourlyRateAutocomplete } from '../HourlyRateAutocomplete';
import { IHourlyRateItem } from '../HourlyRateAutocomplete/HourlyRateAutocomplete.types';
import { HoursInput, IHours } from '../HoursInput';
import styles from './AssignDialog.module.scss';
import { AssignButtons } from './components/AssignButtons';

interface IAssignDialog {
  children: ReactNode;
  dates: DateRange<Date>;
  from: string;
  projectId: string;
  selected: boolean;
  taskId: string;
  to: string;
  userId: string;
  showDepartmentFilter?: boolean;
  showLegalEntityFilter?: boolean;
}

export const AssignDialog = ({
  children,
  dates: [startsAt, endsAt],
  from,
  projectId,
  selected,
  showDepartmentFilter,
  showLegalEntityFilter,
  taskId,
  to,
  userId,
}: IAssignDialog) => {
  const siteLocale = useGetLocale();
  const { t } = useTranslation('assignFlow');
  const { isWorkItem, resourceSourceReferenceId } = useAssignFlowStore();
  const [defaultStartDate, defaultEndDate] = useGetDefaultDates([startsAt, endsAt]);

  const allocatedHoursInputRef = useRef<HTMLInputElement | null>(null);
  const plannedHoursInputRef = useRef<HTMLInputElement | null>(null);

  const [open, setOpen] = useState(false);
  const [allocatedHours, setAllocatedHours] = useState<IHours>({
    hours: '0',
    hoursInt: 0,
    hoursStr: '0',
  });
  const [allocatedHoursError, setAllocatedHoursError] = useState('');
  const [plannedHours, setPlannedHours] = useState<IHours>({
    hours: '',
    hoursInt: 0,
    hoursStr: '',
  });
  const [plannedHoursError, setPlannedHoursError] = useState('');
  const [date, setDate] = useState<DateRange<Date>>([defaultStartDate, defaultEndDate]);
  const [dateError, setDateError] = useState(false);
  const [hourlyRateId, setHourlyRateId] = useState<IHourlyRateItem>({ label: '', value: '' });

  const [startDate, endDate] = date;

  // Capacity for HoursBar
  const {
    data: capacity,
    isError: capacityIsError,
    isFetching: capacityIsFetching,
  } = useGetCapacity({ endDate, startDate, userId }, open);

  // Checks if employee has existing allocation
  // Comes with hourly rate info
  const {
    data: employeeAllocation,
    isError: employeeAllocationIsError,
    isFetching: employeeAllocationIsFetching,
  } = useGetEmployeeAllocation({ projectId, taskId, userId }, open);

  // If none, get suggested hourly rate here
  const {
    data: unallocatedEmployees,
    isError: unallocatedEmployeesIsError,
    isFetching: unallocatedEmployeesIsFetching,
  } = useGetUnallocatedEmployees({ projectId, taskId, userId }, open);

  const resetAllocationHoursToZero = () => {
    setAllocatedHours({
      hours: '0',
      hoursInt: 0,
      hoursStr: '0',
    });
    setAllocatedHoursError('');
  };

  const cleanUp = () => {
    resetAllocationHoursToZero();
    setPlannedHours({
      hours: '',
      hoursInt: 0,
      hoursStr: '',
    });
    setPlannedHoursError('');
    setDate([defaultStartDate, defaultEndDate]);
    setDateError(false);
    setHourlyRateId({
      label: `${unallocatedEmployees?.suggestedHourlyRateAmount} ${unallocatedEmployees?.suggestedHourlyRateName}`,
      value: unallocatedEmployees?.suggestedHourlyRateId ?? '',
    });
  };

  const dialogOnClose = () => {
    setOpen(false);
    cleanUp();
  };

  const assignOnSuccess = () => {
    dialogOnClose();

    const toastDescKey = isWorkItem
      ? 'Toast.AssignTaskToEmployeeSuccess'
      : 'Toast.AssignEmployeeToTaskSuccess';
    toast.success(
      <ToastifyAlert
        title={
          isWorkItem
            ? t('Toast.AssignTaskToEmployeeSuccessTitle')
            : t('Toast.AssignEmployeeToTaskSuccessTitle')
        }
        description={
          <Trans
            i18nKey={toastDescKey}
            key={toastDescKey}
            ns="assignFlow"
            defaults={t(toastDescKey)}
            values={
              isWorkItem
                ? { task: from, employee: capacity?.fullName ?? to }
                : { employee: capacity?.fullName ?? from, task: to }
            }
            components={[
              <span key={0} style={{ fontWeight: 500 }} />,
              <span key={1} style={{ fontWeight: 500 }} />,
            ]}
          />
        }
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  const { mutate: postBulkAssign, isLoading } = usePostBulkAssign(assignOnSuccess);

  const bulkAssign = () => {
    if (!startDate || !endDate) {
      return;
    }
    const assignReqBody: IBulkAssignReq[] = [
      {
        budgetHours: allocatedHours.hoursInt,
        distributionEndsAt: getApiDateFormat(endDate),
        distributionStartsAt: getApiDateFormat(startDate),
        hours: plannedHours ? plannedHours.hoursInt : null,
        ...convertObjectValueToNumber({
          hourlyRateId: hourlyRateId.value,
          projectId,
          resourceSourceReferenceId: userId,
          workItemSourceReferenceId: taskId,
        }),
      },
    ];
    postBulkAssign(assignReqBody);
  };

  const inputOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === eventKeyEnter) {
      bulkAssign();
    }
  };

  const allocationInfoTemplate = (() => {
    if (!employeeAllocation) {
      return (
        <Stack gap={1.5}>
          <Stack gap={0.25}>
            <Typography fontWeight={600} component="h3">
              {t('NewAllocationInformationHeader')}
            </Typography>
            <Typography variant="small">
              <Trans
                i18nKey="NewAllocationInformationText"
                key="NewAllocationInformationText"
                defaults={t('NewAllocationInformationText')}
              />
            </Typography>
          </Stack>
          <Stack gap={2}>
            <HoursInput
              clearAction={resetAllocationHoursToZero}
              error={allocatedHoursError}
              inputRef={allocatedHoursInputRef}
              label={t('AllocatedHoursInputLabel')}
              onBlur={() => {
                if (allocatedHours) {
                  return;
                }
                resetAllocationHoursToZero();
              }}
              onChange={setAllocatedHours}
              onKeyDown={inputOnKeyDown}
              required
              setError={setAllocatedHoursError}
              value={allocatedHours.hours}
            />
            <HourlyRateAutocomplete
              dialogIsOpen={open}
              onChange={setHourlyRateId}
              suggestedHourlyRateId={unallocatedEmployees?.suggestedHourlyRateId}
              taskId={taskId}
              value={hourlyRateId}
            />
          </Stack>
        </Stack>
      );
    }

    const remainingHours =
      Number(employeeAllocation.allocatedHours) - Number(employeeAllocation.registeredHours);
    const hourlyRateLabel = `${formatNumber(employeeAllocation.hourlyRateAmount, siteLocale, {
      min: 0,
      max: 0,
    })} ${employeeAllocation.hourlyRateCurrencyAbb ?? ''}`;

    return (
      <Stack alignItems="flex-start" data-automation-id="ExistingAllocationTable">
        <Typography fontWeight={600} variant="h4">
          {t('ExistingAllocationTable.Header')}
        </Typography>
        <Table className={styles.existingAllocationGrid} size="small">
          <TableBody>
            <TableRow>
              <TableCell>{t('ExistingAllocationTable.AllocatedHours')}:</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {formatNumber(employeeAllocation.allocatedHours, siteLocale)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('ExistingAllocationTable.RegisteredHours')}:</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {formatNumber(employeeAllocation.registeredHours, siteLocale)}
              </TableCell>
            </TableRow>
            <TableRow sx={{ borderTop: '1px solid', pt: 2 }}>
              <TableCell>{t('ExistingAllocationTable.RemainingHours')}:</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {formatNumber(remainingHours, siteLocale)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ pt: 1.5 }}>{t('ExistingAllocationTable.HourlyRate')}:</TableCell>
              <TableCell sx={{ pt: 1.5 }}>{hourlyRateLabel}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    );
  })();

  const copyHourButtonsTemplate = (() => {
    if (!employeeAllocation) {
      return (
        <Button
          data-automation-id="CopyAllocatedHoursButton"
          onClick={() => setPlannedHours(allocatedHours)}
          size="small"
        >
          {t('CopyAllocatedHoursButton')}
        </Button>
      );
    }

    if (!employeeAllocation) {
      return null;
    }

    const remainingHours = String(
      Number(employeeAllocation.allocatedHours) - Number(employeeAllocation.registeredHours),
    );

    return (
      <Button
        data-automation-id="CopyRemainingHoursButton"
        onClick={() => {
          // Set hours only and trigger change inside HoursInput
          setPlannedHours((prev) => ({
            ...prev,
            hours: formatNumber(remainingHours, siteLocale),
          }));
        }}
        size="small"
      >
        {t('CopyRemainingHoursButton')}
      </Button>
    );
  })();

  useEffect(() => {
    if (employeeAllocationIsFetching) {
      return;
    }
    if (!employeeAllocation) {
      allocatedHoursInputRef.current?.select();
      return;
    }
    plannedHoursInputRef.current?.select();
  }, [employeeAllocation, employeeAllocationIsFetching]);

  useEffect(() => {
    if (unallocatedEmployeesIsFetching || !unallocatedEmployees) {
      return;
    }
    setHourlyRateId({
      label: `${unallocatedEmployees.suggestedHourlyRateAmount} ${unallocatedEmployees.suggestedHourlyRateName}`,
      value: unallocatedEmployees.suggestedHourlyRateId,
    });
  }, [unallocatedEmployees, unallocatedEmployeesIsFetching]);

  return (
    <>
      <Stack
        className={selected || isLoading ? styles.ariaSelected : ''}
        sx={{ flex: 1, position: 'relative' }}
      >
        <ListItemButton
          className={styles.listItemButton}
          onClick={() => setOpen(true)}
          role="button"
        >
          {children}
        </ListItemButton>
        {!open ? (
          <Stack className={styles.actionButtons}>
            <AssignButtons
              bulkAssign={bulkAssign}
              isLoading={isLoading}
              setOpen={setOpen}
              id={`${userId}-${taskId}`}
            />
          </Stack>
        ) : null}
      </Stack>
      <Dialog
        data-automation-id="AssignDialog"
        fullWidth
        maxWidth="xs"
        onClose={dialogOnClose}
        open={open}
        PaperProps={{ sx: { maxHeight: 'calc(100% - 128px)', mt: 8 } }}
      >
        <DialogTitle data-automation-id="AssignDialogTitle">{t('AssignAndPlan')}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Box
              sx={{ border: `1px solid ${elements.colorBorder}`, borderRadius: 1, p: 1, pr: 1.5 }}
            >
              <ResponseHandler
                isError={capacityIsError}
                isLoading={capacityIsFetching}
                LoadingComponent={<CircularProgress />}
              >
                {capacity ? (
                  <EmployeeCard
                    addedHours={plannedHours.hoursInt}
                    capacity={capacity.capacity}
                    department={capacity.department}
                    fullName={capacity.fullName}
                    initials={capacity.initials}
                    legalEntity={capacity.legalEntity}
                    position={capacity.position}
                    showDepartmentFilter={showDepartmentFilter}
                    showLegalEntityFilter={showLegalEntityFilter}
                    userId={resourceSourceReferenceId}
                  />
                ) : null}
              </ResponseHandler>
            </Box>
            <ResponseHandler
              isError={employeeAllocationIsError || unallocatedEmployeesIsError}
              isLoading={employeeAllocationIsFetching || unallocatedEmployeesIsFetching}
              LoadingComponent={<CircularProgress />}
            >
              {allocationInfoTemplate}
            </ResponseHandler>
            <Stack gap={1.5}>
              <Typography fontWeight={600} component="h3">
                {t('PlanWorkInRP')}
              </Typography>
              <Stack gap={2}>
                <Stack alignItems="center" direction="row" gap={2.25}>
                  <HoursInput
                    error={plannedHoursError}
                    inputRef={plannedHoursInputRef}
                    label={t('PlannedHoursInputLabel')}
                    onChange={setPlannedHours}
                    onKeyDown={inputOnKeyDown}
                    setError={setPlannedHoursError}
                    value={plannedHours.hours}
                  />
                  {copyHourButtonsTemplate}
                </Stack>
                <AvailabilityDateRangePicker
                  onError={setDateError}
                  onChange={setDate}
                  onKeyDown={inputOnKeyDown}
                  required
                  value={date}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            data-automation-id="CancelButton"
            disabled={isLoading}
            onClick={dialogOnClose}
            variant="outlined"
          >
            {t('CancelButtonText')}
          </Button>
          <LoadingButton
            data-automation-id="AssignButton"
            disabled={isLoading || dateError}
            isLoading={isLoading}
            onClick={bulkAssign}
            variant="contained"
          >
            {t('AssignSubmitButton')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
