import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
} from 'src/components/mui-components';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import {
  useFilterDispatch,
  useFilterStore,
  useGetFilterValuesById,
  useGetParentQueryString,
  useMatchSelectedFilterKey,
} from 'src/stores/FilterStore';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { DataAutomationId } from 'src/utils/ts-utils';
import { useDebounce } from 'use-debounce';
import { IChildInputComponent, IOnChangeInput } from './InputContainerTypes';
import { FilterFeedback } from './components/FilterFeedback';
import { FilterInput } from './components/FilterInput';
import { useOnChangeHandler } from './helpers';

interface IChildInputComponentExtended extends IChildInputComponent, DataAutomationId {}

export const InputContainer = ({
  contentUrl,
  filterId,
  fieldLabel,
  fieldPlaceholder,
  childFilters = [],
  parentFilters = [],
  type,
  ...restProps
}: IChildInputComponentExtended) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 250);
  const dispatch = useFilterDispatch();
  const { selectedFilterList } = useFilterStore();
  const { t } = useTranslation('filter');

  const [isInclude, setIsInclude] = useState<string>(
    selectedFilterList[fieldLabel]?.isInclude === false ? 'is-not' : 'is',
  );

  const pageIdentifier = useGetCurrentPageIdentifier();
  const defaultValues = useGetFilterValuesById(fieldLabel);
  const matchChild = useMatchSelectedFilterKey(childFilters);
  const queryString = useGetParentQueryString(parentFilters);

  const { dropdownList, isError, isFetching, isLoading } = useGetDropdownWithSearchAPI(
    {
      key: `filterInput${filterId}`,
      MSWKey: `FILTER_INPUT_${filterId}`,
      path: `/${contentUrl}?pageIdentifier=${pageIdentifier}${queryString}`,
      searchText: debouncedInputValue,
    },
    !!contentUrl,
  );

  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsInclude(e.target.value);

    if (dispatch && selectedFilterList[fieldLabel]) {
      dispatch({
        type: 'ADD_OR_UPDATE_FILTER',
        payload: {
          [fieldLabel]: {
            label: fieldLabel,
            values: selectedFilterList[fieldLabel].values.map((filter) => ({
              label: filter.label,
              value: filter.value,
            })),
            contentUrl,
            childFilters,
            parentFilters,
            isInclude: isInclude === 'is-not',
            type,
          },
        },
      });

      matchChild?.forEach((filterID) => {
        dispatch({ type: 'RESET_FILTER_ID', payload: { filterID } });
      });
    }
  };

  const [listValue, setListValue] = useState<Array<IOnChangeInput> | null>(
    (defaultValues as Array<IOnChangeInput>) || [],
  );

  const onChangeHandler = useOnChangeHandler({
    contentUrl,
    fieldLabel,
    childFilters,
    parentFilters,
    isInclude: isInclude === 'is',
    setListValue,
    type,
  });

  return (
    <ResponseHandler isError={isError}>
      <Paper sx={{ padding: 1 }} elevation={4}>
        <Stack gap={2}>
          <Stack>
            {type === 'MultiSelectTypeSearch' && (
              <FormControl>
                <RadioGroup
                  row
                  name={`IsOrIsNot${filterId}Radio`}
                  value={isInclude}
                  onChange={onRadioChange}
                  sx={{ gap: 2, paddingInline: 2 }}
                >
                  <FormControlLabel
                    value="is"
                    control={<Radio />}
                    label={t('DropDownRadioIsLabel')}
                  />
                  <FormControlLabel
                    value="is-not"
                    control={<Radio />}
                    label={t('DropDownRadioIsNotLabel')}
                  />
                </RadioGroup>
              </FormControl>
            )}
            <FilterInput
              filterId={filterId}
              fieldLabel={fieldLabel}
              placeholder={fieldPlaceholder}
              addFilterItem={() => {}}
              options={dropdownList}
              data-automation-id={restProps['data-automation-id']}
              openOnFocus={matchChild.length <= 0}
              inputValue={inputValue}
              onInputChange={(_: ChangeEvent<HTMLInputElement>, newInputValue: string) =>
                setInputValue(newInputValue)
              }
              loading={isFetching || isLoading}
              isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
              value={type === 'MultiSelectTypeSearch' ? listValue : listValue?.[0] ?? null}
              label={translationAnyText(t, `FilterInputName${stringToPascal(fieldLabel)}`)}
              filterSelectedOptions
              onChange={onChangeHandler}
              type={type}
              autoHighlight
            />
          </Stack>

          <FilterFeedback matchChildren={matchChild} />
        </Stack>
      </Paper>
    </ResponseHandler>
  );
};
