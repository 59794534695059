import { format, parse } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { useGetMigrationStatus, useGetResourceViewOptions } from 'src/apis/resourcePlannerAPI';
import { useGetSavedView } from 'src/apis/savedViewAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import { ViewOptionsChangeParameters } from 'src/components/layout/FilterPanelLayout/types';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { ResourcePlannerStore } from 'src/stores/ResourcePlannerStore';
import { InitialSetup } from './components/InitialSetup';
import { PeriodPicker } from './components/PeriodPicker';
import { ResourcePlannerViewOptions } from './components/ResourcePlannerViewOptions/ResourcePlannerViewOptions';
import { ResourceTableGroupedByEmployee } from './components/ResourceTableGroupedByEmployee';
import { ResourceTableGroupedByProject } from './components/ResourceTableGroupedByProject';
import { getDateRange } from './helper/getDateRange';
import { useSignalRConnection } from './hooks';
import { TViewOptionKey, TViewOptions, ViewType } from './types/resourcePlanner';

export const ResourcePlanner = () => {
  useSignalRConnection();

  const {
    isResourceMigrated,
    isProjectMigrated,
    isResourcePlanMigrated,
    isError: isMigratedError,
    isLoading: isMigratedLoading,
    isFetched: isMigratedFetched,
  } = useGetMigrationStatus();

  const {
    filterList,
    isError: filterIsError,
    isFetching: filterIsFetching,
  } = useGetFilterAPI('NewResourcePlanner');
  const {
    data,
    isError: savedViewIsError,
    isFetching: savedViewIsFetching,
    isRefetching: savedViewIsRefetching,
  } = useGetSavedView('NewResourcePlanner');

  const [activeViewId, setActiveViewId] = useState('');
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions>();

  const savedView = useMemo(() => data || [], [data]);
  const activeView = useMemo(
    () => savedView.find((v) => v.filterViewId === activeViewId),
    [activeViewId, savedView],
  );
  const viewOptions = useMemo(() => activeView?.viewOptions, [activeView?.viewOptions]);
  const dateRange = useMemo(
    () => activeView?.viewOptions?.['date-range'],
    [activeView?.viewOptions],
  );

  const [preDefinedPeriod, setPreDefinedPeriod] = useState('');
  const [initialDateEnd, setInitialDateEnd] = useState(new Date());
  const [initialDateStart, setInitialDateStart] = useState(new Date());

  const { fields: initialFields } = useGetResourceViewOptions();
  const fields = useMemo(
    () =>
      initialFields.map((field) => ({
        ...field,
        value: changedViewOptions?.[field.name as TViewOptionKey] ?? field.value,
        options: field.options.map((option) => ({
          ...option,
        })),
      })),
    [changedViewOptions, initialFields],
  );
  const groupedBy = useMemo(
    () => fields.find(({ name }) => name === 'grouped-by')?.value as ViewType,
    [fields],
  );
  const selectedViewOptions = useMemo(
    () =>
      fields.reduce<TViewOptions>(
        (acc, { name, value }) => {
          if (value) {
            acc[name as keyof TViewOptions] = value;
          }
          return acc;
        },
        {
          'period-starts-at': format(initialDateStart, 'yyyy-MM-dd'),
          'period-ends-at': format(initialDateEnd, 'yyyy-MM-dd'),
        },
      ),
    [fields, initialDateEnd, initialDateStart],
  );

  const onViewOptionsChange = (items: ViewOptionsChangeParameters[]) => {
    const options = items.map((item) => {
      if (item.name === 'period-starts-at') {
        setInitialDateStart(parse(item.value, 'yyyy-MM-dd', new Date()));
      }
      if (item.name === 'period-ends-at') {
        setInitialDateEnd(parse(item.value, 'yyyy-MM-dd', new Date()));
      }
      return { [item.name]: item.value };
    });
    const optionsToObject = Object.assign({}, ...options);
    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const setViewOptions = useCallback(() => {
    setChangedViewOptions({
      ...viewOptions,
      'period-starts-at': format(initialDateStart, 'yyyy-MM-dd'),
      'period-ends-at': format(initialDateEnd, 'yyyy-MM-dd'),
    });
  }, [initialDateEnd, initialDateStart, viewOptions]);

  useEffect(() => {
    const { startDate, endDate, predefinedPeriod } = getDateRange(dateRange);
    setInitialDateStart(new Date(startDate));
    setInitialDateEnd(new Date(endDate));
    setPreDefinedPeriod(predefinedPeriod);
  }, [dateRange]);

  useEffect(() => {
    setChangedViewOptions({
      ...viewOptions,
      'period-starts-at': format(initialDateStart, 'yyyy-MM-dd'),
      'period-ends-at': format(initialDateEnd, 'yyyy-MM-dd'),
    });
  }, [activeViewId, initialDateEnd, initialDateStart, viewOptions]);

  if (!isMigratedFetched) {
    return null;
  }

  if (!isResourceMigrated || !isProjectMigrated || !isResourcePlanMigrated) {
    return (
      <ResponseHandler
        isEmpty={filterList.length <= 0}
        isError={isMigratedError}
        isLoading={isMigratedLoading}
      >
        <InitialSetup />
      </ResponseHandler>
    );
  }

  return (
    <ResponseHandler
      isEmpty={filterList.length <= 0}
      isError={filterIsError || savedViewIsError}
      isLoading={filterIsFetching || (savedViewIsFetching && !savedViewIsRefetching)}
    >
      <FilterPanelLayout
        activeView={activeViewId}
        changedViewOptions={changedViewOptions}
        filterList={filterList}
        preDefinedPeriod={preDefinedPeriod}
        savedViewsList={savedView}
        setActiveView={setActiveViewId}
        setViewOptions={setViewOptions}
      >
        <ResourcePlannerStore
          value={{
            dateEnd: initialDateEnd,
            filterList,
            selectedViewOptions,
          }}
        >
          <ResourcePlannerViewOptions
            periodPicker={
              <PeriodPicker
                initialDateEnd={initialDateEnd}
                initialDateStart={initialDateStart}
                onChange={onViewOptionsChange}
                setPreDefinedPeriod={setPreDefinedPeriod}
              />
            }
            viewOptionsChange={onViewOptionsChange}
            viewOptionsFields={fields}
          />
          {groupedBy === 'group-by-resource' && <ResourceTableGroupedByEmployee />}
          {groupedBy === 'group-by-work-item' && <ResourceTableGroupedByProject />}
        </ResourcePlannerStore>
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
