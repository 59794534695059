import { ReactNode } from 'react';
import {
  IFilterCategory,
  IFilterCategoryProperties,
  TFilterType,
} from 'src/apis/types/filterListAPI';

export type TFilterID = string;

export interface IValue {
  value: string;
  label?: string;
}

export interface IMenuItem {
  id: 'edit' | 'duplicate' | 'delete';
  label: string;
  icon?: ReactNode;
  action: () => void;
}

export interface IFilterProps {
  label: string;
  values: Array<IValue>;
  isInclude?: boolean;
  contentUrl?: string;
  childFilters?: Array<string>;
  parentFilters?: Array<string>;
  type?: TFilterType;
}

export interface IFilterPropsArray extends IFilterProps {
  id: string;
  items: Array<{ name: string }>;
}

export interface IFilterStoreProps {
  filterList: Array<Partial<IFilterCategoryProperties>>;
  selectedFilterList: Record<TFilterID, IFilterProps>;
  activePanelID: TFilterID;
  activeContainerID: TFilterID;
  filterQueryObj: Record<TFilterID, IFilterProps>;
}

interface IFilterID {
  filterID: TFilterID;
}

interface IAppendFilter extends Pick<IFilterProps, 'values'>, IFilterID {}

export type TFilterActionProps =
  | { type: 'SETUP_FILTER'; payload: IFilterCategory }
  | { type: 'SET_ACTIVATE_PANEL_ID'; payload: IFilterID }
  | { type: 'DEACTIVATE_PANEL_ID' }
  | { type: 'SET_ACTIVATE_CONTAINER_ID'; payload: IFilterID }
  | { type: 'DEACTIVATE_CONTAINER_ID' }
  | { type: 'ADD_OR_UPDATE_FILTER'; payload: Record<TFilterID, IFilterProps> }
  | { type: 'APPEND_FILTER'; payload: IAppendFilter }
  | { type: 'REMOVE_FILTER'; payload: IFilterID }
  | { type: 'QUERY_FILTER' }
  | { type: 'RESET_FILTER' }
  | { type: 'RESET_FILTER_ID'; payload: IFilterID }
  | { type: 'ACTIVATE_RENDER' }
  | { type: 'DEACTIVATE_RENDER' }
  | { type: 'UPDATE_FILTER_LIST'; payload: IFilterStoreProps };

const filterReducer = (state: IFilterStoreProps, action: TFilterActionProps): IFilterStoreProps => {
  switch (action.type) {
    case 'SET_ACTIVATE_PANEL_ID': {
      const {
        payload: { filterID },
      } = action;

      return {
        ...state,
        activePanelID: filterID,
      };
    }
    case 'DEACTIVATE_PANEL_ID': {
      return {
        ...state,
        activePanelID: '',
        filterQueryObj: state.selectedFilterList,
      };
    }

    case 'SET_ACTIVATE_CONTAINER_ID': {
      const {
        payload: { filterID },
      } = action;

      return {
        ...state,
        activeContainerID: filterID,
      };
    }
    case 'DEACTIVATE_CONTAINER_ID':
      return {
        ...state,
        activeContainerID: '',
        filterQueryObj: state.selectedFilterList,
      };
    case 'ADD_OR_UPDATE_FILTER': {
      const { payload } = action;
      return {
        ...state,
        selectedFilterList: {
          ...state.selectedFilterList,
          ...payload,
        },
      };
    }
    case 'APPEND_FILTER': {
      const {
        payload: { filterID, values },
      } = action;
      if (state.selectedFilterList[filterID]) {
        return {
          ...state,
          selectedFilterList: {
            ...state.selectedFilterList,
            [filterID]: {
              ...state.selectedFilterList[filterID],
              values: [...state.selectedFilterList[filterID].values, ...values],
            },
          },
        };
      }
      return state;
    }
    case 'REMOVE_FILTER': {
      const { selectedFilterList: filterList } = state;
      delete filterList[action.payload.filterID];
      return {
        ...state,
        selectedFilterList: {
          ...filterList,
        },
        filterQueryObj: {
          ...filterList,
        },
      };
    }
    case 'QUERY_FILTER': {
      return {
        ...state,
        filterQueryObj: state.selectedFilterList,
      };
    }
    case 'RESET_FILTER': {
      return {
        ...state,
        selectedFilterList: {},
        filterQueryObj: {},
      };
    }
    case 'RESET_FILTER_ID': {
      const {
        payload: { filterID },
      } = action;
      if (state.selectedFilterList[filterID]) {
        return {
          ...state,
          selectedFilterList: {
            ...state.selectedFilterList,
            [filterID]: {
              ...state.selectedFilterList[filterID],
              values: [],
            },
          },
        };
      }
      return state;
    }
    case 'UPDATE_FILTER_LIST': {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

export const initFilterStore: IFilterStoreProps = {
  filterList: [],
  selectedFilterList: {},
  activePanelID: '',
  activeContainerID: '',
  filterQueryObj: {},
};
export default filterReducer;
