const getChildren = (expandId: string | null) => {
  switch (String(expandId)) {
    case '11': // _Administration
      return [
        {
          StartsAt: '2023-01-10T00:00:00',
          EndsAt: '2023-02-15T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843773384491009',
          workItemSourceReferenceId: '23',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843773384491009',
          name: 'Interne møder (O0001)',
          sourceReferenceId: '23',
          tenantId: '10002',
          parentId: '183843773288022017',
          type: 'Task',
          pathToParent: '/183843773288022017/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/_Administration/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-02-15T00:00:00',
          EndsAt: '2023-02-28T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 87,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843773384491010',
          workItemSourceReferenceId: '24',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843773384491010',
          name: 'Fakturering (O0002)',
          sourceReferenceId: '24',
          tenantId: '10002',
          parentId: '183843773288022017',
          type: 'Task',
          pathToParent: '/183843773288022017/',
          sortOrder: 2,
          level: 2,
          sortablePath: '/_Administration/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-02-15T00:00:00',
          EndsAt: '2023-03-15T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843773384491011',
          workItemSourceReferenceId: '25',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843773384491011',
          name: 'Mailhåndtering (O0003)',
          sourceReferenceId: '25',
          tenantId: '10002',
          parentId: '183843773288022017',
          type: 'Task',
          pathToParent: '/183843773288022017/',
          sortOrder: 3,
          level: 2,
          sortablePath: '/_Administration/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '24': // Fakturering (O0002)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct18': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 100,
          TotalBooked: 0,
          TotalActualWorkload: 13,
          TotalEAC: 13,
          NotPlanned: 87,
          PositiveUnplannedHours: 87,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 64,
            value: 64,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843773384491010',
          workItemSourceReferenceId: '24',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    case '78': // Anson has a Tree
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003649',
          workItemSourceReferenceId: '487',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003649',
          name: 'FPSA001 (O0464)',
          sourceReferenceId: '487',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/Anson has a Tree/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003650',
          workItemSourceReferenceId: '488',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003650',
          name: 'FPSA002 (O0465)',
          sourceReferenceId: '488',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 2,
          level: 2,
          sortablePath: '/Anson has a Tree/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003652',
          workItemSourceReferenceId: '489',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003652',
          name: 'FPT003 (O0466)',
          sourceReferenceId: '489',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 4,
          level: 2,
          sortablePath: '/Anson has a Tree/004',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003653',
          workItemSourceReferenceId: '490',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003653',
          name: 'FPT004 (O0467)',
          sourceReferenceId: '490',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 5,
          level: 2,
          sortablePath: '/Anson has a Tree/005',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003656',
          workItemSourceReferenceId: '491',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003656',
          name: 'TMSA005 (O0468)',
          sourceReferenceId: '491',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 8,
          level: 2,
          sortablePath: '/Anson has a Tree/008',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003657',
          workItemSourceReferenceId: '492',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003657',
          name: 'TMSA006 (O0469)',
          sourceReferenceId: '492',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 9,
          level: 2,
          sortablePath: '/Anson has a Tree/009',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003662',
          workItemSourceReferenceId: '493',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003662',
          name: 'FPCS009 (O0470)',
          sourceReferenceId: '493',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 16,
          level: 2,
          sortablePath: '/Anson has a Tree/016',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003663',
          workItemSourceReferenceId: '494',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003663',
          name: 'FPII010 (O0471)',
          sourceReferenceId: '494',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 17,
          level: 2,
          sortablePath: '/Anson has a Tree/017',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003664',
          workItemSourceReferenceId: '495',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003664',
          name: 'TMPH011 (O0472)',
          sourceReferenceId: '495',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 18,
          level: 2,
          sortablePath: '/Anson has a Tree/018',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003665',
          workItemSourceReferenceId: '496',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003665',
          name: 'TMPB012 (O0473)',
          sourceReferenceId: '496',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 19,
          level: 2,
          sortablePath: '/Anson has a Tree/019',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2022-11-02T00:00:00',
          EndsAt: '2022-11-02T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003666',
          workItemSourceReferenceId: '505',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003666',
          name: 'Another test (O0482)',
          sourceReferenceId: '505',
          tenantId: '10002',
          parentId: '183843969505951745',
          type: 'Task',
          pathToParent: '/183843969505951745/',
          sortOrder: 20,
          level: 2,
          sortablePath: '/Anson has a Tree/020',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '488': // FPSA002 (O0465)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003651',
          workItemSourceReferenceId: '497',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003651',
          name: 'FPSA0021 (O0474)',
          sourceReferenceId: '497',
          tenantId: '10002',
          parentId: '183843969615003650',
          type: 'Task',
          pathToParent: '/183843969505951745/183843969615003650/',
          sortOrder: 3,
          level: 3,
          sortablePath: '/Anson has a Tree/002/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '490': // FPT004 (O0467)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003654',
          workItemSourceReferenceId: '498',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003654',
          name: 'FPT0041 (O0475)',
          sourceReferenceId: '498',
          tenantId: '10002',
          parentId: '183843969615003653',
          type: 'Task',
          pathToParent: '/183843969505951745/183843969615003653/',
          sortOrder: 6,
          level: 3,
          sortablePath: '/Anson has a Tree/005/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '498': // FPT0041 (O0475)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003655',
          workItemSourceReferenceId: '499',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003655',
          name: 'FPT00411 (O0476)',
          sourceReferenceId: '499',
          tenantId: '10002',
          parentId: '183843969615003654',
          type: 'Task',
          pathToParent: '/183843969505951745/183843969615003653/183843969615003654/',
          sortOrder: 7,
          level: 4,
          sortablePath: '/Anson has a Tree/005/006/007',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '492': // TMSA006 (O0469)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003658',
          workItemSourceReferenceId: '500',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003658',
          name: 'TMSA0061 (O0477)',
          sourceReferenceId: '500',
          tenantId: '10002',
          parentId: '183843969615003657',
          type: 'Task',
          pathToParent: '/183843969505951745/183843969615003657/',
          sortOrder: 10,
          level: 3,
          sortablePath: '/Anson has a Tree/009/010',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '500': // TMSA0061 (O0477)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003659',
          workItemSourceReferenceId: '501',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003659',
          name: 'TMSA00611 (O0478)',
          sourceReferenceId: '501',
          tenantId: '10002',
          parentId: '183843969615003658',
          type: 'Task',
          pathToParent: '/183843969505951745/183843969615003657/183843969615003658/',
          sortOrder: 11,
          level: 4,
          sortablePath: '/Anson has a Tree/009/010/011',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '501': // TMSA00611 (O0478)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003660',
          workItemSourceReferenceId: '502',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003660',
          name: 'TMSA006111 (O0479)',
          sourceReferenceId: '502',
          tenantId: '10002',
          parentId: '183843969615003659',
          type: 'Task',
          pathToParent:
            '/183843969505951745/183843969615003657/183843969615003658/183843969615003659/',
          sortOrder: 12,
          level: 5,
          sortablePath: '/Anson has a Tree/009/010/011/012',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '502': // TMSA006111 (O0479)
      return [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843969615003661',
          workItemSourceReferenceId: '503',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843969615003661',
          name: '4 parents task (O0480)',
          sourceReferenceId: '503',
          tenantId: '10002',
          parentId: '183843969615003660',
          type: 'Task',
          pathToParent:
            '/183843969505951745/183843969615003657/183843969615003658/183843969615003659/183843969615003660/',
          sortOrder: 13,
          level: 6,
          sortablePath: '/Anson has a Tree/009/010/011/012/013',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '503': // 4 parents task (O0480)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct18': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 64,
            value: 64,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843969615003661',
          workItemSourceReferenceId: '503',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    case '17': // ELL - Ny borgerløsning til skat (FPSA DKK)
      return [
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-06-08T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352961',
          workItemSourceReferenceId: '58',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352961',
          name: 'Fase 1 (O0036)',
          sourceReferenceId: '58',
          tenantId: '10002',
          parentId: '183843850383523841',
          type: 'Task',
          pathToParent: '/183843850383523841/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-03-20T00:00:00',
          EndsAt: '2023-09-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352965',
          workItemSourceReferenceId: '62',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352965',
          name: 'Fase 2 (O0040)',
          sourceReferenceId: '62',
          tenantId: '10002',
          parentId: '183843850383523841',
          type: 'Task',
          pathToParent: '/183843850383523841/',
          sortOrder: 6,
          level: 2,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-09-20T00:00:00',
          EndsAt: '2023-11-20T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352969',
          workItemSourceReferenceId: '66',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352969',
          name: 'Fase 3 (O0044)',
          sourceReferenceId: '66',
          tenantId: '10002',
          parentId: '183843850383523841',
          type: 'Task',
          pathToParent: '/183843850383523841/',
          sortOrder: 11,
          level: 2,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/011',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'NotStarted',
          lastUserSetIsActive: false,
        },
      ];

    case '58': // Fase 1 (O0036)
      return [
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-06-08T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352962',
          workItemSourceReferenceId: '59',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352962',
          name: 'Analyse (O0037)',
          sourceReferenceId: '59',
          tenantId: '10002',
          parentId: '183843850509352961',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352961/',
          sortOrder: 2,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/001/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-04-06T00:00:00',
          EndsAt: '2023-04-12T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352963',
          workItemSourceReferenceId: '60',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352963',
          name: 'Rapport (O0038)',
          sourceReferenceId: '60',
          tenantId: '10002',
          parentId: '183843850509352961',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352961/',
          sortOrder: 3,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/001/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-05-15T00:00:00',
          EndsAt: '2023-06-08T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352964',
          workItemSourceReferenceId: '61',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352964',
          name: 'Fremlæggelse (O0039)',
          sourceReferenceId: '61',
          tenantId: '10002',
          parentId: '183843850509352961',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352961/',
          sortOrder: 4,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/001/004',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '62': // Fase 2 (O0040)
      return [
        {
          StartsAt: '2023-03-20T00:00:00',
          EndsAt: '2023-06-22T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352966',
          workItemSourceReferenceId: '63',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352966',
          name: 'Design af brugeroplevelse (O0041)',
          sourceReferenceId: '63',
          tenantId: '10002',
          parentId: '183843850509352965',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352965/',
          sortOrder: 7,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/006/007',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-05-18T00:00:00',
          EndsAt: '2023-07-19T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352967',
          workItemSourceReferenceId: '64',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352967',
          name: 'Design af brugergrænseflader (O0042)',
          sourceReferenceId: '64',
          tenantId: '10002',
          parentId: '183843850509352965',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352965/',
          sortOrder: 8,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/006/008',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-05-03T00:00:00',
          EndsAt: '2023-09-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352968',
          workItemSourceReferenceId: '65',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352968',
          name: 'Udvikling af løsning (O0043)',
          sourceReferenceId: '65',
          tenantId: '10002',
          parentId: '183843850509352965',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352965/',
          sortOrder: 9,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/006/009',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'OnHold',
          lastUserSetIsActive: false,
        },
      ];

    case '64': // Design af brugergrænseflader (O0042)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct18': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 64,
            value: 64,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843850509352967',
          workItemSourceReferenceId: '64',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    case '66': // Fase 3 (O0044)
      return [
        {
          StartsAt: '2023-09-20T00:00:00',
          EndsAt: '2023-10-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352970',
          workItemSourceReferenceId: '67',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352970',
          name: 'Tuning af samlet løsning (O0045)',
          sourceReferenceId: '67',
          tenantId: '10002',
          parentId: '183843850509352969',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352969/',
          sortOrder: 12,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/011/012',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'NotStarted',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-10-04T00:00:00',
          EndsAt: '2023-11-02T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352971',
          workItemSourceReferenceId: '68',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352971',
          name: 'Udarbejdelse af dokumentation (O0046)',
          sourceReferenceId: '68',
          tenantId: '10002',
          parentId: '183843850509352969',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352969/',
          sortOrder: 13,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/011/013',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'NotStarted',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-10-19T00:00:00',
          EndsAt: '2023-11-16T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct18': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct19': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct20': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843850509352972',
          workItemSourceReferenceId: '69',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843850509352972',
          name: 'Udrulning (O0047)',
          sourceReferenceId: '69',
          tenantId: '10002',
          parentId: '183843850509352969',
          type: 'Task',
          pathToParent: '/183843850383523841/183843850509352969/',
          sortOrder: 14,
          level: 3,
          sortablePath: '/ELL - Ny borgerløsning til skat (FPSA DKK)/011/014',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'NotStarted',
          lastUserSetIsActive: false,
        },
      ];

    case '74': // Simple test 1
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344257',
          workItemSourceReferenceId: '464',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344257',
          name: 'With one (O0441)',
          sourceReferenceId: '464',
          tenantId: '10002',
          parentId: '183844089588875265',
          type: 'Task',
          pathToParent: '/183844089588875265/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/Simple test 1/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344260',
          workItemSourceReferenceId: '459',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344260',
          name: 'Simple contact (O0436)',
          sourceReferenceId: '459',
          tenantId: '10002',
          parentId: '183844089588875265',
          type: 'Task',
          pathToParent: '/183844089588875265/',
          sortOrder: 4,
          level: 2,
          sortablePath: '/Simple test 1/004',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344265',
          workItemSourceReferenceId: '467',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344265',
          name: 'New Task (O0444)',
          sourceReferenceId: '467',
          tenantId: '10002',
          parentId: '183844089588875265',
          type: 'Task',
          pathToParent: '/183844089588875265/',
          sortOrder: 9,
          level: 2,
          sortablePath: '/Simple test 1/009',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-11-30T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344267',
          workItemSourceReferenceId: '468',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344267',
          name: 'Task to be hidden (O0445)',
          sourceReferenceId: '468',
          tenantId: '10002',
          parentId: '183844089588875265',
          type: 'Task',
          pathToParent: '/183844089588875265/',
          sortOrder: 11,
          level: 2,
          sortablePath: '/Simple test 1/011',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '464': // With one (O0441)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344258',
          workItemSourceReferenceId: '458',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344258',
          name: 'Opgave 1 (O0435)',
          sourceReferenceId: '458',
          tenantId: '10002',
          parentId: '183844089685344257',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344257/',
          sortOrder: 2,
          level: 3,
          sortablePath: '/Simple test 1/001/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '458': // Opgave 1 (O0435)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344259',
          workItemSourceReferenceId: '465',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344259',
          name: 'Try again (O0442)',
          sourceReferenceId: '465',
          tenantId: '10002',
          parentId: '183844089685344258',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344257/183844089685344258/',
          sortOrder: 3,
          level: 4,
          sortablePath: '/Simple test 1/001/002/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '459': // Simple contact (O0436)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344261',
          workItemSourceReferenceId: '463',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344261',
          name: 'Ip test (O0440)',
          sourceReferenceId: '463',
          tenantId: '10002',
          parentId: '183844089685344260',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344260/',
          sortOrder: 5,
          level: 3,
          sortablePath: '/Simple test 1/004/005',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '463': // Ip test (O0440)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344262',
          workItemSourceReferenceId: '466',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344262',
          name: 'One more on the way (O0443)',
          sourceReferenceId: '466',
          tenantId: '10002',
          parentId: '183844089685344261',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344260/183844089685344261/',
          sortOrder: 6,
          level: 4,
          sortablePath: '/Simple test 1/004/005/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344264',
          workItemSourceReferenceId: '460',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344264',
          name: 'Maybe it is (O0437)',
          sourceReferenceId: '460',
          tenantId: '10002',
          parentId: '183844089685344261',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344260/183844089685344261/',
          sortOrder: 8,
          level: 4,
          sortablePath: '/Simple test 1/004/005/008',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '466': // One more on the way (O0443)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344263',
          workItemSourceReferenceId: '462',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344263',
          name: 'Subbie (O0439)',
          sourceReferenceId: '462',
          tenantId: '10002',
          parentId: '183844089685344262',
          type: 'Task',
          pathToParent:
            '/183844089588875265/183844089685344260/183844089685344261/183844089685344262/',
          sortOrder: 7,
          level: 5,
          sortablePath: '/Simple test 1/004/005/006/007',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '460': // Maybe it is (O0437)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 80,
            value: 80,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844089685344264',
          workItemSourceReferenceId: '460',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    case '467': // New Task (O0444)
      return [
        {
          StartsAt: '2021-11-24T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344266',
          workItemSourceReferenceId: '461',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344266',
          name: 'Here it  (O0438)',
          sourceReferenceId: '461',
          tenantId: '10002',
          parentId: '183844089685344265',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344265/',
          sortOrder: 10,
          level: 3,
          sortablePath: '/Simple test 1/009/010',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '468': // Task to be hidden (O0445)
      return [
        {
          StartsAt: '2021-11-30T00:00:00',
          EndsAt: '2022-02-24T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844089685344268',
          workItemSourceReferenceId: '469',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844089685344268',
          name: 'Task to be deleted (O0446)',
          sourceReferenceId: '469',
          tenantId: '10002',
          parentId: '183844089685344267',
          type: 'Task',
          pathToParent: '/183844089588875265/183844089685344267/',
          sortOrder: 12,
          level: 3,
          sortablePath: '/Simple test 1/011/012',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '19': // SWE - National Survey Board (MTEB EUR)
      return [
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-07-19T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924345880577',
          workItemSourceReferenceId: '75',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924345880577',
          name: 'Survey Production (start-up) (O0053)',
          sourceReferenceId: '75',
          tenantId: '10002',
          parentId: '183843924249411585',
          type: 'Task',
          pathToParent: '/183843924249411585/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-05-19T00:00:00',
          EndsAt: '2024-05-17T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924345880581',
          workItemSourceReferenceId: '76',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924345880581',
          name: 'Survey conduction (monthly retainer) (O0054)',
          sourceReferenceId: '76',
          tenantId: '10002',
          parentId: '183843924249411585',
          type: 'Task',
          pathToParent: '/183843924249411585/',
          sortOrder: 6,
          level: 2,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '72': // Build Survey Framework (O0050)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 80,
            value: 80,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843924345880578',
          workItemSourceReferenceId: '72',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    case '73': // Setup Survey Monkey (O0051)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 80,
            value: 80,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183843924345880579',
          workItemSourceReferenceId: '73',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    case '75': // Survey Production (start-up) (O0053)
      return [
        {
          StartsAt: '2023-01-18T00:00:00',
          EndsAt: '2023-07-19T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924345880578',
          workItemSourceReferenceId: '72',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924345880578',
          name: 'Build Survey Framework (O0050)',
          sourceReferenceId: '72',
          tenantId: '10002',
          parentId: '183843924345880577',
          type: 'Task',
          pathToParent: '/183843924249411585/183843924345880577/',
          sortOrder: 2,
          level: 3,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/001/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-03-20T00:00:00',
          EndsAt: '2023-05-29T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924345880579',
          workItemSourceReferenceId: '73',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924345880579',
          name: 'Setup Survey Monkey (O0051)',
          sourceReferenceId: '73',
          tenantId: '10002',
          parentId: '183843924345880577',
          type: 'Task',
          pathToParent: '/183843924249411585/183843924345880577/',
          sortOrder: 3,
          level: 3,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/001/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-03-20T00:00:00',
          EndsAt: '2023-05-18T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924345880580',
          workItemSourceReferenceId: '74',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924345880580',
          name: 'Report test sample (O0052)',
          sourceReferenceId: '74',
          tenantId: '10002',
          parentId: '183843924345880577',
          type: 'Task',
          pathToParent: '/183843924249411585/183843924345880577/',
          sortOrder: 4,
          level: 3,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/001/004',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '76': // Survey conduction (monthly retainer) (O0054)
      return [
        {
          StartsAt: '2023-05-19T00:00:00',
          EndsAt: '2024-05-17T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924345880582',
          workItemSourceReferenceId: '77',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924345880582',
          name: 'Monitor collection (O0055)',
          sourceReferenceId: '77',
          tenantId: '10002',
          parentId: '183843924345880581',
          type: 'Task',
          pathToParent: '/183843924249411585/183843924345880581/',
          sortOrder: 7,
          level: 3,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/006/007',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2023-05-19T00:00:00',
          EndsAt: '2024-05-17T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183843924345880583',
          workItemSourceReferenceId: '78',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183843924345880583',
          name: 'Send report (O0056)',
          sourceReferenceId: '78',
          tenantId: '10002',
          parentId: '183843924345880581',
          type: 'Task',
          pathToParent: '/183843924249411585/183843924345880581/',
          sortOrder: 8,
          level: 3,
          sortablePath: '/SWE - National Survey Board (MTEB EUR)/006/008',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '54': // TCC1204-quick create
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612225',
          workItemSourceReferenceId: '269',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612225',
          name: 'FPSA001 (O0247)',
          sourceReferenceId: '269',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/TCC1204-quick create/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612226',
          workItemSourceReferenceId: '270',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612226',
          name: 'FPSA002 (O0248)',
          sourceReferenceId: '270',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 2,
          level: 2,
          sortablePath: '/TCC1204-quick create/002',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612228',
          workItemSourceReferenceId: '271',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612228',
          name: 'FPT003 (O0249)',
          sourceReferenceId: '271',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 4,
          level: 2,
          sortablePath: '/TCC1204-quick create/004',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612229',
          workItemSourceReferenceId: '272',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612229',
          name: 'FPT004 (O0250)',
          sourceReferenceId: '272',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 5,
          level: 2,
          sortablePath: '/TCC1204-quick create/005',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612232',
          workItemSourceReferenceId: '273',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612232',
          name: 'TMSA005 (O0251)',
          sourceReferenceId: '273',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 8,
          level: 2,
          sortablePath: '/TCC1204-quick create/008',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612233',
          workItemSourceReferenceId: '274',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612233',
          name: 'TMSA006 (O0252)',
          sourceReferenceId: '274',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 9,
          level: 2,
          sortablePath: '/TCC1204-quick create/009',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612237',
          workItemSourceReferenceId: '275',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612237',
          name: 'FPCS009 (O0253)',
          sourceReferenceId: '275',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 15,
          level: 2,
          sortablePath: '/TCC1204-quick create/015',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612238',
          workItemSourceReferenceId: '276',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612238',
          name: 'FPII010 (O0254)',
          sourceReferenceId: '276',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 16,
          level: 2,
          sortablePath: '/TCC1204-quick create/016',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612239',
          workItemSourceReferenceId: '277',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612239',
          name: 'TMPH011 (O0255)',
          sourceReferenceId: '277',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 17,
          level: 2,
          sortablePath: '/TCC1204-quick create/017',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612240',
          workItemSourceReferenceId: '278',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612240',
          name: 'TMPB012 (O0256)',
          sourceReferenceId: '278',
          tenantId: '10002',
          parentId: '183844343725948929',
          type: 'Task',
          pathToParent: '/183844343725948929/',
          sortOrder: 18,
          level: 2,
          sortablePath: '/TCC1204-quick create/018',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '270': // FPSA002 (O0248)
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612227',
          workItemSourceReferenceId: '279',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612227',
          name: 'FPSA0021 (O0257)',
          sourceReferenceId: '279',
          tenantId: '10002',
          parentId: '183844343826612226',
          type: 'Task',
          pathToParent: '/183844343725948929/183844343826612226/',
          sortOrder: 3,
          level: 3,
          sortablePath: '/TCC1204-quick create/002/003',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '272': // FPT004 (O0250)
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612230',
          workItemSourceReferenceId: '280',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612230',
          name: 'FPT0041 (O0258)',
          sourceReferenceId: '280',
          tenantId: '10002',
          parentId: '183844343826612229',
          type: 'Task',
          pathToParent: '/183844343725948929/183844343826612229/',
          sortOrder: 6,
          level: 3,
          sortablePath: '/TCC1204-quick create/005/006',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '280': // FPT0041 (O0258)
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 60,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612231',
          workItemSourceReferenceId: '281',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612231',
          name: 'FPT00411 (O0259)',
          sourceReferenceId: '281',
          tenantId: '10002',
          parentId: '183844343826612230',
          type: 'Task',
          pathToParent: '/183844343725948929/183844343826612229/183844343826612230/',
          sortOrder: 7,
          level: 4,
          sortablePath: '/TCC1204-quick create/005/006/007',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '281': // FPT00411 (O0259)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 60,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 60,
          PositiveUnplannedHours: 60,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 80,
            value: 80,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844343826612231',
          workItemSourceReferenceId: '281',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    case '274': // TMSA006 (O0252)
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612234',
          workItemSourceReferenceId: '282',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612234',
          name: 'TMSA0061 (O0260)',
          sourceReferenceId: '282',
          tenantId: '10002',
          parentId: '183844343826612233',
          type: 'Task',
          pathToParent: '/183844343725948929/183844343826612233/',
          sortOrder: 10,
          level: 3,
          sortablePath: '/TCC1204-quick create/009/010',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '282': // TMSA0061 (O0260)
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: true,
          editable: false,
          canAssign: false,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612235',
          workItemSourceReferenceId: '283',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612235',
          name: 'TMSA00611 (O0261)',
          sourceReferenceId: '283',
          tenantId: '10002',
          parentId: '183844343826612234',
          type: 'Task',
          pathToParent: '/183844343725948929/183844343826612233/183844343826612234/',
          sortOrder: 11,
          level: 4,
          sortablePath: '/TCC1204-quick create/009/010/011',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    case '283': // TMSA00611 (O0261)
      return [
        {
          StartsAt: '2020-12-04T00:00:00',
          EndsAt: '2020-12-25T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2024Oct21': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct22': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct23': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct24': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct25': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct28': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct29': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct30': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Oct31': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov01': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov04': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov05': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov06': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov07': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov08': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov11': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov12': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov13': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov14': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov15': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: '',
              editable: false,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 0,
            value: 0,
          },
          canExpand: false,
          editable: false,
          canAssign: true,
          canDelete: false,
          resourceId: '0',
          resourceSourceReferenceId: 0,
          resourceType: 'Unknown',
          workItemId: '183844343826612236',
          workItemSourceReferenceId: '284',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          rootParentName: '',
          id: '183844343826612236',
          name: 'TMSA006111 (O0262)',
          sourceReferenceId: '284',
          tenantId: '10002',
          parentId: '183844343826612235',
          type: 'Task',
          pathToParent:
            '/183844343725948929/183844343826612233/183844343826612234/183844343826612235/',
          sortOrder: 12,
          level: 5,
          sortablePath: '/TCC1204-quick create/009/010/011/012',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          lastUserSetIsActive: false,
        },
      ];

    // case '76': // Z - Test 30 days - 2
    //   return [
    //     {
    //       StartsAt: '2022-03-16T00:00:00',
    //       EndsAt: '2022-06-30T00:00:00',
    //       hierarchyType: 'workitem',
    //       values: {
    //         '2024Oct21': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct22': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct23': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct24': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct25': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct26': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct27': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct28': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct29': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct30': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Oct31': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov01': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov02': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov03': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov04': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov05': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov06': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov07': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov08': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov09': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov10': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov11': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov12': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov13': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov14': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov15': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov16': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //         '2024Nov17': {
    //           displayValue: 0,
    //           value: 0,
    //           heatmapCode: '',
    //           editable: false,
    //         },
    //       },
    //       Budget: 0,
    //       TotalBooked: 0,
    //       TotalActualWorkload: 0,
    //       TotalEAC: 0,
    //       NotPlanned: 0,
    //       PositiveUnplannedHours: 0,
    //       NegativeUnplannedHours: 0,
    //       total: {
    //         displayValue: 0,
    //         value: 0,
    //       },
    //       canExpand: true,
    //       editable: false,
    //       canAssign: true,
    //       canDelete: false,
    //       resourceId: '0',
    //       resourceSourceReferenceId: 0,
    //       resourceType: 'Unknown',
    //       workItemId: '183844041828335617',
    //       workItemSourceReferenceId: '471',
    //       workItemType: 'Task',
    //       hasChildWithEndDateOnTodayOrAfter: false,
    //       rootParentName: '',
    //       id: '183844041828335617',
    //       name: 'Test 30 days - Task 1 (O0448)',
    //       sourceReferenceId: '471',
    //       tenantId: '10002',
    //       parentId: '183844041731866625',
    //       type: 'Task',
    //       pathToParent: '/183844041731866625/',
    //       sortOrder: 1,
    //       level: 2,
    //       sortablePath: '/Z - Test 30 days - 2/001',
    //       isActive: false,
    //       startAt: '0001-01-01T00:00:00',
    //       endAt: '0001-01-01T00:00:00',
    //       status: 'InProgress',
    //       lastUserSetIsActive: false,
    //     },
    //   ];

    case '471': // Test 30 days - Task 1 (O0448)
      return [
        {
          hierarchyType: 'resource',
          values: {
            '2024Oct21': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct22': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct23': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct24': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct25': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct26': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct27': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Oct28': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct29': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct30': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Oct31': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov01': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov02': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov03': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov04': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov05': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov06': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov07': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov08': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov09': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov10': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov11': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov12': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov13': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov14': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov15': {
              displayValue: 4,
              value: 4,
              heatmapCode: 'bgAvailability100',
              editable: true,
            },
            '2024Nov16': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
            '2024Nov17': {
              displayValue: 0,
              value: 0,
              heatmapCode: 'bgLightGray',
              editable: true,
            },
          },
          Budget: 0,
          TotalBooked: 0,
          TotalActualWorkload: 0,
          TotalEAC: 0,
          NotPlanned: 0,
          PositiveUnplannedHours: 0,
          NegativeUnplannedHours: 0,
          total: {
            displayValue: 80,
            value: 80,
          },
          canExpand: false,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '183843674239533056',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '183844041828335617',
          workItemSourceReferenceId: '471',
          workItemType: 'Task',
          hasChildWithEndDateOnTodayOrAfter: false,
          id: '183843674239533056',
          name: 'Anders Nielsen (ANI)',
          role: 'Projektleder',
          isActive: false,
          hiredAt: '2019-01-01',
          tenantId: '10002',
          sourceReferenceId: 1,
          type: 'Employee',
        },
      ];

    default:
      return [];
  }
};

export default (expandId: string | null) => ({
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'GroupByWorkItem',
      viewOptions: {
        'grouped-by': 'group-by-work-item',
        'reporting-types': 'availability',
        'period-types': 'day',
        'unit-types': 'hours',
        'period-starts-at': '2024-10-18',
        'period-ends-at': '2024-11-10',
      },
      periods: [
        {
          startsAt: '2024-10-21T00:00:00',
          endsAt: '2024-10-21T00:00:00',
          type: 'Day',
          identifier: '2024Oct21',
        },
        {
          startsAt: '2024-10-22T00:00:00',
          endsAt: '2024-10-22T00:00:00',
          type: 'Day',
          identifier: '2024Oct22',
        },
        {
          startsAt: '2024-10-23T00:00:00',
          endsAt: '2024-10-23T00:00:00',
          type: 'Day',
          identifier: '2024Oct23',
        },
        {
          startsAt: '2024-10-24T00:00:00',
          endsAt: '2024-10-24T00:00:00',
          type: 'Day',
          identifier: '2024Oct24',
        },
        {
          startsAt: '2024-10-25T00:00:00',
          endsAt: '2024-10-25T00:00:00',
          type: 'Day',
          identifier: '2024Oct25',
        },
        {
          startsAt: '2024-10-26T00:00:00',
          endsAt: '2024-10-26T00:00:00',
          type: 'Day',
          identifier: '2024Oct26',
        },
        {
          startsAt: '2024-10-27T00:00:00',
          endsAt: '2024-10-27T00:00:00',
          type: 'Day',
          identifier: '2024Oct27',
        },
        {
          startsAt: '2024-10-28T00:00:00',
          endsAt: '2024-10-28T00:00:00',
          type: 'Day',
          identifier: '2024Oct28',
        },
        {
          startsAt: '2024-10-29T00:00:00',
          endsAt: '2024-10-29T00:00:00',
          type: 'Day',
          identifier: '2024Oct29',
        },
        {
          startsAt: '2024-10-30T00:00:00',
          endsAt: '2024-10-30T00:00:00',
          type: 'Day',
          identifier: '2024Oct30',
        },
        {
          startsAt: '2024-10-31T00:00:00',
          endsAt: '2024-10-31T00:00:00',
          type: 'Day',
          identifier: '2024Oct31',
        },
        {
          startsAt: '2024-11-01T00:00:00',
          endsAt: '2024-11-01T00:00:00',
          type: 'Day',
          identifier: '2024Nov01',
        },
        {
          startsAt: '2024-11-02T00:00:00',
          endsAt: '2024-11-02T00:00:00',
          type: 'Day',
          identifier: '2024Nov02',
        },
        {
          startsAt: '2024-11-03T00:00:00',
          endsAt: '2024-11-03T00:00:00',
          type: 'Day',
          identifier: '2024Nov03',
        },
        {
          startsAt: '2024-11-04T00:00:00',
          endsAt: '2024-11-04T00:00:00',
          type: 'Day',
          identifier: '2024Nov04',
        },
        {
          startsAt: '2024-11-05T00:00:00',
          endsAt: '2024-11-05T00:00:00',
          type: 'Day',
          identifier: '2024Nov05',
        },
        {
          startsAt: '2024-11-06T00:00:00',
          endsAt: '2024-11-06T00:00:00',
          type: 'Day',
          identifier: '2024Nov06',
        },
        {
          startsAt: '2024-11-07T00:00:00',
          endsAt: '2024-11-07T00:00:00',
          type: 'Day',
          identifier: '2024Nov07',
        },
        {
          startsAt: '2024-11-08T00:00:00',
          endsAt: '2024-11-08T00:00:00',
          type: 'Day',
          identifier: '2024Nov08',
        },
        {
          startsAt: '2024-11-09T00:00:00',
          endsAt: '2024-11-09T00:00:00',
          type: 'Day',
          identifier: '2024Nov09',
        },
        {
          startsAt: '2024-11-10T00:00:00',
          endsAt: '2024-11-10T00:00:00',
          type: 'Day',
          identifier: '2024Nov10',
        },
        {
          startsAt: '2024-11-11T00:00:00',
          endsAt: '2024-11-11T00:00:00',
          type: 'Day',
          identifier: '2024Nov11',
        },
        {
          startsAt: '2024-11-12T00:00:00',
          endsAt: '2024-11-12T00:00:00',
          type: 'Day',
          identifier: '2024Nov12',
        },
        {
          startsAt: '2024-11-13T00:00:00',
          endsAt: '2024-11-13T00:00:00',
          type: 'Day',
          identifier: '2024Nov13',
        },
        {
          startsAt: '2024-11-14T00:00:00',
          endsAt: '2024-11-14T00:00:00',
          type: 'Day',
          identifier: '2024Nov14',
        },
        {
          startsAt: '2024-11-15T00:00:00',
          endsAt: '2024-11-15T00:00:00',
          type: 'Day',
          identifier: '2024Nov15',
        },
        {
          startsAt: '2024-11-16T00:00:00',
          endsAt: '2024-11-16T00:00:00',
          type: 'Day',
          identifier: '2024Nov16',
        },
        {
          startsAt: '2024-11-17T00:00:00',
          endsAt: '2024-11-17T00:00:00',
          type: 'Day',
          identifier: '2024Nov17',
        },
      ],
      children: getChildren(expandId),
    },
    links: [
      {
        href: 'http://uat2.resourceplanner2.aks.timelog.com:80/plan/partialgroupbyworkitem?periodstartsat=2024-10-18&periodendsat=2024-11-10&periodtypes=day&unittypes=hours&reportingtypes=availability&showtaskno=true&firstload=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 1809017086,
  StatusCode: 200,
});
