import { rest } from 'msw';
import { eventHandler } from 'src/mocks/handlers';
import { filterFullResult } from '../filter';
import getGroupByResourceDayFullResult from './getGroupByResourceDayFullResult';
import getGroupByResourceDayPartialResult from './getGroupByResourceDayPartialResult';
import getGroupByResourceEmptyResult from './getGroupByResourceEmptyResult';
import getGroupByResourceMonthFullResult from './getGroupByResourceMonthFullResult';
import getGroupByResourceQuarterFullResult from './getGroupByResourceQuarterFullResult';
import getGroupByResourceWeekFullResult from './getGroupByResourceWeekFullResult';
import getGroupByWorkItemDayFullResult from './getGroupByWorkItemDayFullResult';
import getGroupByWorkItemDayPartialResult from './getGroupByWorkItemDayPartialResult';
import getGroupByWorkItemMonthFullResult from './getGroupByWorkItemMonthFullResult';
import getGroupByWorkItemQuarterFullResult from './getGroupByWorkItemQuarterFullResult';
import getGroupByWorkItemWeekFullResult from './getGroupByWorkItemWeekFullResult';
import getGroupByWorkItemWeekPartialResult from './getGroupByWorkItemWeekPartialResult';
import getResourceViewOptions from './getResourceViewOptions';
import getSearchResourceByName from './getSearchResourceByName';
import getSearchTaskByName from './getSearchTaskByName';
import getSignalRConfigFullResult from './getSignalRConfigFullResult';
import {
  getMigrationStatusComplete,
  getRpActiveEmployeeCount,
  getRpAllProjectCount,
  getRpPlanCount,
  getTlpActiveEmployeeCount,
  getTlpAllProjectCount,
  getTlpPlanCount,
} from './migration';

export const resourcePlannerHandlers = [
  rest.post(
    'http://mockapi/api/v2/resource-planner/partial-group-by-work-item-first-load',
    (req, res, ctx) => {
      if (req.url.search.indexOf('periodtypes=day') > -1) {
        const skip = req.url.searchParams.get('skip');
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByWorkItemDayFullResult(Number(skip)),
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=week') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByWorkItemWeekFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=quarter') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByWorkItemQuarterFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      return eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getGroupByWorkItemMonthFullResult,
          '204': getGroupByResourceEmptyResult,
        },
      });
    },
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/partial-group-by-work-item',
    async (req, res, ctx) => {
      const { expandId } = await req.json();
      if (req.url.search.indexOf('periodtypes=day') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByWorkItemDayPartialResult(expandId),
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      return eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getGroupByWorkItemWeekPartialResult(expandId),
          '204': getGroupByResourceEmptyResult,
        },
      });
    },
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/partial-group-by-employee-first-load',
    (req, res, ctx) => {
      if (req.url.search.indexOf('periodtypes=day') > -1) {
        const skip = req.url.searchParams.get('skip');
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByResourceDayFullResult(Number(skip)),
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=week') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByResourceWeekFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      if (req.url.search.indexOf('periodtypes=quarter') > -1) {
        return eventHandler({
          req,
          res,
          ctx,
          responseData: {
            '200': getGroupByResourceQuarterFullResult,
            '204': getGroupByResourceEmptyResult,
          },
        });
      }
      return eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getGroupByResourceMonthFullResult,
          '204': getGroupByResourceEmptyResult,
        },
      });
    },
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/partial-group-by-employee',
    async (req, res, ctx) => {
      const { expandId } = await req.json();
      return eventHandler({
        req,
        res,
        ctx,
        responseData: {
          '200': getGroupByResourceDayPartialResult(expandId),
          '204': getGroupByResourceEmptyResult,
        },
      });
    },
  ),
  rest.post('http://mockapi/api/v2/resource-planner/group-by-employee-total-row', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/view-options', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getResourceViewOptions } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/migration-status', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getMigrationStatusComplete } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/tlp-active-employee-count', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getTlpActiveEmployeeCount } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/rp-active-employee-count', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getRpActiveEmployeeCount } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/tlp-all-project-count', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getTlpAllProjectCount } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/rp-all-project-count', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getRpAllProjectCount } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/tlp-plan-count', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getTlpPlanCount } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/rp-plan-count', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getRpPlanCount } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/search-resource-by-name', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getSearchResourceByName } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/search-task-by-name', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getSearchTaskByName } }),
  ),
  rest.get('http://mockapi/api/v2/resource-planner/signalr-config', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': getSignalRConfigFullResult } }),
  ),
  rest.get('http://mockapi/api/v2/filters/list/NewResourcePlanner', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': filterFullResult } }),
  ),
  rest.post('http://mockapi/api/v2/ResourcePlanner', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/create-work-item-relationship',
    (req, res, ctx) => eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.post(
    'http://mockapi/api/v2/resource-planner/start-initial-send-of-employees',
    (req, res, ctx) => eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.post('https://localhost:5001/hubs/resourcePlannerHub/negotiate', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
  rest.delete('http://mockapi/api/v2/resource-planner/work-item-relationship', (req, res, ctx) =>
    eventHandler({ req, res, ctx, responseData: { '200': {} } }),
  ),
];
