import { MoreVert } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { INewSavedView } from 'src/apis/savedViewAPI';
import { IconButton, TabMenu, Typography } from 'src/components/mui-components';
import { useMenu } from 'src/hooks/useMenu';
import { IMenuItem } from 'src/reducers/FilterReducer/FilterReducer';
import styles from '../../SaveViewContainer.module.scss';

interface TabActionsProps {
  v: INewSavedView;
  menuItems: IMenuItem[];
}

export const TabActions = ({ v, menuItems }: TabActionsProps) => {
  const { anchorEl, menuIsOpen, setAnchorEl } = useMenu();
  const ICON_BUTTON_ID = 'view-button';
  const TAB_MENU_ID = 'view-menu';

  const { filterViewId, name } = v;

  const handleMenuItemClick = ({ action }: Pick<IMenuItem, 'action'>) => {
    action();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={TAB_MENU_ID}
        aria-expanded={!!menuIsOpen}
        aria-haspopup="true"
        aria-label="More options"
        color="primary"
        id={`ICON_BUTTON_ID-${filterViewId}`}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        data-automation-id={`${name.replace(/ /g, '')}Menu`}
        size="small"
        className={styles.tabIconButton}
      >
        <MoreVert fontSize="small" />
      </IconButton>

      <TabMenu
        id={TAB_MENU_ID}
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ 'aria-labelledby': ICON_BUTTON_ID }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleMenuItemClick(item)}
            data-automation-id={item.label}
          >
            <Typography fontSize={14}>{item.label}</Typography>
          </MenuItem>
        ))}
      </TabMenu>
    </>
  );
};
