import { useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  ColumnDef,
  SortingState,
  PaginationState,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Table, Shimmer, TableSortButton } from 'src/components/ui-components';
import Pagination from 'src/components/ui-components/examples/ExampleTable/components/Pagination';
import { useFilterStore } from 'src/stores/FilterStore';
import { usePostTimeRegistrationsAPI } from 'src/apis/reports/timeRegistrationsAPI';
import { ResponseHandler } from 'src/components/utils/ResponseHandler';
import { useTranslation } from 'react-i18next';

interface IData {
  projectNo: string;
  projectName: string;
  customerName: string;
  projectManagerInitials: string;
  departmentName: string;
}

interface ITable {
  columns: ColumnDef<IData>[];
  data: IData[];
}

const EmptyTable = () => {
  const { t } = useTranslation('filter');
  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>{t('EmptyReportText')}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const LoadingTable = () => (
  <Table>
    <Table.Head>
      <Table.Row>
        <Table.Cell>
          <Shimmer />
        </Table.Cell>
        <Table.Cell>
          <Shimmer width="thirdQuarter" />
        </Table.Cell>
        <Table.Cell>
          <Shimmer width="half" />
        </Table.Cell>
        <Table.Cell>
          <Shimmer width="quarter" />
        </Table.Cell>
      </Table.Row>
    </Table.Head>
    <Table.Body>
      <Table.Row>
        <Table.Cell>
          <Shimmer width="half" />
        </Table.Cell>
        <Table.Cell>
          <Shimmer width="quarter" />
        </Table.Cell>
        <Table.Cell>
          <Shimmer />
        </Table.Cell>
        <Table.Cell>
          <Shimmer width="thirdQuarter" />
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

const TimeRegistrationsTableView = ({ columns, data }: ITable) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <Table layout="fixed" stickyHeader data-automation-id="ExampleTable">
        <Table.ColGroup>
          <Table.Col colWidth={140} />
          <Table.Col />
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers
                .slice(1, headerGroup.headers.length)
                .map((item) => <Table.Col key={`table-col-${item.id}`} colWidth={160} />),
            )}
        </Table.ColGroup>

        <Table.Head>
          {table.getHeaderGroups().map((headerGroup, rowIndex) => (
            <Table.Row data-automation-id={`ExampleTableHeaderRow${rowIndex}`} key={headerGroup.id}>
              {headerGroup.headers.map((header, cellIndex) =>
                header.isPlaceholder ? null : (
                  <Table.Header
                    colSpan={cellIndex === 1 ? 2 : undefined}
                    data-automation-id={`ExampleTableHeaderRow${rowIndex}Cell${cellIndex}`}
                    isSortable={header.column.getCanSort()} // making the filter tag pass
                    key={header.id}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <TableSortButton
                        label="Sort this column"
                        data-automation-id={`ExampleTableSortButton${cellIndex}`}
                        sortDirection={
                          // eslint-disable-next-line no-nested-ternary
                          header.column.getIsSorted()
                            ? header.column.getAutoSortDir() === 'desc'
                              ? 'DESC'
                              : 'ASC'
                            : undefined
                        }
                        {...{ onClick: header.column.getToggleSortingHandler() }}
                      />
                    )}
                  </Table.Header>
                ),
              )}
            </Table.Row>
          ))}
        </Table.Head>

        <Table.Body>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <Table.Row key={row.id} data-automation-id={`ExampleTableBodyRow${rowIndex}`}>
              {row.getVisibleCells().map((cell, cellIndex) => (
                <Table.Cell
                  colSpan={cellIndex === 1 ? 2 : undefined}
                  data-automation-id={`ExampleTableBodyRow${rowIndex}Cell${cellIndex}`}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Pagination
        getCanPreviousPage={table.getCanPreviousPage()}
        getCanNextPage={table.getCanNextPage()}
        getPageCount={table.getPageCount}
        setPageIndex={table.setPageIndex}
        nextPage={table.nextPage}
        previousPage={table.previousPage}
        setPageSize={table.setPageSize}
        pageIndex={table.getState().pagination.pageSize}
        pageSize={50}
      />
    </>
  );
};

export const TimeRegistrationsTable = () => {
  const { selectedFilterList } = useFilterStore();
  const [mutation, modifiedData] = usePostTimeRegistrationsAPI({ selectedFilterList });
  const { mutate: update, isLoading, isError } = mutation;

  useEffect(() => {
    update();
  }, [selectedFilterList, update]);

  const isEmpty = modifiedData === undefined;

  const columns = useMemo<ColumnDef<IData>[]>(
    () => [
      {
        id: 'projectNo',
        header: 'Project number',
        accessorKey: 'projectNo',
        sortDescFirst: true,
      },
      {
        id: 'projectName',
        header: 'Project Name',
        accessorKey: 'projectName',
      },
      {
        id: 'customerName',
        header: 'Customer',
        accessorKey: 'customerName',
        enableSorting: false,
      },
      {
        id: 'projectManagerInitials',
        header: 'Project Manager Initials',
        accessorKey: 'projectManagerInitials',
        enableSorting: false,
      },
      {
        id: 'departmentName',
        header: 'Department',
        accessorKey: 'departmentName',
        enableSorting: false,
      },
    ],
    [],
  );

  const rows = useMemo(() => modifiedData, [modifiedData]);

  return (
    <ResponseHandler
      isLoading={isLoading}
      isEmpty={isEmpty}
      isError={isError}
      EmptyComponent={<EmptyTable />}
      LoadingComponent={<LoadingTable />}
    >
      <TimeRegistrationsTableView columns={columns} data={rows} />
    </ResponseHandler>
  );
};

export default TimeRegistrationsTable;
