import { useMutation, useQueryClient } from '@tanstack/react-query';
import { reloadRpTables } from 'src/screens/ResourcePlanner/helper/reloadRpTables';
import { signalRConnectionIdKey } from 'src/screens/ResourcePlanner/sessionStorageKeys';
import { postFetch } from 'src/utils/fetcher';
import { toPascal } from 'src/utils/object';
import { GET_RECENT_PROJECTS_KEY } from '../get/getRecentProjectsAPI.constants';
import { GET_RECENT_RESOURCES_KEY } from '../get/getRecentResourcesAPI.constants';
import { GET_RECENT_TASKS_KEY } from '../get/getRecentTasksAPI.constants';
import { GET_UNALLOCATED_TASKS_KEY } from '../get/getUnallocatedTasksAPI.constants';
import { IBulkAssignReq } from './postBulkAssignAPI.types';
import { POST_EMPLOYEE_SEARCH_KEY } from './postEmployeeSearchAPI.constants';

const POST_BULK_ASSIGN_URL_PATH = '/api/v2/resource-planner/work-item-relationship/bulk';
const POST_BULK_ASSIGN_KEY = 'BulkAssign';

const postBulkAssign = (reqBody: IBulkAssignReq[] = []) => {
  const hubConnectionId = sessionStorage.getItem(signalRConnectionIdKey);
  const pascalReqBody = reqBody.map((r) => toPascal({ ...r, hubConnectionId }));

  return postFetch({
    path: POST_BULK_ASSIGN_URL_PATH,
    key: POST_BULK_ASSIGN_KEY,
    body: JSON.stringify(pascalReqBody),
  });
};

export const usePostBulkAssign = (onSuccess?: () => void) => {
  const qc = useQueryClient();

  return useMutation(postBulkAssign, {
    onSuccess: () => {
      qc.invalidateQueries([GET_RECENT_PROJECTS_KEY]);
      qc.invalidateQueries([GET_RECENT_RESOURCES_KEY]);
      qc.invalidateQueries([GET_RECENT_TASKS_KEY]);
      qc.invalidateQueries([GET_UNALLOCATED_TASKS_KEY]);
      qc.invalidateQueries([POST_EMPLOYEE_SEARCH_KEY]);
      reloadRpTables(qc);
      onSuccess?.();
    },
  });
};
