import { DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/components/mui-components';

interface IViewDeleteDialog {
  showDeleteDialog: boolean;
  setShowDeleteDialog: (showDeleteDialog: boolean) => void;
  onDelete: () => void;
}

export const DeleteViewDialog = ({
  showDeleteDialog,
  setShowDeleteDialog,
  onDelete,
}: IViewDeleteDialog) => {
  const { t } = useTranslation('savedView');

  const cancelOnClick = () => {
    setShowDeleteDialog(false);
  };

  return (
    <Dialog
      aria-labelledby="dialog-view-delete-text"
      aria-describedby="dialog-view-delete-desc"
      maxWidth="xs"
      onClose={cancelOnClick}
      open={showDeleteDialog}
    >
      <DialogTitle id="dialog-view-delete-text">{t('DeleteViewModalTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-view-delete-desc">
          {t('DeleteViewModalDesc')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={cancelOnClick}>
          {t('DialogCancelText')}
        </Button>
        <Button variant="contained" onClick={onDelete} autoFocus>
          {t('DialogOkText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
