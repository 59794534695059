import { ISavedViewResponse } from 'src/apis/savedViewAPI';

const NewSavedViews: ISavedViewResponse = {
  entities: [
    {
      properties: {
        created: new Date('2024-10-21T08:39:16.411Z'),
        filterValues: [
          {
            filterType: 'DateRange',
            isInclusive: true,
            name: 'DateRange',
            // value: '2024-01-30,2024-11-12',
            value: 'ThreeMonths', // 'OneWeek' || 'OneMonth' || 'ThreeMonths' || 'OneYear'
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'grouped-by',
            value: 'group-by-work-item',
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'reporting-types',
            value: 'availability',
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'period-types',
            value: 'day',
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'unit-types',
            value: 'hours',
          },
        ],
        filterViewId: '0',
        isDefault: false,
        lastModified: new Date('2024-11-01T04:00:22.016Z'),
        name: 'Project View',
      },
    },
    {
      properties: {
        created: new Date('2024-11-01T04:00:33.481Z'),
        filterValues: [
          {
            filterType: 'DateRange',
            isInclusive: true,
            name: 'DateRange',
            // value: '2024-01-30,2024-11-12',
            value: 'OneMonth', // 'OneWeek' || 'OneMonth' || 'ThreeMonths' || 'OneYear'
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'grouped-by',
            value: 'group-by-resource',
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'reporting-types',
            value: 'availability',
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'period-types',
            value: 'day',
          },
          {
            filterType: 'SingleSelect',
            isInclusive: true,
            name: 'unit-types',
            value: 'hours',
          },
        ],
        filterViewId: '1',
        isDefault: false,
        lastModified: new Date('2024-11-01T04:00:37.358Z'),
        name: 'Resource View',
      },
    },
  ],
  links: [
    {
      href: 'http://localhost/tlp/api/v2/filter-view',
      rel: 'self',
    },
  ],
};

export default NewSavedViews;
