import { FilterList } from '@mui/icons-material';
import { Badge, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ViewOptions } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/ViewOptions';
import {
  IViewOptionsField,
  ViewOptionsChangeParameters,
} from 'src/components/layout/FilterPanelLayout/types';
import { useSidePanelDispatch } from 'src/components/layout/SidePanel';
import { Deck } from 'src/components/ui-components';
import { useSelectedFilterListInArray } from 'src/stores/FilterStore';

interface IResourcePlannerViewOptions {
  periodPicker: JSX.Element;
  viewOptionsChange: (item: ViewOptionsChangeParameters[]) => void;
  viewOptionsFields: Array<IViewOptionsField>;
}

export const ResourcePlannerViewOptions = ({
  periodPicker,
  viewOptionsChange,
  viewOptionsFields,
}: IResourcePlannerViewOptions) => {
  const { t } = useTranslation('viewOptions');
  const sidePanelDispatch = useSidePanelDispatch();
  const selectedFilterList = useSelectedFilterListInArray();

  const toggleFilterPanel = () => {
    sidePanelDispatch?.({ type: 'TOGGLE_PANEL' });
  };

  return (
    <Deck.Item>
      <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
        {periodPicker}
        <Stack direction="row">
          <ViewOptions
            viewOptionsFields={viewOptionsFields}
            viewOptionsChange={viewOptionsChange}
          />
          <Button
            onClick={toggleFilterPanel}
            startIcon={
              <Badge
                badgeContent={selectedFilterList.length}
                color="primary"
                data-automation-id="FilterContainerAddFilterCount"
              >
                <FilterList />
              </Badge>
            }
            variant="text"
          >
            {t('filter')}
          </Button>
        </Stack>
      </Stack>
    </Deck.Item>
  );
};
