import cx from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';
import { ToastContentProps, TypeOptions } from 'react-toastify';
import { Typography } from 'src/components/mui-components';
import { Icon } from 'src/components/ui-components/Icon';
import { Stack } from '@mui/material';

import styles from './ToastItem.module.scss';

export interface SharedToastProps {
  message: string | ReactNode;
  actions?: ReactNode;
}

const icons: { [key in TypeOptions]: JSX.Element | undefined } = {
  error: <Icon iconName="error" tone="error" />,
  success: <Icon iconName="check" tone="success" />,
  warning: <Icon iconName="warning" tone="warning" />,
  info: <Icon iconName="info" tone="default" />,
  default: undefined,
};

interface ToastProps
  extends Partial<ToastContentProps>,
    SharedToastProps,
    HTMLAttributes<HTMLElement> {}

export const ToastItem = ({ toastProps, message, actions, ...props }: ToastProps) => (
  <Stack
    gap={1}
    justifyContent="start"
    alignContent="start"
    className={cx(styles.Toast, {
      [styles.Error]: toastProps?.type === 'error',
      [styles.Success]: toastProps?.type === 'success',
      [styles.Warning]: toastProps?.type === 'warning',
    })}
    {...props}
  >
    <Stack gap={1} direction="row">
      {toastProps?.type && icons[toastProps.type]}
      <Stack gap={2}>
        <Typography>{message}</Typography>
        {actions && (
          <Stack gap={1} direction="row">
            {actions}
          </Stack>
        )}
      </Stack>
    </Stack>
  </Stack>
);
