import { IResourcePlannerColumn } from 'src/apis/types/resourcePlannerAPI';

export const TAKE = 50;

export const FIXED_COLUMNS: IResourcePlannerColumn[] = [
  {
    identifier: 'status',
  },
  {
    identifier: 'startsAt',
    format: 'date',
    alignment: 'center',
  },
  {
    identifier: 'endsAt',
    format: 'date',
    alignment: 'center',
  },
  {
    identifier: 'budget',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'totalBooked',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'totalActualWorkload',
    alignment: 'right',
    format: 'number',
  },
  {
    identifier: 'notPlanned',
    alignment: 'right',
    format: 'number',
  },
];
