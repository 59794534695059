import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IDistinctCurrenciesResponse, IExchangeRateCurrency } from '../types';

const EXCHANGE_RATE_DISTINCT_CURRENCIES_URL_PATH = '/api/v1/exchange-rate/distinct-currencies';
export const EXCHANGE_RATE_DISTINCT_CURRENCIES_MSW_STATUS_KEY = 'distinctCurrenciesAPI';
export const EXCHANGE_RATE_DISTINCT_CURRENCIES_KEY = 'EXCHANGE_RATE_DISTINCT_CURRENCIES';

const getDistinctCurrencies = (): Promise<IDistinctCurrenciesResponse> =>
  getFetch({
    path: EXCHANGE_RATE_DISTINCT_CURRENCIES_URL_PATH,
    key: EXCHANGE_RATE_DISTINCT_CURRENCIES_MSW_STATUS_KEY,
  });

export const useGetDistinctCurrencies = () => {
  const { data, ...rest } = useQuery(
    [EXCHANGE_RATE_DISTINCT_CURRENCIES_KEY],
    getDistinctCurrencies,
  );

  const distinctCurrencies: IExchangeRateCurrency[] = data?.entities
    ? data.entities.map(({ properties }) =>
        properties
          ? {
              ...properties,
            }
          : { currencyName: '', currencyAbb: '', currencyId: 0, isBaseCurrency: false },
      )
    : [];

  return {
    distinctCurrencies,
    ...rest,
  };
};
